import { useCallback } from "react";

export const useButtonHandlers = (
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  calculate: () => void
) => {
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);

  const onSaveClick = useCallback(async () => {
    try {
      await calculate();

      close();
    } catch (error) {}
  }, [calculate, close]);

  return { close, onSaveClick };
};
