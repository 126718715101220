import { useCallback, useState } from "react";
import { Button, Card, Row, Table } from "antd";
import { useGetTableColumns } from "./hooks/useGetTableColumns";
import { SimpleTablePagination } from "../../../../../../../../components/SimpleTablePagination";
import { useFetchDataCount } from "../../../../../../../../lib/hooks/useDefaultFetchDataCount";
import { useFetchData } from "../../../../../../../../lib/hooks/useDefaultFetchData";
import { createPortal } from "react-dom";
import { Loader } from "../../../../../../../../components/Loader";
import { useOpenSidebar } from "./hooks/useOpenSideBar";
import ImpoundsService from "../../../../../../../../services/accounts/impounds";
import { useButtonHandlers } from "./hooks/useButtonHandlers";
import { IImpoundsGet } from "../../../../../../../../types/Impounds";

type ImpoundsProps = {
  accountId: string;
  primaryBuyerNumber: number;
  primarySellerNumber: number;
  setShouldPayeeDisbUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  reloadAccount: () => void;
};

export const Impounds = ({
  accountId,
  primaryBuyerNumber,
  primarySellerNumber,
  setShouldPayeeDisbUpdate,
  reloadAccount,
}: ImpoundsProps) => {
  const [id, setId] = useState<string | null>(null);

  const [activeKey, setActiveKey] = useState<string>("");

  const [isDistributionModalOpen, setIsDistributionModalOpen] =
    useState<boolean>(false);

  const [impoundCurrentBalance, setImpoundCurrentBalance] = useState<number>(0);

  const {
    request,
    isLoading: isCountLoading,
    total,
    onPageChange,
    fetchCount,
  } = useFetchDataCount<{ contactId: string }>(
    ImpoundsService,
    { contactId: accountId },
    ["contactId"],
    10
  );

  const { data, isLoading, fetchData } = useFetchData<any, IImpoundsGet>(
    ImpoundsService,
    request
  );

  const reloadData = useCallback(
    (isImpoundCreated?: boolean) => {
      fetchCount();
      fetchData();
      isImpoundCreated ? setShouldPayeeDisbUpdate(true) : reloadAccount();
    },
    [fetchCount, fetchData, reloadAccount, setShouldPayeeDisbUpdate]
  );

  const { formWrapper, isOpen, setIsOpen } = useOpenSidebar({
    id,
    setId,
    reloadData,
    payeesNumber: {
      primaryBuyerNumber,
      primarySellerNumber,
    },
    activeKey,
    customSidebarClass: "sidebar__container-impound",
    parentId: accountId,
    isDistributionModalOpen,
    setIsDistributionModalOpen,
    impoundCurrentBalance,
  });

  const { onDeleteBtnClick, onOpenBtnClick } = useButtonHandlers({
    reloadData,
    setIsOpen,
    setId,
    setActiveKey,
    setIsDistributionModalOpen,
    setImpoundCurrentBalance,
  });

  const { columns } = useGetTableColumns({
    setShouldPayeeDisbUpdate,
    contractId: accountId,
    onDeleteBtnClick,
    onOpenBtnClick,
  });

  const onRow = useCallback(
    (record: IImpoundsGet) => {
      const { id, balance } = record;

      return {
        onClick: () => onOpenBtnClick(id, "details", balance),
      };
    },
    [onOpenBtnClick]
  );

  const onAdd = useCallback(() => setIsOpen(true), [setIsOpen]);

  return (
    <Row className="mb-4 w-100">
      <Card className="w-100">
        <Row className="w-100 d-flex justify-space-between">
          <h5 className="h h5 ant-table-title">Impounds</h5>
          <Button shape="round" onClick={onAdd}>
            Add new
          </Button>
        </Row>
        {isLoading ? (
          <Loader fontSize="64px" />
        ) : (
          data.length !== 0 && (
            <Row className="w-100 pt-6">
              <Table
                dataSource={data}
                columns={columns}
                pagination={false}
                loading={isLoading}
                className="w-100 clickable-rows-table"
                rowKey="id"
                onRow={onRow}
              />
              {total > 10 && (
                <SimpleTablePagination
                  isLoading={isCountLoading}
                  total={total}
                  pageNumber={request.pageNumber}
                  onPageChange={onPageChange}
                />
              )}
            </Row>
          )
        )}
      </Card>
      {isOpen && createPortal(formWrapper, document.body)}
    </Row>
  );
};
