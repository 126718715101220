import { useCallback, useState } from "react";
import { useDefaultDeleteModal } from "../../../../../../../lib/hooks/defaultDeleteModal";
import {
  deleteTickler,
  createTickler,
  editTickler,
} from "../../../../../../../acions/accounts/ticklers";
import { ITickler } from "../../../../../../../types/Tickler";
import { FormInstance } from "antd";
import { useAppDispatch } from "../../../../../../../store";

type TButtonHandlersProps = {
  reloadData: () => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
  form?: FormInstance;
  parentId?: string;
};

export const useButtonHandlers = ({
  reloadData,
  setIsOpen,
  setId,
  form,
  parentId,
}: TButtonHandlersProps) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { openDeleteModal } = useDefaultDeleteModal(
    deleteTickler,
    reloadData,
    setIsOpen
  );

  const onDeleteClick = useCallback(
    (id: string) => {
      openDeleteModal(id, "Delete Tickler");
    },
    [openDeleteModal]
  );

  const onCancelClick = useCallback(() => {
    setIsOpen(false);
    setId(null);
  }, [setId, setIsOpen]);

  const create = useCallback(
    async (entry: ITickler, isNew: boolean) => {
      try {
        await Promise.resolve(dispatch(createTickler(entry)));

        reloadData();
        setIsDisabled(false);
        setId(null);
        setIsOpen(isNew);
        form?.resetFields();
      } catch (error) {
        setIsDisabled(false);
      }
    },
    [dispatch, form, reloadData, setId, setIsOpen]
  );

  const edit = useCallback(
    async (id: string, entry: ITickler) => {
      try {
        await Promise.resolve(dispatch(editTickler(entry, id)));

        reloadData();
        setIsDisabled(false);
        setId(null);
        setIsOpen(false);
        form?.resetFields();
      } catch (error) {
        setIsDisabled(false);
      }
    },
    [dispatch, form, reloadData, setId, setIsOpen]
  );

  const onSaveClick = useCallback(
    async (isNew: boolean, id: string | null) => {
      try {
        const formData = await form?.validateFields();

        const { date } = formData;

        const request = {
          ...formData,
          contractId: parentId,
          date: date ? date.startOf("D").format() : undefined,
        };

        setIsDisabled(true);

        id !== null ? edit(id, request) : create(request, isNew);
      } catch (error) {
        setIsDisabled(false);
      }
    },
    [create, edit, form, parentId]
  );

  return {
    isDisabled,
    onDeleteClick,
    onCancelClick,
    onSaveClick,
  };
};
