import { useCallback, useEffect, useState } from "react";
import FeesService from "../../../../services/administration/fees";
import { FormInstance } from "antd";
import { INITIAL_FEE_AMOUNTS } from "../../../../lib/constants";
import ContractFeesService from "../../../../services/accounts/fees";
import { useTransformRequest } from "./useTransformRequest";

type TInitialValuesProps = {
  id: string | null;
  form: FormInstance;
  parentId: string | undefined;
};

export const useGetInitialValues = ({
  id,
  form,
  parentId,
}: TInitialValuesProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(!!id);

  const [references, setReferences] = useState<number>(0);

  const { transform } = useTransformRequest(parentId, false);

  const fetchFee = useCallback(() => {
    if (id !== null) {
      setIsLoading(true);

      const service: any = parentId ? ContractFeesService : FeesService;

      const requset: any = { id };

      if (parentId) {
        requset.contractId = parentId;
      }

      service
        .fetch(requset)
        .then((res: any) => {
          transform(res);

          form.setFieldsValue(res);
          setReferences(res.references);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    } else {
      form.setFieldsValue(INITIAL_FEE_AMOUNTS);
    }
  }, [id, parentId, transform, form]);

  useEffect(() => fetchFee(), [fetchFee]);

  return { isLoading, references };
};
