import { Row } from "antd";

type SelectOptionProps = {
  name: string;
  item: any;
};

export const SelectOption = ({ item, name }: SelectOptionProps) => {
  return (
    <Row className="justify-space-between align-center">
      <Row className="d-block main-info">
        <p className="my-0 text-md w-500 elipsis">{name}</p>
        {!item.isEmail && (
          <p className="my-0 text-sm title default-bg additional-info">
            {item.email}
          </p>
        )}
      </Row>
      <Row className="mx-2 additional-info">
        {item.role && (
          <span className="text-sm title default-bg">
            Primary {item.role === 1 ? "Buyer" : "Seller"}&nbsp;|&nbsp;
          </span>
        )}
        {!item.isEmail && (
          <span className="text-sm title default-bg">#{item.number}</span>
        )}
      </Row>
    </Row>
  );
};
