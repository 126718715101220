import { ReactNode, useMemo } from "react";
import dayjs from "../../../configs/dayjs";
import { DATE_FORMAT } from "../../../lib/constants";

export const useCreatedAtLabel = (data: any) => {
  const createdAtLabel: ReactNode = useMemo(() => {
    return data ? (
      <div>
        <p className="my-0 text-md title">
          Last Updated: {dayjs(data.createdAt).format(DATE_FORMAT)}
        </p>
      </div>
    ) : (
      <div />
    );
  }, [data]);

  return { createdAtLabel };
};
