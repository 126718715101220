import { useMemo } from "react";
import "./styles.scss";
import { Row, Tabs } from "antd";
import { Fees } from "./FeeTab";
import { LateCharges } from "./LateChargesTab";
import { Impounds } from "./ImpoundsTab";
import { Ticklers } from "./TicklersTab";
import { TemplatesTab } from "./TemplatesTab";

export const Administration = () => {
  const tabs = useMemo(
    () => [
      {
        key: "Fees",
        label: "Fees",
        children: <Fees />,
      },
      {
        key: "Late Charges",
        label: "Late Charges",
        children: <LateCharges />,
      },
      {
        key: "Impounds",
        label: "Impounds",
        children: <Impounds />,
      },
      {
        key: "Ticklers",
        label: "Ticklers",
        children: <Ticklers />,
      },
      {
        key: "Forms Templates",
        label: "Forms Templates",
        children: <TemplatesTab />,
      },
    ],
    []
  );

  return (
    <Row className="table-page-content">
      <Row className="d-block w-100">
        <Row className="py-6 mx-8">
          <h3 className="h h3">Administration</h3>
        </Row>
        <Row className="administration-content">
          <div className="d-block width-100">
            <Tabs
              className="h-100 setting-options"
              tabPosition="left"
              items={tabs}
            />
          </div>
        </Row>
      </Row>
    </Row>
  );
};
