import { Form, Row, Col, Input, Select, Button } from "antd";
import { useValidateOnBlur } from "../../../lib/hooks/validateOnBlur";
import { PhoneInput } from "../../../components/PhoneInput";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../../store";
import { registerAdmin } from "../../../acions/users";
import { LoadingOutlined } from "@ant-design/icons";
import { useGetRole } from "../../../lib/hooks/getRole";
import { FullNameFormItems } from "./FullNameFormItems";
import { useGetEmailFormRules } from "../../../lib/hooks/getEmailFormRules";

type CreateAdminProps = {
  setShowSideBar: (value: boolean) => void;
  roles: any[];
  reloadData: () => void;
};

export const CreateAdmin = ({
  setShowSideBar,
  roles,
  reloadData,
}: CreateAdminProps) => {
  const [form] = Form.useForm();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [role] = useGetRole();

  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [validateOnBlur] = useValidateOnBlur(form);

  const [selectedRole, setSelectedRole] = useState<any>(undefined);

  const { rules: emailRules } = useGetEmailFormRules();

  useEffect(() => {
    setSelectedRole(roles[0].value);
  }, [roles]);

  const onSubmit = (values: any) => {
    const phone = `1${values.phone.replace(/[\s-()]/g, "")}`;

    setIsLoading(true);
    Promise.resolve(
      dispatch(registerAdmin({ ...values, phone, roleId: selectedRole }))
    )
      .then(() => {
        setIsLoading(false);
        setShowSideBar(false);
        reloadData();
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const formatedRoles = useMemo(() => {
    let value = [];
    if (role === "Admin") {
      value.push(roles.find((item) => item.label === "Admin"));
    } else {
      value = roles.filter((item) => item.label !== "User");
    }
    return value;
  }, [roles, role]);

  return (
    <>
      <div className="px-6 pt-8 pb-6 sidebar-body">
        <Form
          autoComplete="off"
          requiredMark={false}
          layout="vertical"
          form={form}
          onFinish={onSubmit}
          disabled={isLoading}
          className="user-management-form"
        >
          <FullNameFormItems form={form} />

          <Row className="align-center mb-4">
            <Col span={8}>
              <span className="text-md title">Email</span>
            </Col>
            <Col span={16}>
              <Form.Item name="email" className="mb-0" rules={emailRules}>
                <Input onBlur={(e) => validateOnBlur(e, "email")} />
              </Form.Item>
            </Col>
          </Row>

          <Row className="align-center mb-4">
            <Col span={8}>
              <span className="text-md title">Phone</span>
            </Col>
            <Col span={16}>
              <PhoneInput
                form={form}
                size="middle"
                formItemMargin="mb-0"
                disabled={isLoading}
              />
            </Col>
          </Row>

          <Row className="align-center mb-4">
            <Col span={8}>
              <span className="text-md title">Role</span>
            </Col>
            <Col span={16}>
              <div className="role-selector">
                <Select
                  options={formatedRoles}
                  onChange={setSelectedRole}
                  defaultValue="Admin"
                />
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="sidebar-footer px-6 py-6">
        <Button
          type="primary"
          shape="round"
          htmlType="submit"
          block
          onClick={form.submit}
          disabled={isLoading}
        >
          Create Admin
          {isLoading && <LoadingOutlined />}
        </Button>
      </div>
    </>
  );
};
