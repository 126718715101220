import { FormInstance } from "antd";
import { useCallback, useState } from "react";
import { useAppDispatch } from "../../../../store";
import {
  changeLateChargesCalc,
  deleteLateCharge,
  createLateCharge,
  editLateCharge,
} from "../../../../acions/administration/lateCharges";
import { ILateCharge } from "../../../../types/LateCharges";
import { useDefaultDeleteModal } from "../../../../lib/hooks/defaultDeleteModal";
import { deleteContractLateCharge } from "../../../../acions/accounts/lateCharges";

type TButtonHandlersProps = {
  reloadData: () => void;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  form?: FormInstance;
  setId?: React.Dispatch<React.SetStateAction<string | null>>;
  parentId?: string;
};

export const useButtonHandlers = ({
  reloadData,
  form,
  setIsOpen,
  setId,
  parentId,
}: TButtonHandlersProps) => {
  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { openDeleteModal } = useDefaultDeleteModal(
    parentId ? deleteContractLateCharge : deleteLateCharge,
    reloadData,
    setIsOpen
  );

  const onLateChargeCalcChange = useCallback(async () => {
    try {
      setIsFormDisabled(true);
      const formData = await form?.validateFields();

      await Promise.resolve(dispatch(changeLateChargesCalc(formData)));

      setIsFormDisabled(false);
    } catch (error) {
      setIsFormDisabled(false);
    }
  }, [dispatch, form]);

  const onDeleteBtnClick = useCallback(
    (id: string) => openDeleteModal(id, "Delete a Late Charge"),
    [openDeleteModal]
  );

  const create = useCallback(
    async (isNew: boolean, entry: ILateCharge) => {
      try {
        await Promise.resolve(dispatch(createLateCharge(entry, parentId)));

        reloadData();
        setIsFormDisabled(false);

        setIsOpen && setIsOpen(isNew);
        form?.resetFields();
      } catch (error) {
        setIsFormDisabled(false);
      }
    },
    [dispatch, form, reloadData, setIsOpen, parentId]
  );

  const edit = useCallback(
    async (entry: ILateCharge, id: string) => {
      try {
        await Promise.resolve(dispatch(editLateCharge(entry, id, parentId)));

        reloadData();
        setIsFormDisabled(false);
        setIsOpen && setIsOpen(false);
      } catch (error) {
        setIsFormDisabled(false);
      }
    },
    [dispatch, reloadData, setIsOpen, parentId]
  );

  const onCancelClick = useCallback(() => {
    setIsOpen && setIsOpen(false);
    setId && setId(null);
  }, [setId, setIsOpen]);

  const onSaveClick = useCallback(
    async (isNew: boolean, id: string | null) => {
      try {
        const formData = await form?.validateFields();

        const { amount } = formData;

        if (amount) {
          formData.amount = +amount.replace(/,/g, "");
        }

        setIsFormDisabled(true);

        id !== null ? edit(formData, id) : create(isNew, formData);

        if (setId) setId(null);
      } catch (error) {
        setIsFormDisabled(false);
      }
    },
    [create, edit, form, setId]
  );

  return {
    onLateChargeCalcChange,
    isFormDisabled,
    onDeleteBtnClick,
    onCancelClick,
    onSaveClick,
  };
};
