import { ThunkAction } from "redux-thunk";
import { RootState } from "../../store";
import { AnyAction } from "redux";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../actionsCreators/messageActions";
import {
  ASSIGN_CHECK_NUMBERS,
} from "../../lib/constants";
import BatchDisbursementsService from "../../services/processing/disbursements";

export const assign =
  (req: {
    batchId: string;
    selectedDisbursementIds: string[],
    checkNumber: number;
    ignoreAssigned: boolean;
  }): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await BatchDisbursementsService.assign(req);

      dispatch(setSuccessMessage(ASSIGN_CHECK_NUMBERS));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };
