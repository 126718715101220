import { ColumnsType } from "antd/es/table";
import { IImpoundActivity } from "../../../../../../../../../../../types/ImpoundActivity";
import { useCallback, useMemo, useState, MouseEvent } from "react";
import dayjs from "../../../../../../../../../../../configs/dayjs";
import { DATE_FORMAT } from "../../../../../../../../../../../lib/constants";
import { Dropdown, MenuProps, Row, Tooltip } from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { getFormattedCurrency } from "../../../../../../../../../../../lib/currencyFormatter";
import { useDelete } from "./useDelete";
import { useRecalculate } from "./useRecalculate";

export const useTableColumns = (reloadData: () => void) => {
  const [openedActivityId, setOpenedActivityId] = useState<string | null>(null);

  const { onDeleteBtnClick } = useDelete(reloadData);

  const {
    calculateBalances,
    updateImpoundWithBalance,
    updateImpoundWithYTD,
    isDisabled,
  } = useRecalculate(reloadData);

  const onDelete = useCallback(
    (e: MouseEvent<HTMLInputElement>): void => {
      e.stopPropagation();
      onDeleteBtnClick(e.currentTarget.id);
    },
    [onDeleteBtnClick]
  );

  const onEdit = useCallback(
    (e: MouseEvent<HTMLInputElement>): void => {
      e.stopPropagation();
      setOpenedActivityId(e.currentTarget.id);
    },
    [setOpenedActivityId]
  );

  const onCalculateBalances = useCallback(
    (e: MouseEvent<HTMLInputElement>): void => {
      e.stopPropagation();
      calculateBalances(e.currentTarget.id);
    },
    [calculateBalances]
  );

  const onUpdateImpoundWithBalance = useCallback(
    (e: MouseEvent<HTMLInputElement>): void => {
      e.stopPropagation();
      updateImpoundWithBalance(e.currentTarget.id);
    },
    [updateImpoundWithBalance]
  );

  const onUpdateImpoundWithYTD = useCallback(
    (e: MouseEvent<HTMLInputElement>): void => {
      e.stopPropagation();
      updateImpoundWithYTD(e.currentTarget.id);
    },
    [updateImpoundWithYTD]
  );

  const columns: ColumnsType<IImpoundActivity> = useMemo(
    () => [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        width: "13%",
        render: (value: string) => dayjs(value).format(DATE_FORMAT),
      },
      {
        title: "Audit",
        dataIndex: "auditNumber",
        key: "auditNumber",
        width: "13%",
        ellipsis: true,
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        width: "20%",
        align: "right",
        render: (value: number, record: IImpoundActivity) => {
          const { direction } = record;

          const tooltipText = `${direction === 1 ? "Into" : "From"} Impound`;

          return (
            <Row className="justify-space-between">
              <Tooltip
                placement="top"
                title={tooltipText}
                overlayClassName="text-sm"
              >
                {direction === 1 ? (
                  <Row className="align-center justify-center impound impound-into">
                    <ArrowLeftOutlined />
                  </Row>
                ) : (
                  <Row className="align-center justify-center impound impound-from">
                    <ArrowRightOutlined />
                  </Row>
                )}
              </Tooltip>
              <Row className="amount__container justify-end">
                <p className="my-0 text-md elipsis">
                  {getFormattedCurrency(value)}
                </p>
              </Row>
            </Row>
          );
        },
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        width: "30%",
        ellipsis: true,
      },
      {
        title: "Balance",
        dataIndex: "balance",
        key: "balance",
        width: "17%",
        ellipsis: true,
        align: "right",
        render: (value: number) => getFormattedCurrency(value),
      },
      {
        width: "7%",
        render: (_: any, record: IImpoundActivity) => {
          const { id } = record;
          const items: MenuProps["items"] = [
            {
              key: "1",
              label: (
                <Row id={id} onClick={onEdit}>
                  Edit Record
                </Row>
              ),
            },
            {
              key: "3",
              label: (
                <Row id={id} onClick={onCalculateBalances}>
                  Calculate Balances to Last Trans
                </Row>
              ),
            },
            {
              key: "4",
              label: (
                <Row id={id} onClick={onUpdateImpoundWithBalance}>
                  Update Impound with Hist Balance
                </Row>
              ),
            },
            {
              key: "5",
              label: (
                <Row id={id} onClick={onUpdateImpoundWithYTD}>
                  Update Impound with YTD In & Out
                </Row>
              ),
            },
            {
              key: "2",
              label: (
                <Row id={id} onClick={onDelete}>
                  Delete Record
                </Row>
              ),
            },
          ];

          return (
            <div className="ant-table-actions">
              <Dropdown
                menu={{ items }}
                trigger={["click"]}
                disabled={isDisabled}
              >
                <EllipsisOutlined
                  className="ant-table-btn"
                  onClick={(e) => e.stopPropagation()}
                />
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [
      isDisabled,
      onCalculateBalances,
      onDelete,
      onEdit,
      onUpdateImpoundWithBalance,
      onUpdateImpoundWithYTD,
    ]
  );

  return { columns, openedActivityId, setOpenedActivityId };
};
