import { useCallback } from "react";

export const useFormData = () => {
  const getFormData = useCallback((data: any, categoryId?: string) => {
    const formData = new FormData();

    if (data.file && data.file.length > 0) {
      data.fileUpdated = !!data.file[0]?.originFileObj;
    }

    for (const [key, value] of Object.entries(data)) {
      if (value === undefined || value === null) {
        continue;
      }

      if (key === "file") {
        if (data[key][0]?.originFileObj) {
          formData.append(key, data[key][0]?.originFileObj);
        }

        continue;
      }

      formData.append(key, data[key]);
    }

    categoryId && formData.append("categoryId", categoryId);

    return formData;
  }, []);

  return { getFormData };
};
