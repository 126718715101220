import { useCallback, useState } from "react";

export const useToggleTerms = () => {
  const [termsTabId, setTermsTabId] = useState<undefined | "1" | "2">(
    undefined
  );

  const onCloseTerms = useCallback(() => setTermsTabId(undefined), []);

  const openTerms = useCallback(() => setTermsTabId("1"), []);

  const openPrivacy = useCallback(() => setTermsTabId("2"), []);

  return { termsTabId, setTermsTabId, onCloseTerms, openTerms, openPrivacy };
};
