import { Button, Card, Row, Table } from "antd";
import { useCallback, useState } from "react";
import { useGetTableColumns } from "./hooks/useGetTableColumns";
import { useFetchDataCount } from "../../../../../../lib/hooks/useDefaultFetchDataCount";
import { TablePagination } from "../../../../../../components/TablePagination";
import { useDefaultTableScrollProp } from "../../../../../Customers/hooks/defaultTableScrollProp";
import { createPortal } from "react-dom";
import { useFetchData } from "../../../../../../lib/hooks/useDefaultFetchData";
import { useDefaultFormSidebar } from "../../../../../../lib/hooks/defaultFormSidebar";
import { TicklerForm } from "./TicklerForm";
import { TRequest } from "./types";
import { ITicklerExtended } from "../../../../../../types/Tickler";
import TicklersService from "../../../../../../services/accounts/ticklers";
import { useDefualtOnRow } from "../../../../../../lib/hooks/useDefaultOnRow";

type TicklersProps = {
  accountId: string;
};

export const Ticklers = ({ accountId }: TicklersProps) => {
  const [id, setId] = useState<string | null>(null);

  const {
    request,
    isLoading: isCountLoading,
    total,
    onPageChange,
    fetchCount,
  } = useFetchDataCount<{ ContractId: string }>(TicklersService, {
    ContractId: accountId,
  });

  const { data, isLoading, fetchData } = useFetchData<
    TRequest,
    ITicklerExtended
  >(TicklersService, request);

  const { scroll } = useDefaultTableScrollProp(data, 235);

  const reloadData = useCallback(() => {
    fetchCount();
    fetchData();
  }, [fetchData, fetchCount]);

  const { formWrapper, isOpen, setIsOpen } = useDefaultFormSidebar({
    id,
    setId,
    reloadData,
    title: "Tickler",
    parentId: accountId,
    FormElem: TicklerForm,
  });

  const openTickler = useCallback(
    (id: string) => {
      setIsOpen(true);
      setId(id);
    },
    [setIsOpen]
  );

  const { onRow } = useDefualtOnRow(openTickler);

  const { columns } = useGetTableColumns({
    setId,
    setIsOpen,
    reloadData,
    openTickler,
  });

  const onAdd = useCallback(() => setIsOpen(true), [setIsOpen]);

  return (
    <Row className="w-100 px-8 py-6">
      <Card className="w-100">
        <Row className="w-100 d-flex justify-space-between">
          <h5 className="h h5 ant-table-title">Ticklers</h5>
          <Button shape="round" onClick={onAdd}>
            Add New
          </Button>
        </Row>
        <Row className="w-100 mt-6 d-block table__container">
          <Table
            dataSource={data}
            loading={isLoading}
            pagination={false}
            rowKey="id"
            columns={columns}
            className="w-100 clickable-rows-table"
            scroll={scroll}
            onRow={onRow}
          />
          {total > 20 && (
            <TablePagination
              isTotalLoading={isCountLoading}
              total={total}
              currentPage={request.pageNumber}
              pageSize={request.pageSize}
              onPageChange={onPageChange}
            />
          )}
        </Row>
      </Card>
      {isOpen && createPortal(formWrapper, document.body)}
    </Row>
  );
};
