import { FormInstance, Row } from "antd";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { FloatNumberInput } from "../../../../../../../../../components/FloatNumberInput";
import { getFormattedCurrency } from "../../../../../../../../../lib/currencyFormatter";

type RegularAmountProps = {
  data: any;
  form: FormInstance;
};

export const RegularAmount = ({ data, form }: RegularAmountProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  const collapse = useCallback(
    () => setIsCollapsed(!isCollapsed),
    [isCollapsed]
  );

  const collapseIcon: ReactNode = useMemo(
    () =>
      isCollapsed ? (
        <RightOutlined
          className="text-md title logo-cursor"
          onClick={collapse}
        />
      ) : (
        <DownOutlined
          className="text-md title logo-cursor"
          onClick={collapse}
        />
      ),
    [collapse, isCollapsed]
  );

  const itemsMap: any = useMemo(
    () => ({
      regularAmountPrincipalAndInterest: "Principal & Interest",
      regularAmountFee: "Buyer Fee",
      regularAmountImpound: "Impound",
      regularAmountPassthru: "Passthru",
    }),
    []
  );

  const summary: number = useMemo(() => {
    const {
      regularAmountPrincipalAndInterest,
      regularAmountFee,
      regularAmountImpound,
      regularAmountPassthru,
    } = data;

    return (
      (regularAmountPrincipalAndInterest || 0) +
      (regularAmountFee || 0) +
      (regularAmountImpound || 0) +
      (regularAmountPassthru || 0)
    );
  }, [data]);

  const paymentItems: ReactNode = useMemo(() => {
    const values = Object.keys(itemsMap).map((item: string) => ({
      label: itemsMap[item],
      value: data[item],
    }));

    return values.map((item: any, i: number) => (
      <Row
        className="justify-space-between w-100 payment-row payment-row_item"
        key={i}
      >
        <span className="text-md title">{item.label}</span>
        <span>{getFormattedCurrency(item.value || 0)}</span>
      </Row>
    ));
  }, [data, itemsMap]);

  return (
    <Row>
      <div className="w-100 mb-4 divider" />
      <Row className="justify-space-between w-100">
        <Row>
          {collapseIcon}
          <span className="mx-2 text-md">Regular Amount</span>
        </Row>
        <Row>{getFormattedCurrency(summary || 0)}</Row>
      </Row>
      <Row className="justify-space-between w-100 payment-row_item align-center my-1">
        <Row>
          <span className="text-md title">Overridden Amount</span>
        </Row>
        <Row>
          <FloatNumberInput
            name={"regularOverridenAmount"}
            form={form}
            label=""
            className="mb-0 balance__form-item"
            includeThousandsSeparator
          />
        </Row>
      </Row>
      {!isCollapsed && <Row>{paymentItems}</Row>}
    </Row>
  );
};
