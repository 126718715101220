import { Col, Input, Row, Select, DatePicker, Form, FormInstance } from "antd";
import { useCallback, useMemo } from "react";
import { MAX_NOTEES, MAX_SALUTATION } from "../../../../../../lib/constants";
import { TaxIdInput } from "./TaxIdInput";
import { DATE_FORMAT } from "../../../../../../lib/constants";
import { Loader } from "../../../../../../components/Loader";
import { FullNameFormItems } from "./FullNameFormItems";

type GeneralInfoProps = {
  form: FormInstance;
  isLoading: boolean;
  types: any[];
};

export const GeneralInfo = ({ form, isLoading, types }: GeneralInfoProps) => {
  const type = Form.useWatch(["typeId"], {
    form,
    preserve: true,
  });

  const isIndividual: boolean = useMemo(() => type === 1 || !type, [type]);

  const onChangeType = useCallback(
    () => form.setFieldValue(["taxId"], ""),
    [form]
  );

  return (
    <>
      {!isLoading ? (
        <>
          <Row className="pb-6">
            <h5 className="h h5">General</h5>
          </Row>
          <Row>
            <Col span={12} className="pr-8">
              <Row className="mb-4">
                <Form.Item
                  label="Type"
                  name={["typeId"]}
                  className="w-100 mb-0"
                >
                  <Select options={types} onChange={onChangeType} />
                </Form.Item>
              </Row>

              <FullNameFormItems isIndividual={isIndividual} />
            </Col>
            <Col span={12} className="pl-8">
              <Row className="mb-4">
                <TaxIdInput form={form} isIndividual={isIndividual} />
              </Row>
              {isIndividual && (
                <>
                  <Row className="mb-4 flex-column">
                    <Form.Item
                      label="Date of Birth"
                      name={["customerIndividualData", "dateOfBirth"]}
                      className="mb-0"
                    >
                      <DatePicker format={DATE_FORMAT} />
                    </Form.Item>
                  </Row>
                  <Row className="mb-4 w-100">
                    <Form.Item
                      label="Salutation"
                      name={["customerIndividualData", "salutation"]}
                      className="w-100"
                      rules={[
                        {
                          max: 10,
                          message: MAX_SALUTATION,
                          validateTrigger: "onSubmit",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Row>
                </>
              )}
            </Col>

            <div className="w-100 my-4 divider" />

            <Row className="mb-4 w-100">
              <Form.Item
                className="mb-0 w-100"
                name={["notes"]}
                label="Notes"
                rules={[
                  {
                    max: 2000,
                    message: MAX_NOTEES,
                    validateTrigger: "onSubmit",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Row>
          </Row>
        </>
      ) : (
        <Loader fontSize="64px" />
      )}
    </>
  );
};
