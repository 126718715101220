import { Button, Dropdown, MenuProps } from "antd";
import { Radio, RadioChangeEvent, Row } from "antd";
import { useCallback, useMemo } from "react";
import { MoreOutlined } from "@ant-design/icons";
import { SingleSelect } from "../../../components/SingleSelect";
import { CreateButton } from "./CreateEmails/CreateButton";
import { useDeleteAll } from "../hooks/useDeleteAll";
import { SendAllButton } from "./SendAllButton";
import { Email } from "../../../types/Email";

type HeaderProps = {
  request: any;
  isLoading: boolean;
  setRequest: React.Dispatch<React.SetStateAction<any>>;
  reloadData: () => void;
  setData: React.Dispatch<React.SetStateAction<Email[]>>;
};

export const Header = ({
  request,
  isLoading,
  setRequest,
  reloadData,
  setData,
}: HeaderProps) => {
  const { removeAll } = useDeleteAll(reloadData);

  const typeOptions = useMemo(
    () => [
      { value: 1, label: "Outbox" },
      { value: 2, label: "Sent" },
    ],
    []
  );

  const dropdownItems: MenuProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: <Row onClick={removeAll}>Delete All from Outbox</Row>,
      },
    ],
    [removeAll]
  );

  const sentFilterOptions = useMemo(
    () => [
      { label: "Today", value: 0 },
      { label: "Last Week", value: 1 },
      { label: "Last Month", value: 2 },
      { label: "Last 6 Months", value: 3 },
    ],
    []
  );

  const onFilterChange = useCallback(
    (value: number) => {
      setRequest((prev: any) => ({ ...prev, dateType: value, pageNumber: 1 }));
    },
    [setRequest]
  );

  const onTypeChange = useCallback(
    (e: RadioChangeEvent) => {
      setData([]);
      const value = e.target.value;

      const request: any = {
        pageNumber: 1,
        pageSize: 20,
        type: value,
      };

      if (value === 2) {
        request.dateType = 0;
      }

      setRequest(request);
    },
    [setRequest, setData]
  );

  return (
    <Row className="d-block width-100 mx-8 ">
      <Row className="my-6">
        <h3 className="h h3">Emails</h3>
      </Row>
      <Row className="justify-space-between">
        <Radio.Group
          optionType="button"
          options={typeOptions}
          defaultValue={1}
          onChange={onTypeChange}
          disabled={isLoading}
        />
        {request.type === 1 ? (
          <Row>
            <Dropdown
              className="mr-2"
              menu={{ items: dropdownItems }}
              trigger={["click"]}
              disabled={isLoading}
            >
              <Button shape="circle" icon={<MoreOutlined />}></Button>
            </Dropdown>
            <SendAllButton isLoading={isLoading} reloadData={reloadData} />
            <CreateButton isLoading={isLoading} reloadData={reloadData} />
          </Row>
        ) : (
          <SingleSelect
            options={sentFilterOptions}
            label="Sent"
            value={undefined || request.dateType}
            onChange={onFilterChange}
            loading={isLoading}
          />
        )}
      </Row>
    </Row>
  );
};
