import { FormInstance, Row } from "antd";
import { FloatNumberInput } from "../../../../../../components/FloatNumberInput";
import { useCallback } from "react";

type InputRowProps = {
  form: FormInstance;
  label: string;
  name: string[];
  calculate: (toUpdate?: {
    name: string;
    value: any;
    isUpdatedField?: boolean;
  }) => void;
  isUpdated?: boolean;
};

export const InputRow = ({
  label,
  name,
  form,
  calculate,
  isUpdated = false,
}: InputRowProps) => {
  const onPressEnter = useCallback((): void => {
    const nameProp = `${name[0]}.lateCharge.${name[1]}`;

    isUpdated
      ? calculate({ name: nameProp, value: true, isUpdatedField: true })
      : calculate();
  }, [calculate, isUpdated, name]);

  return (
    <Row className="align-center mb-2 justify-space-between">
      <p className="my-0 text-md title">{label}</p>
      <FloatNumberInput
        name={name}
        label=""
        form={form}
        className="late-charge-input mb-0"
        allowNegative
        onPressEnter={onPressEnter}
      />
    </Row>
  );
};
