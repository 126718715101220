import { Row, Table } from "antd";
import { useCallback } from "react";
import { Filters } from "./Filters";
import { useGetCustomerTableColumns } from "./hooks/getCustomerTableColumns";
import { TablePagination } from "../../components/TablePagination";
import { useGetTypes } from "./hooks/fetchTypes";
import { useFetchData } from "../../lib/hooks/useDefaultFetchData";
import { CustomerItem } from "../../types/CustomersList";
import CustomersService from "../../services/customers";
import { useFetchCount } from "./hooks/useFetchCount";
import "./styles.scss";
import { NavigateFunction, useNavigate } from "react-router-dom";
import ROUTES from "../../configs/routesConfig";
import { useDefualtOnRow } from "../../lib/hooks/useDefaultOnRow";

type TRequest = {
  pageSize: number;
  pageNumber: number;
  typeId?: number;
  order?: number;
  search?: string;
};

export const Customers = () => {
  const navigate: NavigateFunction = useNavigate();

  const { types, isTypesLoading } = useGetTypes();

  const {
    total,
    isLoading: isTotalLoading,
    request,
    setSearchParams,
    fetchCount,
  } = useFetchCount();

  const { data, isLoading, fetchData } = useFetchData<TRequest, CustomerItem>(
    CustomersService,
    request
  );

  const reloadData = useCallback(() => {
    fetchData();
    fetchCount();
  }, [fetchData, fetchCount]);

  const navigateToCustomer = useCallback(
    (id: string) => {
      navigate(`${ROUTES.CUSTOMERS}/${id}`);
    },
    [navigate]
  );

  const { columns, onSortChange } = useGetCustomerTableColumns(
    reloadData,
    navigateToCustomer
  );

  const onPageChange = (pageNumber: number, pageSize: number) => {
    const newRequest: any = { ...request, pageNumber, pageSize };

    setSearchParams(newRequest);
  };

  const onSorterChange = (_pagination: any, _filter: any, sorter: any) => {
    onSortChange(sorter, request);
  };

  const { onRow } = useDefualtOnRow(navigateToCustomer);

  return (
    <Row className="table-page-content position-relative overflow-hidden customers">
      <Filters
        request={request}
        isLoading={isLoading}
        types={types}
        isTypesLoading={isTypesLoading}
      />
      <Row className="d-block table__container width-100 mx-8">
        <Table
          dataSource={data}
          columns={columns}
          loading={isLoading}
          pagination={false}
          className="overflow-y-auto clickable-rows-table"
          rowKey="number"
          onChange={onSorterChange}
          showSorterTooltip={false}
          onRow={onRow}
        />
        <TablePagination
          isTotalLoading={isTotalLoading}
          total={total}
          currentPage={request.pageNumber}
          pageSize={request.pageSize}
          onPageChange={onPageChange}
        />
      </Row>
    </Row>
  );
};
