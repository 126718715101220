import { Col, FormInstance, Row } from "antd";
import { FloatNumberInput } from "../../../../../components/FloatNumberInput";
import { useCallback, useMemo, useState } from "react";
import dayjs from "../../../../../configs/dayjs";
import { Warning } from "./Warning";
import { DataRow } from "./DataRow";
import { LateChargesModal } from "./LateChargeModal";
import { InterestAfterModal } from "./InterestAfterModal";

type BreakDownProps = {
  form: FormInstance;
  instance: any;
  isFormDisabled: boolean;
  isPaymentPosted: boolean;
  calculate: () => void;
  isHistoryOpened: boolean;
};

export const BreakDown = ({
  form,
  instance,
  isFormDisabled,
  isPaymentPosted,
  calculate,
  isHistoryOpened,
}: BreakDownProps) => {
  const [isLCModalOpen, setIsLCModalOpen] = useState<boolean>(false);

  const [isInterestModalOpen, setIsInterestModalOpen] =
    useState<boolean>(false);

  const openLCPopup = useCallback(() => setIsLCModalOpen(true), []);

  const openInterestPopup = useCallback(() => setIsInterestModalOpen(true), []);

  const headerWarning = useMemo(() => {
    if (!instance || !instance.dueDateNext) {
      return <Row />;
    }

    const { dueDateNext, breakdown } = instance;

    const diff = dayjs().diff(dueDateNext, "d");

    return diff > 0 ? (
      <Warning diff={diff} lateCharge={breakdown.lateCharge} />
    ) : (
      <Row />
    );
  }, [instance]);

  const contractId: string = useMemo(
    () => (instance ? instance.contractId : ""),
    [instance]
  );

  const lateChargeOptions: any = useMemo(() => {
    if (instance) {
      const { breakdown } = instance;
      return {
        lateChargeBefore: breakdown.lateChargeBefore,
        lateChargeAfter: breakdown.lateChargeAfter,
      };
    } else {
      return {};
    }
  }, [instance]);

  const rowsData = useMemo(
    () => [
      { label: "Principal", name: "principal", toText: "Principal" },
      { label: "Interest", name: "interest", toText: "Unpaid" },
      { label: "Late Charge", name: "lateCharge", toText: "Unpaid" },
      { label: "From Impound", name: "fromImpound", toText: "Impound" },
    ],
    []
  );

  return (
    <Row className="d-block breakdown__container">
      <Row className="align-center mb-4 w-100">
        <p className="my-0 text-md card-header w-500">Payment Breakdown</p>
        {headerWarning}
      </Row>
      <Row className="d-block w-100">
        <Row className="w-100 py-2 header row">
          <Col span={5} className="px-2 bordered">
            <p className="my-0 text-md w-500">Type</p>
          </Col>
          <Col span={6} className="px-2 bordered">
            <p className="my-0 text-md w-500">Amount</p>
          </Col>
          <Col span={8} className="px-2 bordered">
            <p className="my-0 text-md w-500">To Balance</p>
          </Col>
          <Col span={5} className="px-2 d-flex justify-end">
            <p className="my-0 text-md w-500">Before/After</p>
          </Col>
        </Row>
        {rowsData.map((data, i: number) => {
          return (
            <DataRow
              key={i}
              form={form}
              instance={instance}
              label={data.label}
              name={data.name}
              toText={data.toText}
              openLCPopup={data.name === "lateCharge" ? openLCPopup : undefined}
              openInterestPopup={openInterestPopup}
              calculate={calculate}
              isHistoryOpened={isHistoryOpened}
              isPaymentPosted={isPaymentPosted}
              isFormDisabled={isFormDisabled}
            />
          );
        })}
        <Row className="w-100 align-center row passthru">
          <Col span={5} className="px-2">
            <p className="my-0 text-md">Mortgage/</p>
            <p className="my-0 text-md">Passthru</p>
          </Col>
          <Col span={6} className="px-2">
            <FloatNumberInput
              name={["breakdown", "mortgagePassthru"]}
              label=""
              form={form}
              className="totals-input mb-0"
              allowNegative
              onPressEnter={calculate}
              disabled={!isHistoryOpened || isPaymentPosted || isFormDisabled}
            />
          </Col>
        </Row>
      </Row>
      <LateChargesModal
        isOpen={isLCModalOpen}
        setIsOpen={setIsLCModalOpen}
        lateChargeOptions={lateChargeOptions}
        contractId={contractId}
        form={form}
        isFormDisabled={isFormDisabled}
        isPaymentPosted={isPaymentPosted}
        calculate={calculate}
        isHistoryOpened={isHistoryOpened}
      />
      <InterestAfterModal
        isOpen={isInterestModalOpen}
        setIsOpen={setIsInterestModalOpen}
        isPaymentPosted={isPaymentPosted}
        isFormDisabled={isFormDisabled}
        form={form}
        calculate={calculate}
      />
    </Row>
  );
};
