import { useMemo } from "react";
import { TemplateItem } from "../../../../types/Template";
import { DOCX_TYPE } from "../../../../lib/constants";

export const useGetInitialValues = (data: TemplateItem | null) => {
  const initialData: any = useMemo(() => {
    return data
      ? {
          ...data,
          file: [
            {
              name: data.fileName,
              type: DOCX_TYPE,
              status: "done",
              size: data.fileSizeBytes,
            },
          ],
        }
      : {};
  }, [data]);

  const id: string | null = useMemo(() => (data ? data.id : null), [data]);

  const isDefault: boolean = useMemo(
    () => (data ? data.isDefault : false),
    [data]
  );

  return { initialData, isDefault, id };
};
