import { FormInstance } from "antd";
import { useAppDispatch } from "../../../../../../../../../../../store";
import { useCallback } from "react";
import { updateImpoundActivity } from "../../../../../../../../../../../acions/accounts/impounds/activities";
import { IImpoundActivity } from "../../../../../../../../../../../types/ImpoundActivity";
import { transformToFloat } from "../../../../../../../../../../../lib/transformToFloat";

export const useUpdate = (
  id: string | null,
  form: FormInstance,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  closeModal: () => void
) => {
  const dispatch = useAppDispatch();

  const onSave = useCallback(async () => {
    if (id) {
      try {
        const values = await form.validateFields();

        setIsLoading(true);

        const request: IImpoundActivity = {
          ...values,
          date: values.date.format(),
          amount: transformToFloat(values.amount),
          balance: transformToFloat(values.balance),
        };

        await Promise.resolve(dispatch(updateImpoundActivity(id, request)));

        setIsLoading(false);

        closeModal();
      } catch {
        setIsLoading(false);
      }
    }
  }, [dispatch, form, id, setIsLoading, closeModal]);

  return { onSave };
};
