import { Button, Row } from "antd";
import "../../../../../../../../../components/DefaultFormButtons/style.scss";
import { TRecord } from "../types";
import { useCallback } from "react";

type TBtnsProps = {
  onSaveClick: (
    isNew: boolean,
    record: TRecord,
    customerIds?: any
  ) => Promise<void>;
  onCancelClick: () => void;
  onDeleteClick: (id: string) => void;
  isFormDisabled: boolean;
  record: TRecord;
  customerIds?: any;
};

export const FormButtons = ({
  onSaveClick,
  onCancelClick,
  onDeleteClick,
  isFormDisabled,
  record,
  customerIds,
}: TBtnsProps) => {
  const onDelete = useCallback(
    () => onDeleteClick(record.id || ""),
    [onDeleteClick, record]
  );

  const onSave = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const id: string = event.currentTarget.id;
      onSaveClick(id === "addNew", record, customerIds);
    },
    [customerIds, onSaveClick, record]
  );

  return (
    <Row className="default-form-buttons w-100 pa-6 divider d-flex justify-space-between position-absolute">
      <div className="d-flex default-form-buttons-main">
        <Button
          id="add"
          shape="round"
          type="primary"
          disabled={isFormDisabled}
          loading={isFormDisabled}
          onClick={onSave}
        >
          {record.id !== null ? "Save" : "Add"}
        </Button>
        {record.id === null && (
          <Button
            id="addNew"
            shape="round"
            disabled={isFormDisabled}
            loading={isFormDisabled}
            onClick={onSave}
          >
            Add & New
          </Button>
        )}
        <Button shape="round" disabled={isFormDisabled} onClick={onCancelClick}>
          Cancel
        </Button>
      </div>
      <div>
        {record.id !== null && (
          <Button type="link" disabled={isFormDisabled} onClick={onDelete}>
            Delete
          </Button>
        )}
      </div>
    </Row>
  );
};
