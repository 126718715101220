import { Button, Row } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { useCallback } from "react";
import { createPortal } from "react-dom";
import { useSidebar } from "../hooks/useSidebar";

type HeaderProps = {
  contractId: string;
  mailing: any;
  reloadData: () => void;
};

export const Header = ({ contractId, mailing, reloadData }: HeaderProps) => {
  const { formWrapper, isOpen, setIsOpen } = useSidebar(
    contractId,
    mailing,
    reloadData
  );

  const onSettingsClicked = useCallback(() => setIsOpen(true), [setIsOpen]);

  return (
    <Row className="w-100 d-flex justify-space-between">
      <h5 className="h h5 ant-table-title">Sent Mailings</h5>
      <Button
        color="primary"
        type="link"
        icon={<SettingOutlined />}
        onClick={onSettingsClicked}
      >
        Mailing Settings
      </Button>
      {isOpen && createPortal(formWrapper, document.body)}
    </Row>
  );
};
