import { Form, FormInstance } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";

type MessageProps = {
  form: FormInstance;
};

export const Message = ({ form }: MessageProps) => {
  const [isTemplateSelected, setIsTemplateSelected] = useState<boolean>(false);

  const DEFAULT_MESSAGE =
    "Hello!\n\nPlease see the attached notice regarding your Account.\n\n{Sunwest Logo}\nBest regards,\nSunwest Escrow Team";

  const templateId = Form.useWatch("templateId", form);

  useEffect(() => {
    if (templateId && !isTemplateSelected) {
      form.setFieldValue("message", DEFAULT_MESSAGE);
      setIsTemplateSelected(true);
    }
  }, [templateId, form, isTemplateSelected]);

  return (
    <Form.Item label="Message" name="message" className="mb-4">
      <TextArea rows={7} />
    </Form.Item>
  );
};
