import { useState, useCallback, ReactNode } from "react";
import { createPortal } from "react-dom";
import { Filters } from "./Filters";
import { Row, Table } from "antd";
import { useGetTableColumns } from "./hooks/userManagementTableColumns";
import { TablePagination } from "../../components/TablePagination";
import { useFetchUsersCount } from "./hooks/fetchUsersCount";
import { useFetchUserRoles } from "./hooks/fetchUserRoles";
import { useOpenSideBar } from "./hooks/openSideBar";
import { useTableSorter } from "./hooks/tableSorter";
import { useReasonModal } from "./hooks/reasonModal";
import { useDefaultDeleteModal } from "../../lib/hooks/defaultDeleteModal";
import { deleteUser } from "../../acions/users";
import { useSidebarHeader } from "../../lib/hooks/sidebarHeader";
import { useFetchData } from "../../lib/hooks/useDefaultFetchData";
import { UsersService } from "../../services/users";
import "./styles.scss";

type TRequest = {
  pageSize: number;
  pageNumber: number;
  statuses?: number[];
  order?: number;
  search?: string;
  roles?: string[];
};

export const UserManagement = () => {
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

  const { getHeader } = useSidebarHeader();

  const {
    total,
    isLoading: isTotalLoading,
    request,
    fetchDataCount,
    setSearchParams,
  } = useFetchUsersCount();

  const { onSortChange } = useTableSorter();

  const { data, isLoading, fetchData } = useFetchData<TRequest, any>(
    UsersService,
    request
  );

  const { roles, isLoading: isRolesLoading } = useFetchUserRoles();

  const onOpenBlockModal = (userId: string | null, isBlockValue: boolean) => {
    setSelectedUserId(userId);
    setShowModal(true);
    setIsBlock(isBlockValue);
  };

  const reloadData = useCallback(() => {
    fetchData();
    fetchDataCount();
  }, [fetchDataCount, fetchData]);

  const { openDeleteModal } = useDefaultDeleteModal(deleteUser, reloadData);

  const { showSideBar, sideBar, onOpenSideBar } = useOpenSideBar(
    selectedUserId,
    roles,
    setSelectedUserId,
    onOpenBlockModal,
    fetchData,
    openDeleteModal
  );

  const {
    modal: reasonModal,
    setShowModal,
    setIsBlock,
  } = useReasonModal(selectedUserId, reloadData);

  const onRow = useCallback(
    (record: any) => {
      const header: ReactNode = getHeader(record);

      return {
        onClick: () => onOpenSideBar(header, record.id, false),
      };
    },
    [getHeader, onOpenSideBar]
  );

  const { columns } = useGetTableColumns(
    onOpenSideBar,
    onOpenBlockModal,
    openDeleteModal
  );

  const onPageChange = useCallback(
    (pageNumber: number, pageSize: number) => {
      const newRequest = { ...request, pageNumber, pageSize };

      setSearchParams(newRequest);
    },
    [request, setSearchParams]
  );

  const onSorterChange = (_pagination: any, _filter: any, sorter: any) => {
    onSortChange(sorter, request);
  };

  return (
    <Row className="table-page-content mx-8 ">
      {reasonModal}
      <Row className="d-block width-100">
        <Row className="my-6">
          <h3 className="h h3">User Management</h3>
        </Row>
        <Filters
          roles={roles}
          isLoading={isLoading}
          isRolesLoading={isRolesLoading}
          request={request}
          reloadData={reloadData}
        />
      </Row>
      <Row className="d-block table__container mt-2">
        <Table
          dataSource={data}
          columns={columns}
          loading={isLoading}
          pagination={false}
          className="overflow-y-auto clickable-rows-table"
          onChange={onSorterChange}
          rowKey="id"
          showSorterTooltip={false}
          onRow={onRow}
        />
        <TablePagination
          isTotalLoading={isTotalLoading}
          total={total}
          currentPage={request.pageNumber}
          pageSize={request.pageSize}
          onPageChange={onPageChange}
        />
      </Row>
      {showSideBar && createPortal(sideBar, document.body)}
    </Row>
  );
};
