import { Row, Table } from "antd";
import { useFetchDataCount } from "../../../../../../../../../../lib/hooks/useDefaultFetchDataCount";
import { useFetchData } from "../../../../../../../../../../lib/hooks/useDefaultFetchData";
import ImpoundActivitiesService from "../../../../../../../../../../services/accounts/impounds/activities";
import { useDefaultTableScrollProp } from "../../../../../../../../../Customers/hooks/defaultTableScrollProp";
import { useTableColumns } from "./hooks/useTableColumns";
import { SimpleTablePagination } from "../../../../../../../../../../components/SimpleTablePagination";
import { Footer } from "../common/Footer";
import "./styles.scss";
import { HeaderTools } from "./HeaderTools";
import { useCallback } from "react";
import { EditModal } from "./EditModal";
import { IImpoundActivity } from "../../../../../../../../../../types/ImpoundActivity";
import { TRequest } from "../common/types";
import { useDefualtOnRow } from "../../../../../../../../../../lib/hooks/useDefaultOnRow";

type ActivityDetailsProps = {
  impoundId: string | null;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setParentId: React.Dispatch<React.SetStateAction<string | null>>;
  balanceBeginningYear: number | null;
};

export const ActivityDetails = ({
  impoundId,
  setIsOpen,
  setParentId,
  balanceBeginningYear,
}: ActivityDetailsProps) => {
  const {
    isLoading: isCountLoading,
    total,
    request,
    onPageChange,
    fetchCount,
  } = useFetchDataCount<{ impoundId: string | null }>(
    ImpoundActivitiesService,
    { impoundId }
  );

  const { isLoading, data, fetchData } = useFetchData<
    TRequest,
    IImpoundActivity
  >(ImpoundActivitiesService, request);

  const reloadData = useCallback(() => {
    fetchData();
    fetchCount();
  }, [fetchCount, fetchData]);

  const { scroll } = useDefaultTableScrollProp(data, 350);

  const { columns, openedActivityId, setOpenedActivityId } =
    useTableColumns(reloadData);

  const { onRow } = useDefualtOnRow(setOpenedActivityId);

  return (
    <Row className="w-100 activities__container">
      <HeaderTools balanceBeginningYear={balanceBeginningYear} />
      <Row className="px-6 pb-6 w-100">
        <Table
          className="w-100 clickable-rows-table"
          dataSource={data}
          columns={columns}
          pagination={false}
          rowKey="id"
          scroll={scroll}
          loading={isLoading}
          onRow={onRow}
        />
        {total > 20 && (
          <SimpleTablePagination
            isLoading={isCountLoading}
            total={total}
            pageNumber={request.pageNumber}
            onPageChange={onPageChange}
            pageSize={request.pageSize}
          />
        )}
      </Row>
      <Footer setId={setParentId} setIsOpen={setIsOpen} />
      <EditModal
        activityId={openedActivityId}
        setActivityId={setOpenedActivityId}
        reloadData={fetchData}
      />
    </Row>
  );
};
