import { ThunkAction } from "redux-thunk";
import { RootState } from "../store";
import { AnyAction } from "redux";
import EmailsOutboxService from "../services/emails/outbox";
import {
  setErrorMessage,
  setSuccessMessage,
} from "./actionsCreators/messageActions";
import {
  ALL_EMAILS_WAS_DELETED,
  DELETE_USER_SUCCESS,
  EMAIL_WAS_SENT,
} from "../lib/constants";

export const sendOne =
  (id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await EmailsOutboxService.sendOne(id);

      dispatch(setSuccessMessage(EMAIL_WAS_SENT));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };

export const deleteEmail =
  (id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await EmailsOutboxService.remove(id);

      dispatch(setSuccessMessage(DELETE_USER_SUCCESS));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };

export const deleteAllEmails =
  (_: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const res = await EmailsOutboxService.removeAll();

      dispatch(setSuccessMessage(ALL_EMAILS_WAS_DELETED));

      return await Promise.resolve(res);
    } catch (error: any) {
      dispatch(setErrorMessage(error.response.data.title, true));

      return await Promise.reject(error);
    }
  };
