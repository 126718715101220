import { useCallback, useEffect } from "react";
import ImpoundActivitiesService from "../../../../../../../../../../../services/accounts/impounds/activities";
import { FormInstance } from "antd";
import dayjs from "dayjs";
import { transformToFormatted } from "../../../../../../../../../../../lib/transformToFloat";

export const useFetch = (
  id: string | null,
  form: FormInstance,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const fetchData = useCallback(async () => {
    if (id) {
      try {
        const response = await ImpoundActivitiesService.fetch(id);

        const { date, amount, balance } = response;

        response.date = dayjs(date);

        response.amount = transformToFormatted(amount);
        response.balance = transformToFormatted(balance);

        form.setFieldsValue(response);

        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    }
  }, [id, form, setIsLoading]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
};
