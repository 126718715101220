import { useCallback, useEffect, useMemo, useState } from "react";
import LateChargesService from "../../../services/administration/latecharges";
import { useFetchDataCount } from "../hooks/useFetchDataCount";
import { useGetTableColumns } from "./hooks/useGetTableColumns";
import { Button, Card, Row, Table } from "antd";
import { SimpleTablePagination } from "../../../components/SimpleTablePagination";
import { CalcForm } from "./CalcForm";
import { createPortal } from "react-dom";
import { useDefaultFormSidebar } from "../../../lib/hooks/defaultFormSidebar";
import { LateChargeForm } from "./LateChargeForm";
import { useContainerClasses } from "./hooks/useContainerClasses";
import ContractLateChargesService from "../../../services/accounts/lateCharges";
import { useGetData } from "./hooks/useGetData";
import { ContractTotals } from "./ContractTotals";
import { Loader } from "../../../components/Loader";
import { useDefualtOnRow } from "../../../lib/hooks/useDefaultOnRow";

type LateChargesProps = {
  accountId?: string;
  setLCDays1?: (val: number) => void;
};

export const LateCharges = ({
  accountId = undefined,
  setLCDays1,
}: LateChargesProps) => {
  const { upperClass, lowerClass } = useContainerClasses(accountId);

  const [totalsKey, setTotalsKey] = useState<boolean>(false);

  const [id, setId] = useState<string | null>(null);

  const service: any = useMemo(() => {
    return accountId ? ContractLateChargesService : LateChargesService;
  }, [accountId]);

  const {
    request,
    isLoading: isCountLoading,
    total,
    onPageChange,
    fetchDataCount,
  } = useFetchDataCount(service.fetchCount, 10, accountId);

  const { data, isLoading, fetchData } = useGetData(
    request,
    accountId,
    service
  );

  useEffect(() => {
    if (setLCDays1) {
      setLCDays1(data.length > 0 ? data[0].assessAfterDays : 0);
    }
  }, [data, setLCDays1]);

  const reloadData = useCallback(() => {
    fetchData();
    fetchDataCount();
    setId(null);

    if (accountId) {
      setTotalsKey(!totalsKey);
    }
  }, [fetchData, fetchDataCount, accountId, totalsKey]);

  const { formWrapper, isOpen, setIsOpen } = useDefaultFormSidebar({
    id,
    setId,
    reloadData,
    title: "Late Charge",
    FormElem: LateChargeForm,
    parentId: accountId,
  });

  const openSideBar = useCallback(
    (id: string): void => {
      setIsOpen(true);
      setId(id);
    },
    [setId, setIsOpen]
  );

  const { onRow } = useDefualtOnRow(openSideBar);

  const { columns } = useGetTableColumns({
    reloadData,
    setIsOpen,
    setId,
    accountId,
    openSideBar,
  });

  return (
    <Row className={upperClass}>
      <Row className={lowerClass}>
        <Card>
          <Row className="w-100 d-flex justify-space-between">
            <h5 className="h h5 ant-table-title">
              {accountId ? "Late Charges" : "Default Late Charges"}
            </h5>
            <Button shape="round" onClick={() => setIsOpen(true)}>
              Add New
            </Button>
          </Row>
          {accountId && isLoading && data.length === 0 && (
            <Row className="d-block w-100 mt-6 table__container">
              <Loader fontSize="64px" />
            </Row>
          )}
          {(!accountId || data.length !== 0) && (
            <Row className="d-block w-100 mt-6 table__container">
              <Table
                dataSource={data}
                columns={columns}
                loading={isLoading}
                pagination={false}
                rowKey="id"
                className="overflow-y-auto clickable-rows-table"
                onRow={onRow}
              />
              {total > 10 && (
                <SimpleTablePagination
                  isLoading={isCountLoading}
                  total={total}
                  pageNumber={request.pageNumber}
                  onPageChange={onPageChange}
                />
              )}
              {accountId && (
                <ContractTotals
                  accountId={accountId}
                  key={`ContractTotals${+totalsKey}`}
                />
              )}
            </Row>
          )}
        </Card>
        {!accountId && <CalcForm reloadData={reloadData} />}
      </Row>
      {isOpen && createPortal(formWrapper, document.body)}
    </Row>
  );
};
