import { API_URL } from "../../configs/apiUrl";
import authAxios, { authHeader } from "../data";
import EmailsOutboxService from "../emails/outbox";

const fetchAll = async (request: any) => {
  return await EmailsOutboxService.fetchAll({ ...request, type: 2 });
};

const fetchCount = async (request: any) => {
  return await EmailsOutboxService.fetchCount({ ...request, type: 2 });
};

const saveSettings = async (request: any, id: string) => {
  const result = await authAxios.put(
    `${API_URL}Contracts/${id}/mailing`,
    request,
    {
      headers: authHeader(),
    }
  );

  return result.data;
};

const sendEmail = async (request: any, contractId: string) => {
  return new Promise((resolve: any) => {
    setTimeout(() => {
      resolve(1);
    }, 1000);
  });
};

const MailingsService = {
  fetchAll,
  fetchCount,
  saveSettings,
  sendEmail,
};

export default MailingsService;
