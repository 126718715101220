import { useCallback, useState } from "react";
import { useAppDispatch } from "../../../../../../../store";
import { saveSettings } from "../../../../../../../acions/accounts/mailings";
import { FormInstance } from "antd";

export const useSave = (
  form: FormInstance,
  contractId: string,
  reloadData: () => void,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const dispatch = useAppDispatch();

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const onSave = useCallback(async () => {
    try {
      setIsDisabled(true);

      const request = await form.validateFields();

      const { lateNoticePayers, lateNoticePayees } = request;

      if (!lateNoticePayers) {
        delete request.latePayerDays;
      }

      if (!lateNoticePayees) {
        delete request.latePayeeDays;
      }

      await Promise.resolve(dispatch(saveSettings(request, contractId)));

      setIsDisabled(false);
      setIsOpen(false);
      reloadData();
    } catch (e: any) {
      setIsDisabled(false);
    }
  }, [contractId, dispatch, form, reloadData, setIsOpen]);

  return { isDisabled, onSave };
};
