import { ReactNode, useCallback, useMemo } from "react";
import { Modal } from "../../../Modal";
import { Button, Col, FormInstance, Row } from "antd";
import { ConfigForm } from "./ConfigForm";
import { getFormattedCurrency } from "../../../../../../lib/currencyFormatter";
import { GeneralConfiguration } from "./GeneralConfiguration";

type LateChargesModalProps = {
  contractId: string;
  isOpen: boolean;
  form: FormInstance;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  lateChargeOptions: any;
  isFormDisabled: boolean;
  isPaymentPosted: boolean;
  calculate: (toUpdate?: {
    name: string;
    value: any;
    isUpdatedField?: boolean;
  }) => void;
  isHistoryOpened: boolean;
};

export const LateChargesModal = ({
  contractId,
  isOpen,
  form,
  setIsOpen,
  lateChargeOptions,
  isFormDisabled,
  isPaymentPosted,
  calculate,
  isHistoryOpened,
}: LateChargesModalProps) => {
  const closeModal = useCallback(() => setIsOpen(false), [setIsOpen]);

  const title: ReactNode = useMemo(() => {
    return (
      <Row className="align-center">
        <h5 className="h h5">Late Charge Configuration</h5>
      </Row>
    );
  }, []);

  const getUnpaidRow = useCallback(
    (label: string, name: string): ReactNode => {
      const value = lateChargeOptions[name] || 0;

      const clasName: string = `unpaid-value text-sm elipsis ${
        value !== 0 && "text-color-red"
      }`;

      return (
        <Row className="justify-space-between align-center mb-1 w-100">
          <span className="text-sm">{label}</span>
          <span className={clasName} title={value}>
            {getFormattedCurrency(value)}
          </span>
        </Row>
      );
    },
    [lateChargeOptions]
  );

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      footer={
        <Button
          type="primary"
          shape="round"
          onClick={closeModal}
          disabled={isFormDisabled}
          loading={isFormDisabled}
        >
          Close
        </Button>
      }
      width={670}
    >
      <Row className="px-8 pb-6 pt-8 late-charges-popup">
        <Row className="align-end w-100">
          <Col span={13}>
            <ConfigForm
              form={form}
              isFormDisabled={isFormDisabled}
              isPaymentPosted={isPaymentPosted}
              calculate={calculate}
              isHistoryOpened={isHistoryOpened}
            />
          </Col>
          <Col span={11}>
            <Row className="d-block ml-8 mb-2 py-2 px-3 unpaid-amounts-container">
              {getUnpaidRow("Unpaid Before:", "lateChargeBefore")}
              {getUnpaidRow("Unpaid After:", "lateChargeAfter")}
            </Row>
          </Col>
        </Row>
        <GeneralConfiguration contractId={contractId} />
      </Row>
    </Modal>
  );
};
