import { useCallback, useEffect, useState } from "react";

export const useFetchData = <TReq, TData>(
  service: any,
  request: TReq
): {
  isLoading: boolean;
  data: TData[];
  fetchData: () => void;
  setData: React.Dispatch<React.SetStateAction<TData[]>>;
} => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [data, setData] = useState<TData[]>([]);

  const fetchData = useCallback(() => {
    setIsLoading(true);

    service
      .fetchAll(request)
      .then((res: TData[]) => {
        setData(res);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [request, service]);

  useEffect(() => fetchData(), [fetchData]);

  return { isLoading, data, fetchData, setData };
};
