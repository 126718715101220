import { useCallback, useState } from "react";
import BatchDisbursementsService from "../../../../../../../services/processing/disbursements";
import { FormInstance } from "antd";
import { useAppDispatch } from "../../../../../../../store";
import { setErrorMessage } from "../../../../../../../acions/actionsCreators/messageActions";
import { NO_CHECKS_TO_ASSIGN } from "../../../../../../../lib/constants";

type TResponse = {
  nextCheckNumber: number;
  canAssign: boolean;
  hasAssigned: boolean
};

type TOnAssignClick = {
  batchId: string;
  form: FormInstance;
  selectedDisbursementIds?: string[];
};

export const useOnAssignClick = ({
  batchId,
  form,
  selectedDisbursementIds = [],
}: TOnAssignClick) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  
  const [hasAssigned, setHasAssigned] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const onAssignClick = useCallback(() => {
    setIsDisabled(true);

    BatchDisbursementsService.fetchCheckNumber({ batchId, selectedDisbursementIds })
      .then((res: TResponse) => {
        if (res.canAssign) {
          setIsOpen(true);
          setHasAssigned(res.hasAssigned);
          form.setFieldValue("checkNumber", res.nextCheckNumber);
        } else {
          dispatch(setErrorMessage(NO_CHECKS_TO_ASSIGN, true));
        }
        setIsDisabled(false);
      })
      .catch((error) => {
        setIsDisabled(false)
        dispatch(setErrorMessage(error.response.data.title, true));
      });
  }, [batchId, dispatch, form, selectedDisbursementIds]);

  return { isDisabled, isOpen, onAssignClick, setIsOpen, hasAssigned };
};
