import { Form, FormInstance } from "antd";
import { useCallback, useMemo } from "react";
import { transformToFloat } from "../../../../../../../../../lib/transformToFloat";
import _ from "lodash";

export const useCalculateTotals = (form: FormInstance) => {
  const buyerFields = Form.useWatch(["buyer"], form);

  const sellerFields = Form.useWatch(["seller"], form);

  const subtrahendFields = Form.useWatch(["subtrahend"], form);

  const getSum = useCallback(
    (data: any) =>
      _.sum(
        Object.values(data).map((item: any) => transformToFloat(item) || 0)
      ),
    []
  );

  const buyerSubtotal = useMemo(
    () => buyerFields && getSum(buyerFields),
    [buyerFields, getSum]
  );

  const sellerFeeTotal = useMemo(
    () => sellerFields && getSum(sellerFields),
    [getSum, sellerFields]
  );

  const buyerTotal = useMemo(() => {
    if (subtrahendFields) {
      const subtrahend = getSum(subtrahendFields);
      return buyerSubtotal - subtrahend;
    }

    return 0;
  }, [buyerSubtotal, getSum, subtrahendFields]);

  return { buyerSubtotal, sellerFeeTotal, buyerTotal };
};
