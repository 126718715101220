import { Alert } from "./lib/Alert";
import "./styles/index.scss";
import "antd-css-utilities/utility.min.css";
import { NotificationContainer } from "./lib/Notification/NotificationContainer";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { useAppDispatch } from "./store";
import { setErrorMessage } from "./acions/actionsCreators/messageActions";

export default function App() {
  const dispatch = useAppDispatch();

  const message = useSelector((state: any) => state.message.message);

  useEffect(() => {
    const info = Cookies.get("info");

    if (info) {
      Cookies.remove("info");
      dispatch(setErrorMessage(info, false));
    }
  }, [dispatch]);

  return (
    <>
      {message && message.type && (
        <NotificationContainer>
          <Alert
            type={message.type}
            shouldDisapear={message.shouldDisapear}
            closable={message.closable}
          >
            {message.message}
          </Alert>
        </NotificationContainer>
      )}
    </>
  );
}
