import { Button, Row } from "antd";

type TFormButtons = {
  id: string | null;
  onCancelBtnClick: () => void;
  onSaveBtnClick: (id: string | null, customersIds: any) => Promise<void>;
  customersIds: any;
  isDisabled: boolean;
  createButtonTitle?: string;
};

export const FormButtons = ({
  id,
  onCancelBtnClick,
  onSaveBtnClick,
  customersIds,
  isDisabled,
  createButtonTitle = "Create",
}: TFormButtons) => {
  return (
    <Row className="pt-6 pb-1 w-100 d-flex justify-end">
      <Button
        shape="round"
        type="primary"
        className="mr-2"
        onClick={() => onSaveBtnClick(id, customersIds)}
        disabled={isDisabled}
      >
        {id !== null ? "Save" : createButtonTitle}
      </Button>
      <Button shape="round" onClick={onCancelBtnClick} disabled={isDisabled}>
        Cancel
      </Button>
    </Row>
  );
};
