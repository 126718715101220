import { Form, FormInstance } from "antd";
import { Modal } from "../../../Modal";
import { FloatNumberInput } from "../../../../../../components/FloatNumberInput";
import { useButtonHandlers } from "./hooks/useButtonHandlers";
import { ModalFooter } from "../common/ModalFooter";
import "./styles.scss";

type TInterestAfterModalProps = {
  isOpen: boolean;
  form: FormInstance;
  isFormDisabled: boolean;
  isPaymentPosted: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  calculate: () => void;
};

export const InterestAfterModal = ({
  isOpen,
  form,
  isFormDisabled,
  isPaymentPosted,
  setIsOpen,
  calculate,
}: TInterestAfterModalProps) => {
  const { close, onSaveClick } = useButtonHandlers(setIsOpen, calculate);

  return (
    <Modal
      title="Change Interest Balance"
      isOpen={isOpen}
      footer={
        <ModalFooter
          isFormDisabled={isFormDisabled}
          isPaymentPosted={isPaymentPosted}
          closeModal={close}
          onSaveClicked={onSaveClick}
        />
      }
      width={260}
      className="terms-modal interest-after-modal"
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        className="px-6 pt-6 pb-3"
      >
        <FloatNumberInput
          form={form}
          name={["breakdown", "interestAfter"]}
          label="Unpaid Interest Balance After"
        />
      </Form>
    </Modal>
  );
};
