import { useEffect, useMemo, useState } from "react";
import { TemplateItem } from "../../../../../../../../../../../types/Template";
import TemplatesService from "../../../../../../../../../../../services/administration/templates";

export const useFetchTemplates = () => {
  const [data, setData] = useState<TemplateItem[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    TemplatesService.fetchAll({ isOther: true })
      .then((res) => {
        if (res.length > 0) {
          const templates: TemplateItem[] = res[0].templates;

          setData(templates);
          setIsLoading(false);
        }
      })
      .catch(() => setIsLoading(false));
  }, []);

  const formatted = useMemo(
    () =>
      data.map((item: TemplateItem) => ({
        value: item.id,
        label: item.name,
      })),
    [data]
  );

  return { isLoading, data, formatted };
};
