import { useMemo } from "react";
import { useGetFullNameFormRules } from "../../../../../../../../../lib/hooks/getFullNameFormRules";
import { MAX_COMPANY_NAME } from "../../../../../../../../../lib/constants";

export const useReceiverFormOptions = (isIndividual: boolean) => {
  const { formOptions: formOptionsIndividual } = useGetFullNameFormRules(
    true,
    "receiver"
  );

  const formOptionsCompany = useMemo(() => {
    return [
      {
        label: "Company Name",
        name: ["receiver", "companyName"],
        rules: [
          {
            max: 150,
            message: MAX_COMPANY_NAME,
            validateTrigger: "onSubmit",
          },
          {
            required: true,
            validateTrigger: "onSubmit",
          },
        ],
      },
    ];
  }, []);

  const formOptions = useMemo(() => {
    return isIndividual ? formOptionsIndividual : formOptionsCompany;
  }, [formOptionsCompany, formOptionsIndividual, isIndividual]);

  return { formOptions };
};
