import { useMemo } from "react";
import { CustomModal } from "../../../lib/CustomModal";
import { PrintForm } from "./PrintForm";
import "./styles.scss";

type TPrintModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  contentType: number;
  setContentType: React.Dispatch<React.SetStateAction<number>>;
};

export const PrintModal = ({
  isOpen,
  setIsOpen,
  contentType,
  setContentType,
}: TPrintModalProps) => {
  const TITLE_OPTIONS = useMemo(
    () => [
      { value: 2, label: "Print Batch Report" },
      { value: 3, label: "Print Payments Report" },
      { value: 4, label: "Print Disbursements Report" },
    ],
    []
  );

  const title = useMemo(
    () => TITLE_OPTIONS.find((title) => title.value === contentType)?.label,
    [TITLE_OPTIONS, contentType]
  );

  return (
    <CustomModal
      isModalOpen={isOpen}
      setIsModalOpen={setIsOpen}
      width={400}
      titleText={title || ""}
      headerClass="processing-print-modal--header"
      modalClass="processing-print-modal"
    >
      <PrintForm
        isBatchReport={contentType === 2}
        setIsOpen={setIsOpen}
        setContentType={setContentType}
        contentType={contentType}
      />
    </CustomModal>
  );
};
