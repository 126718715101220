import { FormInstance } from "antd";
import { useCallback, useState } from "react";
import {
  createFeeType,
  editFeeType,
} from "../../../../acions/administration/fees";
import { useAppDispatch } from "../../../../store";
import { IFeeType } from "../../../../types/Fees";
import { setErrorMessage } from "../../../../acions/actionsCreators/messageActions";
import {
  FEE_TYPE_CANT_BE_DELETED,
  INITIAL_FEE_AMOUNTS,
} from "../../../../lib/constants";
import { useDeleteModal } from "./useDeleteModal";
import { useTransformRequest } from "./useTransformRequest";

type TButtonHandlersProps = {
  parentId: string | undefined;
  reloadData: () => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  form?: FormInstance;
  setId?: React.Dispatch<React.SetStateAction<string | null>>;
};

export function useButtonHandlers({
  parentId,
  reloadData,
  form,
  setIsOpen,
  setId,
}: TButtonHandlersProps) {
  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);

  const { transform } = useTransformRequest(parentId);

  const { openDeleteModal } = useDeleteModal(reloadData, setIsOpen);

  const dispatch = useAppDispatch();

  const onDeleteBtnClick = useCallback(
    (id: string, references: number) => {
      if (parentId) {
        openDeleteModal({ id, contractId: parentId }, "Delete a Fee");
      } else {
        references === 0
          ? openDeleteModal({ id }, "Delete a Fee Type")
          : dispatch(setErrorMessage(FEE_TYPE_CANT_BE_DELETED, true));
      }
    },
    [openDeleteModal, dispatch, parentId]
  );

  const create = useCallback(
    async (isNew: boolean, feeEntry: IFeeType) => {
      try {
        await Promise.resolve(dispatch(createFeeType(feeEntry)));

        reloadData();
        setIsFormDisabled(false);
        setIsOpen(isNew);
        form?.resetFields();
        isNew && form?.setFieldsValue(INITIAL_FEE_AMOUNTS);
      } catch (error) {
        setIsFormDisabled(false);
      }
    },
    [dispatch, form, reloadData, setIsOpen]
  );

  const edit = useCallback(
    async (feeEntry: IFeeType, currentId: string) => {
      try {
        await Promise.resolve(dispatch(editFeeType(feeEntry, currentId)));

        setIsFormDisabled(false);
        setIsOpen(false);
        reloadData();
      } catch (error) {
        setIsFormDisabled(false);
      }
    },
    [dispatch, reloadData, setIsOpen]
  );

  const onCancelClick = useCallback(() => {
    setIsOpen(false);
    setId && setId(null);
  }, [setId, setIsOpen]);

  const onSaveClick = useCallback(
    async (isNew: boolean, currentId: string | null) => {
      try {
        const formData = await form?.validateFields();

        transform(formData);

        setIsFormDisabled(true);

        currentId !== null
          ? edit(formData, currentId)
          : create(isNew, formData);
      } catch (error) {
        setIsFormDisabled(false);
      }
    },
    [create, edit, form, transform]
  );

  return {
    onDeleteBtnClick,
    onSaveClick,
    isFormDisabled,
    onCancelClick,
  };
}
