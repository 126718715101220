import { Row, Tabs } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TemplateCategoryItem } from "../../../../types/Template";
import { Loader } from "../../../../components/Loader";

type CategoriesProps = {
  data: TemplateCategoryItem[];
  isLoading: boolean;
  category: TemplateCategoryItem | null;
  setCategory: React.Dispatch<
    React.SetStateAction<TemplateCategoryItem | null>
  >;
};

export const Categories = ({
  data,
  isLoading,
  category,
  setCategory,
}: CategoriesProps) => {
  const [active, setActive] = useState<any>(null);

  const items: any[] = useMemo(
    () =>
      data
        ? data.map((tab) => ({
            ...tab,
            key: tab.id,
            label: tab.categoryName,
          }))
        : [],
    [data]
  );

  useEffect(() => {
    if (data && data.length > 0 && !category) {
      setActive(data[0].id);
    }
  }, [category, data, setCategory]);

  useEffect(() => {
    const item = items.find((item) => item.id === active);

    item && setCategory(item);
  }, [active, items, setCategory]);

  const onTabChange = useCallback((id: string) => setActive(id), []);

  return (
    <Row className="d-block">
      <h5 className="h mb-6 h5 py-1 ant-table-title">Forms Templates</h5>
      {isLoading ? (
        <Row className="pt-8 pr-8 w-100 justify-center">
          <Loader fontSize="32px" />
        </Row>
      ) : (
        <Tabs
          activeKey={active}
          tabPosition="left"
          items={items}
          onChange={onTabChange}
        />
      )}
    </Row>
  );
};
