import { Button, Row } from "antd";
import { useCallback } from "react";

type TFooter = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
};

export const Footer = ({ setIsOpen, setId }: TFooter) => {
  const onClose = useCallback(() => {
    setId(null);
    setIsOpen(false);
  }, [setId, setIsOpen]);

  return (
    <Row className="pa-6 w-100 divider position-absolute footer">
      <Button shape="round" onClick={onClose}>
        Cancel
      </Button>
    </Row>
  );
};
