import { Form, FormInstance } from "antd";
import { useMemo, useState } from "react";
import { useFetchPrimaryData } from "../hooks/useFetchPrimaryData";
import { SendToFormItem } from "./SendToFormItem";
import { SubjectRow } from "./SubjectRow";
import { Message } from "./Message";
import { Attachments } from "./Attachments";
import { useSend } from "../hooks/useSend";
import { useOnFail } from "../hooks/useOnFail";

type FormContainerProps = {
  contractId: string;
  form: FormInstance;
  primaryData: {
    buyerId: string;
    sellerId: string;
  };
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
  close: () => void;
};

export const FormContainer = ({
  contractId,
  form,
  primaryData,
  isLoading,
  setIsLoading,
  setError,
  close,
}: FormContainerProps) => {
  const [sendToData, setSendToData] = useState<any[]>([]);

  const { onFail, clearError } = useOnFail(setError);

  const { send } = useSend(contractId, sendToData, setIsLoading, close, onFail);

  const { formattedData: initalData } = useFetchPrimaryData(
    primaryData,
    setIsLoading
  );

  const initialValues = useMemo(
    () => ({
      subject: "Important documents from Sunwest Escrow",
      message: "{Sunwest Logo}",
    }),
    []
  );

  return (
    <Form
      form={form}
      autoComplete="off"
      layout="vertical"
      requiredMark={false}
      disabled={isLoading}
      initialValues={initialValues}
      onFinish={send}
      onChange={clearError}
    >
      <SendToFormItem
        form={form}
        initalData={initalData}
        optionsData={sendToData}
        setOptionsData={setSendToData}
        clearError={clearError}
      />

      <SubjectRow form={form} isLoading={isLoading} clearError={clearError} />

      <Message form={form} />

      <Attachments clearError={clearError} />
    </Form>
  );
};
