import { useCallback, useEffect, useState } from "react";
import { TemplateCategoryItem } from "../../../../types/Template";
import TemplatesService from "../../../../services/administration/templates";

export const useFetchData = () => {
  const [isCatLoading, setCatIsLoading] = useState<boolean>(false);
  const [isTempLoading, setIsTempLoading] = useState<boolean>(false);

  const [data, setData] = useState<TemplateCategoryItem[]>([]);

  const fetchData = useCallback((isFirstLoad: boolean = true) => {
    isFirstLoad && setCatIsLoading(true);
    setIsTempLoading(true);

    TemplatesService.fetchAll()
      .then((res: TemplateCategoryItem[]) => {
        setData(res);

        isFirstLoad && setCatIsLoading(false);
        setIsTempLoading(false);
      })
      .catch(() => {
        isFirstLoad && setCatIsLoading(false);
        setIsTempLoading(false);
      });
  }, []);

  useEffect(() => fetchData(), [fetchData]);

  return { isCatLoading, isTempLoading, data, fetchData };
};
