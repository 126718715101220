import { ColumnsType } from "antd/es/table";
import dayjs from "../../../../../../configs/dayjs";
import { DATE_FORMAT } from "../../../../../../lib/constants";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { getFormattedCurrency } from "../../../../../../lib/currencyFormatter";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import ROUTES from "../../../../../../configs/routesConfig";

export const useColumns = () => {
  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: "Date",
        dataIndex: "date",
        render: (value: string) => dayjs(value).format(DATE_FORMAT),
        sorter: true,
      },
      {
        title: "Account Number",
        dataIndex: "contractNumber",
        render: (value: number, record: any) => (
          <div className="cell-elipsis">
            <Link
              to={`${ROUTES.ACCOUNTS}/${record.contractId}`}
              target="_blank"
            >
              <span className="link-btn">{value}</span>
            </Link>
          </div>
        ),
        sorter: true,
      },
      {
        title: "Type",
        dataIndex: "type",
        render: (value: number) => (
          <div className="d-flex align-center">
            <div
              className={`mr-2 d-flex justify-center payment-icon ${
                value === 2 && "disb"
              }`}
            >
              {value === 1 ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}
            </div>
            {value === 1 ? "Payment" : "Disbursement"}
          </div>
        ),
        sorter: true,
      },
      {
        title: "Amount",
        dataIndex: "amount",
        render: (value: number) => getFormattedCurrency(value),
      },
    ],
    []
  );

  return { columns };
};
