import { Button, Dropdown, MenuProps } from "antd";
import { useMemo } from "react";
import { MoreOutlined } from "@ant-design/icons";

type TModeDropdownProps = {
  isDisabled: boolean;
  onOpenChecksModeClick: () => void;
};

export const ModeDropdown = ({
  isDisabled,
  onOpenChecksModeClick,
}: TModeDropdownProps) => {
  const items: MenuProps["items"] = useMemo(
    () => [
      {
        key: 1,
        label: <div onClick={onOpenChecksModeClick}>Open Checks Mode</div>,
      },
    ],
    [onOpenChecksModeClick]
  );

  return (
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      className="mr-4"
      disabled={isDisabled}
    >
      <Button shape="circle" icon={<MoreOutlined />} />
    </Dropdown>
  );
};
