import { useCallback, useEffect, useMemo, useState } from "react";
import { ILateChargeExtended } from "../../../../types/LateCharges";
import LateChargesService from "../../../../services/administration/latecharges";
import ContractLateChargesService from "../../../../services/accounts/lateCharges";
import {
  LATE_CHARGES_ASSESS_METHOD,
  LATE_CHARGES_TYPE,
} from "../../../../lib/constants";
import { transformToFormatted } from "../../../../lib/transformToFloat";

type TInitialValuesProps = {
  id: string | null;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  parentId: string | undefined;
};

export const useGetInitialValues = ({
  id,
  setIsLoading,
  parentId,
}: TInitialValuesProps) => {
  const [entry, setEntry] = useState<ILateChargeExtended | null>(null);

  const fetchLateCharge = useCallback(() => {
    if (id !== null) {
      setIsLoading(true);

      const service = parentId
        ? ContractLateChargesService
        : LateChargesService;

      service
        .fetch(id)
        .then((res: any) => {
          setEntry(res);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [id, setIsLoading, parentId]);

  useEffect(() => {
    fetchLateCharge();
  }, [fetchLateCharge, id]);

  return useMemo(() => {
    return entry !== null
      ? {
          assessAfterDays: entry.assessAfterDays,
          type: entry.type,
          percentage: entry.percentage?.toFixed(4) || 0.0,
          amount: transformToFormatted(entry.amount) || 0.0,
          assessMethod: entry.assessMethod,
        }
      : {
          type: LATE_CHARGES_TYPE[0].value,
          assessMethod: LATE_CHARGES_ASSESS_METHOD[0].value,
          amount: "0.00",
        };
  }, [entry]);
};
