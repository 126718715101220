import {
  Button,
  DatePicker,
  Dropdown,
  Form,
  FormInstance,
  Input,
  InputRef,
  MenuProps,
  Row,
  Select,
} from "antd";
import { CustomerInputSearch } from "../../../../../../../../../Accounts/components/Filters/CustomerInputSearch";
import { FloatNumberInput } from "../../../../../../../../../../components/FloatNumberInput";
import { useGetInpurSearchIds } from "../../../../../../../../../Accounts/components/Filters/hooks/getInputSearchIds";
import { useButtonHandlers } from "./hooks/useButtonHandlers";
import { FormButtons } from "../common/FormButtons";
import {
  DATE_FORMAT,
  DISTRIBUTION_DESC_MAX,
  DISBURSEMENTS_METHOD_TYPES,
  DISTRIBUTION_AMOUNT_LIMIT,
} from "../../../../../../../../../../lib/constants";
import { useCallback, useEffect, useMemo, useRef, MouseEvent } from "react";
import { MoreOutlined } from "@ant-design/icons";
import { useFetchBatches } from "./hooks/useFetchBatches";
import { Rule } from "antd/es/form";
import dayjs from "dayjs";
import { transformToFloat } from "../../../../../../../../../../lib/transformToFloat";

const { TextArea } = Input;

type TDistributionFormProps = {
  reloadData: () => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  parentId: string | null;
  primaryBuyerNumber: number;
  primarySellerNumber: number;
  impoundCurrentBalance: number;
};

export const DistributionForm = ({
  reloadData,
  setIsOpen,
  parentId,
  primaryBuyerNumber,
  primarySellerNumber,
  impoundCurrentBalance,
}: TDistributionFormProps) => {
  const [form]: FormInstance[] = Form.useForm();

  const ref = useRef<InputRef>(null);

  const { onCustomerFound, hasPayeeId, customerIds } = useGetInpurSearchIds();

  const { options, isLoading: isBatchesLoading } = useFetchBatches(form);

  const { isFormDisabled, onCancelBtnClick, onSaveBtnClick } =
    useButtonHandlers({ reloadData, setIsOpen, parentId, form, options });

  const onChange = useCallback(
    (e: MouseEvent<HTMLInputElement>) => {
      const id = e.currentTarget.id;
      form.setFieldValue(
        "payeeId",
        id === "1" ? primaryBuyerNumber : primarySellerNumber
      );
      ref.current && ref.current.focus();
    },
    [form, primaryBuyerNumber, primarySellerNumber]
  );

  const items: MenuProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: (
          <div id={"1"} onClick={onChange}>
            Set Primary Buyer
          </div>
        ),
      },
      {
        key: "2",
        label: (
          <div id={"2"} onClick={onChange}>
            Set Primary Seller
          </div>
        ),
      },
    ],
    [onChange]
  );

  const amountRules = useMemo(() => {
    return [
      {
        required: true,
        validateTrigger: "onSubmit",
      },
      () => ({
        validateTrigger: "onSubmit",
        transform: transformToFloat,
        validator(_: Rule, value: number) {
          if (value <= impoundCurrentBalance) {
            return Promise.resolve("");
          }

          return Promise.reject(new Error(DISTRIBUTION_AMOUNT_LIMIT));
        },
      }),
    ];
  }, [impoundCurrentBalance]);

  useEffect(() => {
    const initialValues = {
      date: dayjs(),
      disbursementMethod: DISBURSEMENTS_METHOD_TYPES[0].value,
    };

    form.setFieldsValue(initialValues);
  }, [form]);

  return (
    <Row className="d-flex flex-column">
      <Form
        form={form}
        className="w-100 mt-6 distribution-form"
        layout="vertical"
        requiredMark={false}
        disabled={isFormDisabled}
      >
        <Form.Item
          label="Payment Batch"
          name="batchId"
          className="mb-4"
          rules={[{ required: true, validateTrigger: "onSubmit" }]}
        >
          <Select
            options={options}
            disabled={isBatchesLoading || isFormDisabled}
          />
        </Form.Item>
        <Form.Item
          label="Date"
          name="date"
          className="mb-4"
          rules={[
            { type: "date", required: true, validateTrigger: "onSubmit" },
          ]}
        >
          <DatePicker format={DATE_FORMAT} />
        </Form.Item>
        <Row className="w-100 d-flex align-flex-start justify-space-between">
          <CustomerInputSearch
            form={form}
            label="Payee"
            name={[`payeeId`]}
            isFormDisabled={isFormDisabled}
            onCustomerFound={onCustomerFound}
            hasId={hasPayeeId}
            placeholder="Input Payee#, “Enter” to confirm"
            additionalClass="flex-grow-1"
            customRef={ref}
          />
          <Dropdown
            className="ml-2 mt-6"
            menu={{ items }}
            trigger={["click"]}
            placement="bottomRight"
            overlayClassName="payee-dropdown"
          >
            <Button shape="circle" icon={<MoreOutlined />}></Button>
          </Dropdown>
        </Row>
        <FloatNumberInput
          name="amount"
          label="Amount"
          form={form}
          className="w-100 mb-4"
          disabled={isFormDisabled}
          rules={amountRules}
          includeThousandsSeparator
        />
        <Form.Item
          label="Method"
          className="w-100 mb-4"
          name="disbursementMethod"
        >
          <Select options={DISBURSEMENTS_METHOD_TYPES} />
        </Form.Item>
        <Form.Item
          label="Description"
          className="w-100 mb-4"
          name="description"
          rules={[
            {
              max: 200,
              message: DISTRIBUTION_DESC_MAX,
              validateTrigger: "onSubmit",
            },
          ]}
        >
          <TextArea rows={2} style={{ resize: "none" }} />
        </Form.Item>
      </Form>
      <Row className="distribution-form-footer-wrapper">
        <FormButtons
          id={null}
          onCancelBtnClick={onCancelBtnClick}
          onSaveBtnClick={onSaveBtnClick}
          customersIds={customerIds}
          isDisabled={isFormDisabled}
          createButtonTitle="Create Payment"
        />
      </Row>
    </Row>
  );
};
