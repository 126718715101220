import { useCallback, useState } from "react";
import { RegisterTemplateMessage } from "./components/RegisterTemplateMessage";
import { SecurityCheck } from "./components/SecurityCheck";
import { SignUpSteps } from "./components/SignUpSteps";
import { IssueWithAccount } from "../../components/IssueWithAccount";
import "./styles.scss";

export const SignUp = () => {
  const [email, setEmail] = useState<string>("");

  const [verificationCodeId, setVerificationCodeId] = useState<string | null>(
    null
  );

  const [status, setStatus] = useState<0 | 1 | 2 | 3 | null>(null);

  const [userInitialData, setUserInitialData] = useState<any>({});

  const [isChecking, setIsChecking] = useState<boolean>(false);

  const onNextClicked = useCallback((value: any) => {
    setEmail(value.email);
    setIsChecking(true);
  }, []);

  const onConfirmSecurityCode = useCallback((value: any) => {
    setStatus(value.status);
    setUserInitialData(value.registerUserRequest || {});
    setIsChecking(false);
  }, []);

  return (
    <>
      {isChecking ? (
        <SecurityCheck
          email={email}
          setIsChecking={setIsChecking}
          verificationCodeId={verificationCodeId}
          setVerificationCodeId={setVerificationCodeId}
          onConfirmSecurityCode={onConfirmSecurityCode}
        />
      ) : (
        <>
          {status === 2 || status === null ? (
            <SignUpSteps
              email={email}
              verificationCodeId={verificationCodeId}
              onNextClicked={onNextClicked}
              status={status}
              userInitialData={userInitialData}
            />
          ) : status === 1 ? (
            <IssueWithAccount
              header="There are several users with the same email/username"
              username={email}
              postInfo="&ensp;and provide the email/username you’ve tried to use."
            />
          ) : (
            <RegisterTemplateMessage
              status={status}
              email={email}
              setStatus={setStatus}
            />
          )}
        </>
      )}
    </>
  );
};
