import { FormInstance, RadioChangeEvent } from "antd";
import dayjs from "../../../../../../../configs/dayjs";
import { useMemo } from "react";

export const useOnRadioChange = (form: FormInstance) => {
  const DATE_DEFAULT_VALUE = useMemo(
    () => [
      { value: 1, defaultValue: [dayjs().startOf("D"), dayjs().startOf("D")] },
      {
        value: 2,
        defaultValue: [
          dayjs().subtract(1, "day").startOf("D"),
          dayjs().startOf("D"),
        ],
      },
      {
        value: 3,
        defaultValue: [
          dayjs().subtract(3, "day").startOf("D"),
          dayjs().startOf("D"),
        ],
      },
      { value: 4, defaultValue: [dayjs().startOf("D"), dayjs().startOf("D")] },
    ],
    []
  );

  const onRadioChange = (e: RadioChangeEvent) => {
    const value = DATE_DEFAULT_VALUE.find(
      (item) => item.value === e.target.value
    )?.defaultValue;

    form.setFieldValue("date", value);
  };

  return { DATE_DEFAULT_VALUE, onRadioChange };
};
