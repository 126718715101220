import { Row } from "antd";
import { ITicklerExtended } from "../../../../types/Tickler";
import { DATE_FORMAT } from "../../../../lib/constants";
import dayjs from "../../../../configs/dayjs";

type TicklerProps = {
  tickler: ITicklerExtended;
};

export const Tickler = ({ tickler }: TicklerProps) => {
  return (
    <Row className="d-block mb-4">
      <Row className="align-center mb-1">
        <span
          className={`mark ${tickler.paymentAlert ? "alert" : "default"}`}
        ></span>
        <p className="my-0 pl-2 text-sm w-500">{tickler.ticklerTypeName}</p>
      </Row>
      <Row className="pl-4 mb-1">
        <p className="my-0 text-md">{tickler.text}</p>
      </Row>
      <Row className="align-center pl-4 last-updated-data">
        <p className="my-0 text-sm title pr-2">
          {dayjs(tickler.lastUpdated).format(DATE_FORMAT)}
        </p>
        <div className="separator" />
        <p
          className="my-0 text-sm title pl-2 elipsis"
          title={tickler.lastUpdatedBy}
        >
          {tickler.lastUpdatedBy}
        </p>
      </Row>
    </Row>
  );
};
