import { useCallback, useState } from "react";
import { useDefaultDeleteModal } from "../../../../../../../../../lib/hooks/defaultDeleteModal";
import {
  createPayer,
  deletePayer,
  editPayer,
} from "../../../../../../../../../acions/accounts/payers";
import { FormInstance } from "antd";
import { IPayersPost } from "../../../../../../../../../types/Payer";
import { useAppDispatch } from "../../../../../../../../../store";

type TButtonHandlers = {
  reloadData: () => void;
  setId: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  parentId?: string;
  form?: FormInstance;
};

export const useButtonHandlers = ({
  reloadData,
  setId,
  form,
  parentId,
}: TButtonHandlers) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const setIsOpen = useCallback(
    (val: any) => {
      !val && setId(undefined);
    },
    [setId]
  );

  const { openDeleteModal } = useDefaultDeleteModal(
    deletePayer,
    reloadData,
    setIsOpen
  );

  const dispatch = useAppDispatch();

  const onDeleteBtnClick = useCallback(
    (id: string) => {
      openDeleteModal(id, "Delete Payer");
    },
    [openDeleteModal]
  );

  const onCancelBtnClick = useCallback(() => {
    setId(undefined);
  }, [setId]);

  const onSuccess = useCallback(
    (isNew = false) => {
      setIsDisabled(false);
      setId(isNew ? null : undefined);

      isNew &&
        form?.setFieldsValue({
          payeeId: undefined,
          status: 0,
        });

      reloadData();
    },
    [form, reloadData, setId]
  );

  const create = useCallback(
    async (req: IPayersPost, isNew: boolean) => {
      try {
        await Promise.resolve(dispatch(createPayer(req)));

        form?.resetFields();
        onSuccess(isNew);
      } catch (error) {
        setIsDisabled(false);
      }
    },
    [dispatch, form, onSuccess]
  );

  const edit = useCallback(
    async (id: string, req: IPayersPost) => {
      try {
        await Promise.resolve(dispatch(editPayer(id, req)));

        onSuccess();
      } catch (error) {
        setIsDisabled(false);
      }
    },
    [dispatch, onSuccess]
  );

  const onSaveBtnClick = useCallback(
    async (isNew: boolean, id: string | null, customersIds: any) => {
      try {
        const formData = await form?.validateFields();

        const { assumedDate, soldDate } = formData;

        const req = {
          ...formData,
          payerId: customersIds.payeeId,
          contractId: parentId,
          assumedDate: assumedDate
            ? assumedDate.startOf("D").format()
            : undefined,
          soldDate: soldDate ? soldDate.startOf("D").format() : undefined,
        };

        setIsDisabled(true);

        id === null ? create(req, isNew) : edit(id, req);
      } catch (error) {
        setIsDisabled(false);
      }
    },
    [create, edit, form, parentId]
  );

  return { onDeleteBtnClick, onCancelBtnClick, onSaveBtnClick, isDisabled };
};
