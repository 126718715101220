import { Card, Col, Form, FormInstance, Row } from "antd";
import { General } from "./General";
import { FeesAndTaxes } from "./FeesAndTaxes";
import { Disbursements } from "./Disbursements";
import { BreakDown } from "./Breakdown";
import { useResetInstance } from "./hooks/useResetInstance";
import { useCallback } from "react";

type PaymentFormProps = {
  isLoading: boolean;
  isFormBlured: boolean;
  instance: any;
  form: FormInstance;
  isPaymentPosted: boolean;
  calculate: () => Promise<0 | 1>;
  manualUpdated?: {
    principalAmount: boolean;
    interestAmount: boolean;
    disbursements: boolean;
  };
  isHistoryOpened?: boolean;
  isHistoryEdited?: boolean;
  setManualUpdated?: React.Dispatch<
    React.SetStateAction<{
      principalAmount: boolean;
      interestAmount: boolean;
      disbursements: boolean;
    }>
  >;
};

export const PaymentForm = ({
  isLoading,
  isFormBlured,
  instance,
  form,
  isPaymentPosted,
  calculate,
  isHistoryOpened = false,
  isHistoryEdited = true,
  setManualUpdated,
  manualUpdated,
}: PaymentFormProps) => {
  const { resetByDisbursements } = useResetInstance(calculate);

  const onFormChange = useCallback(
    (e: any) => {
      if (setManualUpdated && manualUpdated) {
        const updated = { ...manualUpdated };

        const { target } = e;

        if (target) {
          const { id, value } = target;

          const val: number = +value || 0;

          if (id === "interestAmount" && instance.interestAmount !== val) {
            updated.interestAmount = true;
          }

          if (
            id === "breakdown_principalAmount" &&
            instance.breakdown.principalAmount !== val
          ) {
            updated.principalAmount = true;
          }

          setManualUpdated(updated);
        }
      }
    },
    [instance, manualUpdated, setManualUpdated]
  );

  return (
    <Row className="d-block form-container pt-4">
      <Form
        layout="vertical"
        form={form}
        disabled={isLoading || isPaymentPosted}
        onChange={onFormChange}
        requiredMark={false}
      >
        <Row className="w-100">
          <Col span={16} className="pr-4">
            <Card className="position-relative">
              <General
                form={form}
                instance={instance}
                calculate={calculate}
                isFormBlured={isFormBlured}
              />
              {isFormBlured && <div className="blur" />}
            </Card>
          </Col>
          <Col span={8}>
            <Card className="position-relative">
              <FeesAndTaxes
                form={form}
                instance={instance}
                calculate={calculate}
                isHistoryOpened={isHistoryOpened}
              />
              {isFormBlured && <div className="blur" />}
            </Card>
          </Col>
        </Row>
        <Row className="w-100 mt-4">
          <Col span={16} className="pr-4">
            <Card className="position-relative second-row-card h-100">
              <BreakDown
                form={form}
                instance={instance}
                calculate={calculate}
                isFormDisabled={isLoading}
                isPaymentPosted={isPaymentPosted}
                isHistoryOpened={isHistoryOpened}
              />
              {isFormBlured && <div className="blur" />}
            </Card>
          </Col>
          <Col span={8}>
            <Card className="position-relative second-row-card">
              <Disbursements
                instance={instance}
                form={form}
                reset={resetByDisbursements}
                isFormDisabled={isLoading}
                isPaymentPosted={isPaymentPosted}
                isHistoryEdited={isHistoryEdited}
                isHistoryOpened={isHistoryOpened}
              />
              {isFormBlured && <div className="blur" />}
            </Card>
          </Col>
        </Row>
      </Form>
    </Row>
  );
};
