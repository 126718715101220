import { Divider, Form, FormInstance, Row } from "antd";
import { useInitialSettings } from "../hooks/useInitialSettings";
import { SettingsRow } from "./SettingsRow";
import { FormFooterButtons } from "./FormFooterButtons";
import { useSave } from "../hooks/useSave";
import "../styles.scss";

type MailingSettingsFormProps = {
  parentId: string;
  mailing: any;
  reloadData: () => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const MailingSettingsForm = ({
  parentId,
  mailing,
  reloadData,
  setIsOpen,
}: MailingSettingsFormProps) => {
  const [form]: FormInstance[] = Form.useForm();

  useInitialSettings(mailing, form);

  const { isDisabled, onSave } = useSave(form, parentId, reloadData, setIsOpen);

  return (
    <Row className="mail-settings-form h-100 d-flex position-relative flex-column">
      <Form
        form={form}
        className="pa-6"
        layout="vertical"
        requiredMark={false}
        disabled={isDisabled}
      >
        <Row className="my-2 text-md w-500">
          <span className="type-col px-2">Type/Reciever</span>
          <span className="email-col px-2 border-left">Email</span>
          <span className=" mail-col px-2 border-left">Mail</span>
        </Row>

        <Divider className="mt-0 mb-3" />

        <Row className="pa-2">
          <p className="my-0 text-md title">PAYMENT RECIEPT</p>
        </Row>

        <SettingsRow
          form={form}
          label="All Payers"
          emailName="paymentEmail"
          mailName="paymentMail"
        />

        <Divider className="mt-0 mb-3" />

        <Row className="pa-2">
          <p className="my-0 text-md title">DEPOSIT RECEIPT</p>
        </Row>

        <SettingsRow
          form={form}
          label="Primary Payee"
          emailName="depositPrimaryEmail"
          mailName="depositPrimaryMail"
        />

        <SettingsRow
          form={form}
          label="Secondary Payees"
          emailName="depositSecondaryEmail"
          mailName="depositSecondaryMail"
        />

        <Divider className="mt-0 mb-3" />

        <Row className="pa-2 justify-space-between align-center">
          <p className="my-0 text-md title">LATE NOTICE</p>
          <p className="my-0 text-sm title send-after-label">Generate After</p>
        </Row>

        <SettingsRow
          form={form}
          label="All Payers"
          emailName="lateNoticePayers"
          mailName="latePayerDays"
          sendAfter
        />

        <SettingsRow
          form={form}
          label="All Payees"
          emailName="lateNoticePayees"
          mailName="latePayeeDays"
          sendAfter
        />
      </Form>

      <FormFooterButtons
        isDisabled={isDisabled}
        setIsOpen={setIsOpen}
        onSave={onSave}
      />
    </Row>
  );
};
