import { Alert, Row } from "antd";
import { ReactNode, useEffect, useMemo } from "react";
import { useScrollToDiv } from "../../../../../../../../../lib/hooks/useScrollToDiv";

type ErrorContainerProps = {
  error: string;
};

export const ErrorContainer = ({ error }: ErrorContainerProps) => {
  const { ref, scrollToDiv } = useScrollToDiv();

  const message: ReactNode = useMemo(() => {
    const lines: ReactNode[] = error
      .split("\n")
      .map((line: string, i: number) => (
        <p className="my-0 text-md" key={i}>
          {line}
        </p>
      ));

    return <Row className="d-block">{lines}</Row>;
  }, [error]);

  useEffect(() => scrollToDiv(), [message, ref, scrollToDiv]);

  return (
    <Row ref={ref} className="d-block">
      <Alert message={message} showIcon type="error" className="mb-4" />
    </Row>
  );
};
