import { Button } from "antd";
import { useState } from "react";
import { useSendAll } from "../hooks/useSendAll";

type SendAllButtonProps = {
  isLoading: boolean;
  reloadData: () => void;
};

export const SendAllButton = ({
  isLoading,
  reloadData,
}: SendAllButtonProps) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const { sendAll } = useSendAll(setIsDisabled, reloadData);

  return (
    <Button
      shape="round"
      className="mr-2"
      disabled={isLoading || isDisabled}
      onClick={sendAll}
    >
      Send All
    </Button>
  );
};
