import { setErrorMessage } from "../../../acions/actionsCreators/messageActions";
import { useAppDispatch } from "../../../store";

export const useValidatePayment = () => {
  const dispatch = useAppDispatch();

  const totalsNotEqualError = (action: string) => {
    return `Unable to ${action} the payment. Total Payment and Total to Disburse amounts should be equal.`;
  };

  const negativeDisbursementError = (action: string) => {
    return `Unable to ${action} the payment. There are disbursements with negative amounts.`;
  };

  const negativeAfterError = (action: string) => {
    return `Unable to ${action} the payment. It results in a negative account balance.`;
  };

  const noImpoundError = (action: string) => {
    return `Unable to ${action} the payment. Disbursement for impound distribution was not added.`;
  };

  const validate = (instance: any, action: string) => {
    const { breakdown, disbursements } = instance;

    if (instance.totalPayment !== instance.totalToDisburse) {
      dispatch(setErrorMessage(totalsNotEqualError(action), true));
      return false;
    }

    if (disbursements.findIndex((item: any) => item.amount < 0) > -1) {
      dispatch(setErrorMessage(negativeDisbursementError(action), true));
      return false;
    }

    const afterProps: string[] = [
      "principalAfter",
      "interestAfter",
      "lateChargeAfter",
      "fromImpoundAfter",
    ];

    if (afterProps.findIndex((item: string) => breakdown[item] < 0) > -1) {
      dispatch(setErrorMessage(negativeAfterError(action), true));
      return false;
    }

    if (
      (breakdown.fromImpoundAmount || breakdown.fromImpoundToBalance) &&
      disbursements.findIndex(
        (item: any) => item.type === 2 || item.type === 101
      ) === -1
    ) {
      dispatch(setErrorMessage(noImpoundError(action), true));
      return false;
    }

    return true;
  };

  return { validate };
};
