import { ReactNode, useCallback, useMemo, useState } from "react";
import { FormContainer } from "./components/FormContainer";
import { Col, Form, FormInstance, Modal, Row } from "antd";
import { MODAL_MASK_COLOR } from "../../../../../../../../lib/constants";
import { FormButtons } from "./components/FormButtons";
import { ErrorContainer } from "./components/ErrorContainer";
import "./styles.scss";

type EmailsModalProps = {
  contractId: string;
  isOpen: boolean;
  primaryData: {
    buyerId: string;
    sellerId: string;
  };
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const EmailsModal = ({
  contractId,
  isOpen,
  primaryData,
  setIsOpen,
}: EmailsModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [form]: FormInstance[] = Form.useForm();

  const [error, setError] = useState<string | null>(null);

  const clearError = useCallback(() => setError(null), []);

  const close = useCallback(() => {
    setIsOpen(false);
    setIsLoading(false);
    form.resetFields();
    clearError();
  }, [setIsOpen, form, clearError]);

  const title: ReactNode = useMemo(
    () => (
      <Row justify="start" key={"modal-title"}>
        <Col>
          <div className="text w-500">Send Emails</div>
        </Col>
      </Row>
    ),
    []
  );

  return (
    <Modal
      title={title}
      open={isOpen}
      onCancel={close}
      footer={<FormButtons form={form} isLoading={isLoading} close={close} />}
      width={700}
      centered
      className="emails-modal"
      maskStyle={{ backgroundColor: MODAL_MASK_COLOR }}
      wrapClassName="z-1000 position-fixed"
    >
      {error && <ErrorContainer error={error} />}

      <FormContainer
        contractId={contractId}
        form={form}
        primaryData={primaryData}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        close={close}
        setError={setError}
      />
    </Modal>
  );
};
