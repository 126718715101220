import { Button, Dropdown, MenuProps } from "antd";
import { useMemo } from "react";
import { DownOutlined } from "@ant-design/icons";
import { useChecksReport } from "../hooks/useChecksReport";

type TPrintDropdownProps = {
  batchId: string;
  isDisabled: boolean;
  reloadData: () => void;
};

export const PrintDropdown = ({
  batchId,
  isDisabled,
  reloadData,
}: TPrintDropdownProps) => {
  const { isDownloading, printChecksReport } = useChecksReport(
    batchId,
    reloadData
  );

  const items: MenuProps["items"] = useMemo(
    () => [
      { key: 1, label: <div onClick={printChecksReport}>Checks</div> },
      { key: 2, label: <div>Direct Deposit Advices (Savings)</div> },
      { key: 3, label: <div>Direct Deposit Advices (Checkings)</div> },
      { key: 4, label: <div>Sent to Escrow Accounts Advices</div> },
    ],
    [printChecksReport]
  );

  return (
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      disabled={isDisabled || isDownloading}
    >
      <Button shape="round">
        Print <DownOutlined />
      </Button>
    </Dropdown>
  );
};
