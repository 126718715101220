import { useCallback, useEffect, useMemo, useState } from "react";
import FeesService from "../../../../services/administration/fees";
import { useFetchData } from "../hooks/useFetchData";
import { IFeeTypeExtended } from "../../../../types/Fees";
import { Button, Form, FormInstance, Row, Select } from "antd";
import { useTransformRequest } from "../hooks/useTransformRequest";

type FeeTypeSelectProps = {
  id: string | null;
  form: FormInstance;
};

export const FeeTypeSelect = ({ id, form }: FeeTypeSelectProps) => {
  const request: any = useMemo(
    () => ({
      pageSize: 99999,
      pageNumber: 1,
    }),
    []
  );
  const { data, isLoading } = useFetchData(undefined, request, FeesService);

  const [defaultFeeType, setDefaultFeeType] = useState<IFeeTypeExtended | null>(
    null
  );

  const { transform } = useTransformRequest("", false);

  const [showResetBtn, setShowResetBtn] = useState<boolean>(!!id);

  const feeTypes = useMemo(() => {
    return data.length > 0
      ? data.map((item: IFeeTypeExtended) => ({
          label: item.feeType,
          value: item.id,
        }))
      : [];
  }, [data]);

  useEffect(() => {
    const selectedItem = data.find(
      (item: any) => item.feeType === form.getFieldValue("feeType")
    );

    if (selectedItem) {
      setDefaultFeeType(selectedItem);
      form.setFieldValue("feeTypeId", selectedItem.id);
    }
  }, [data, form]);

  const onFeeTypeChange = useCallback(
    (selectedId: string): void => {
      const selectedItem: IFeeTypeExtended | undefined = data.find(
        (item: IFeeTypeExtended) => item.id === selectedId
      );

      transform(selectedItem);

      if (selectedItem) {
        setDefaultFeeType(selectedItem);
        form.setFieldsValue(selectedItem);
      }

      setShowResetBtn(true);
    },
    [data, form, transform]
  );

  const resetToDefault = useCallback((): void => {
    form.setFieldsValue(defaultFeeType);
  }, [defaultFeeType, form]);

  return (
    <>
      <Form.Item
        name="feeTypeId"
        label="Fee Type"
        className="mb-4"
        rules={[{ required: true }]}
      >
        <Select
          options={feeTypes}
          loading={isLoading}
          onChange={onFeeTypeChange}
        />
      </Form.Item>
      {showResetBtn && (
        <Row>
          <Button
            type="link"
            size="small"
            className="px-0 py-0"
            style={{ fontSize: 12 }}
            onClick={resetToDefault}
          >
            Reset to default
          </Button>
          <div className="divider mt-5 mb-3 w-100" />
        </Row>
      )}
    </>
  );
};
