import { useCallback, useState, MouseEvent } from "react";
import TemplatesService from "../../../../services/administration/templates";
import { saveAs } from "file-saver";

export const useDownload = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onDownload = useCallback((id: string, fileName: string): any => {
    setIsLoading(true);

    TemplatesService.download(id)
      .then((res) => {
        const blob = new Blob([res], {
          type: "application/octet-stream",
        });
        saveAs(blob, fileName);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, []);

  const onDownloadClick = useCallback(
    (e: MouseEvent<HTMLInputElement>, fileName: string): void => {
      e.stopPropagation();
      onDownload(e.currentTarget.id, fileName);
    },
    [onDownload]
  );

  return { isLoading, onDownloadClick, onDownload };
};
