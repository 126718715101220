import { FormInstance } from "antd";
import { useCallback } from "react";
import { useFormData } from "./useFormData";
import { useAppDispatch } from "../../../../store";
import {
  createTemplate,
  editTemplate,
} from "../../../../acions/administration/templates";

export const useSave = (
  parentId: string,
  form: FormInstance,
  close: () => void,
  reloadData: () => void,
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const dispatch = useAppDispatch();

  const { getFormData } = useFormData();

  const create = useCallback(
    async (isNew: boolean, req: any) => {
      try {
        await Promise.resolve(dispatch(createTemplate(req)));

        isNew ? form.resetFields() : close();
        reloadData();

        setIsDisabled(false);
      } catch {
        setIsDisabled(false);
      }
    },
    [dispatch, form, close, reloadData, setIsDisabled]
  );

  const edit = useCallback(
    async (id: string, req: any) => {
      try {
        await Promise.resolve(dispatch(editTemplate(id, req)));

        close();
        reloadData();

        setIsDisabled(false);
      } catch {
        setIsDisabled(false);
      }
    },
    [close, dispatch, reloadData, setIsDisabled]
  );

  const onSave = useCallback(
    async (isNew: boolean, id: string | null) => {
      try {
        const request = await form.validateFields();

        const formData = id
          ? getFormData(request)
          : getFormData(request, parentId);

        setIsDisabled(true);

        id ? edit(id, formData) : create(isNew, formData);
      } catch {
        setIsDisabled(false);
      }
    },
    [create, edit, form, getFormData, parentId, setIsDisabled]
  );

  return { onSave };
};
