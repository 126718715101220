import { useCallback, useState } from "react";
import { useDefaultDeleteModal } from "../../../lib/hooks/defaultDeleteModal";
import {
  deleteBatch,
  createBatch,
  editBatch,
} from "../../../acions/processing/batches";
import { FormInstance } from "antd";
import { IBatchPost } from "../../../types/Batches";
import { useAppDispatch } from "../../../store";
import { NavigateFunction, useNavigate } from "react-router-dom";
import ROUTES from "../../../configs/routesConfig";

type TButtonHandlersProps = {
  setIsOpenEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  form?: FormInstance;
  onEditSuccess: () => void;
};

export const useButtonHandlers = ({
  setIsOpenEditModal,
  form,
  onEditSuccess,
}: TButtonHandlersProps) => {
  const navigate: NavigateFunction = useNavigate();

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { openDeleteModal } = useDefaultDeleteModal(
    deleteBatch,
    onEditSuccess,
    setIsOpenEditModal
  );

  const onDeleteBtnClick = useCallback(
    (id: string) => {
      openDeleteModal(id, "Delete Batch");
    },
    [openDeleteModal]
  );

  const create = useCallback(
    async (entry: IBatchPost) => {
      setIsDisabled(true);
      try {
        await Promise.resolve(dispatch(createBatch(entry))).then((res: any) => {
          setIsDisabled(false);
          setIsOpenEditModal(false);
          navigate(`${ROUTES.PROCESSING}/${res.id}`);
        });
      } catch (error) {
        setIsDisabled(false);
      }
    },
    [dispatch, navigate, setIsOpenEditModal]
  );

  const edit = useCallback(
    async (entry: IBatchPost, id: string) => {
      try {
        setIsDisabled(true);
        await Promise.resolve(dispatch(editBatch(entry, id)));
        setIsDisabled(false);
        onEditSuccess && onEditSuccess();
        setIsOpenEditModal(false);
      } catch (error) {
        setIsDisabled(false);
      }
    },
    [dispatch, onEditSuccess, setIsOpenEditModal]
  );

  const onSaveBtnClick = useCallback(
    async (id: string | null) => {
      try {
        setIsDisabled(true);

        const formData = await form?.validateFields();

        const { date, paymentsDate } = formData;

        const req = {
          ...formData,
          date: date.format(),
          paymentsDate: paymentsDate.format(),
        };

        id !== null ? edit(req, id) : create(req);
      } catch (error) {
        setIsDisabled(false);
      }
    },
    [create, edit, form]
  );

  const onCancelBtnClick = useCallback(() => {
    setIsOpenEditModal(false);
  }, [setIsOpenEditModal]);

  return {
    onDeleteBtnClick,
    onSaveBtnClick,
    onCancelBtnClick,
    isDisabled,
  };
};
