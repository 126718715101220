import { ColumnsType } from "antd/es/table";
import { IImpDistributionGet } from "../../../../../../../../../../../types/Distribuions";
import { useCallback, useMemo, MouseEvent } from "react";
import dayjs from "../../../../../../../../../../../configs/dayjs";
import { DATE_FORMAT } from "../../../../../../../../../../../lib/constants";
import { EllipsisOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import { useButtonHandlers } from "./useButtonHandlers";
import { getFormattedCurrency } from "../../../../../../../../../../../lib/currencyFormatter";

type TTableColumns = {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reloadData: () => void;
  parentId: string | null;
};

export const useGetTableColumns = ({
  setIsModalOpen,
  reloadData,
  parentId,
}: TTableColumns) => {
  const { onDeleteBtnClick } = useButtonHandlers({
    reloadData,
    setIsOpen: setIsModalOpen,
    parentId,
  });

  const onDelete = useCallback(
    (e: MouseEvent<HTMLInputElement>): void => {
      onDeleteBtnClick(e.currentTarget.id);
    },
    [onDeleteBtnClick]
  );

  const columns: ColumnsType<IImpDistributionGet> = useMemo(
    () => [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        width: "14%",
        render: (value: string) => (
          <div>{dayjs(value).format(DATE_FORMAT)}</div>
        ),
      },
      {
        title: "Payee",
        dataIndex: "payeeNumber",
        key: "payeeNumber",
        width: "12%",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        width: "15%",
        align: "right",
        render: (value: number) => <div>{getFormattedCurrency(value)}</div>,
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        width: "52%",
        ellipsis: true,
        render: (value: string) => (
          <div className="elipsis" title={value}>
            {value}
          </div>
        ),
      },
      {
        key: "actions",
        width: "7%",
        render: (_: any, record: IImpDistributionGet) => {
          const { id } = record;

          const items: MenuProps["items"] = [
            {
              key: "1",
              label: (
                <div id={id} onClick={onDelete}>
                  Delete
                </div>
              ),
            },
          ];

          return (
            <div className="ant-table-actions">
              <Dropdown menu={{ items }} trigger={["click"]}>
                <EllipsisOutlined className="ant-table-btn" />
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [onDelete]
  );

  return { columns };
};
