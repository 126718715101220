import { Progress } from "antd";
import { useGetTaskStatus } from "./hooks/useGetTaskStatus";

type ProgressBarProps = {
  id: string;
  errorMessage: string;
  onSuccess: (id?: string) => void;
  customSuccessMessage?: string;
};

export const ProgressBar = ({
  id,
  errorMessage,
  onSuccess,
  customSuccessMessage,
}: ProgressBarProps) => {
  const { percent } = useGetTaskStatus(
    id,
    errorMessage,
    onSuccess,
    customSuccessMessage
  );

  return <Progress percent={percent} status="active" />;
};
