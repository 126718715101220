import { Form, FormInstance, Row, Select, Tag, Tooltip } from "antd";
import { ReactNode, useCallback, useEffect, useMemo } from "react";
import { useGetCustomerInfo } from "./hooks/useGetCustomerInfo";
import _ from "lodash";
import { useTagProps } from "./hooks/useTagProps";
import { SelectOption } from "./SelectOption";
import { useRules } from "./hooks/useRules";

type SendToFormItemProps = {
  form: FormInstance;
  initalData: any;
  optionsData: any;
  setOptionsData: React.Dispatch<React.SetStateAction<any>>;
  clearError: () => void;
};

export const SendToFormItem = ({
  form,
  initalData,
  optionsData,
  setOptionsData,
  clearError,
}: SendToFormItemProps) => {
  const { rules } = useRules(optionsData);

  useEffect(() => {
    const val = initalData.map((item: any, i: number) => ({ ...item, i }));

    setOptionsData(val);
  }, [initalData, setOptionsData]);

  const { isLoading, search, testEmail } = useGetCustomerInfo(setOptionsData);

  useEffect(() => {
    try {
      optionsData.length > 2 && form.validateFields();
    } catch {}
  }, [form, optionsData]);

  const options: ReactNode = useMemo(() => {
    const values = optionsData.map((item: any) => {
      const name: string = item.isEmail ? `<${item.name}>` : item.name;

      return (
        <Select.Option key={item.i} className="send-to-select-item">
          <SelectOption name={name} item={item} />
        </Select.Option>
      );
    });

    return values;
  }, [optionsData]);

  const { getTagLabel, getTooltip } = useTagProps();

  const tagRender = useCallback(
    (props: any) => {
      const { value, closable, onClose } = props;

      const item = optionsData.find((item: any) => item.i.toString() === value);

      const isError: boolean = (!item || !item.email) && !isLoading;

      const tooltipMsg = getTooltip(item, value);

      return (
        <Tag
          className={`mr-1 select-tag ${isError ? "error-label" : ""}`}
          closable={closable}
          onClose={onClose}
        >
          <Tooltip
            placement="top"
            title={tooltipMsg}
            overlayClassName="text-sm"
          >
            {getTagLabel(item, value)}
          </Tooltip>
        </Tag>
      );
    },
    [optionsData, isLoading, getTagLabel, getTooltip]
  );

  const onSelect = useCallback(
    (value: string, option: any) => {
      if (Object.keys(option).length === 0) {
        const val = +value;

        val && !_.isNaN(val) ? search(val) : testEmail(value);
      }
    },
    [search, testEmail]
  );

  const dropdownRender = useCallback((originNode: any) => {
    return (
      <Row className="d-block">
        <p className="my-1 mx-3 text-sm title">
          Select from the list or input Customer#/Email Address and click Enter
        </p>
        {originNode}
      </Row>
    );
  }, []);

  return (
    <Form.Item label="Send To" name="sendTo" rules={rules}>
      <Select
        mode="tags"
        optionFilterProp="children"
        className="send-to-select"
        tagRender={tagRender}
        onSelect={onSelect}
        loading={isLoading}
        dropdownRender={dropdownRender}
        onChange={clearError}
      >
        {options}
      </Select>
    </Form.Item>
  );
};
