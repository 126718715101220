import { Form, FormInstance, Input } from "antd";
import { useCallback, useMemo } from "react";
import { useCheckAccount } from "../hooks/useCheckAccount";
import _ from "lodash";
import { Rule } from "antd/es/form";

type AccountInputProps = {
  form: FormInstance;
  isShort: boolean;
  isFormDisabled: boolean;
};

export const AccountInput = ({
  form,
  isShort,
  isFormDisabled,
}: AccountInputProps) => {
  const setFieldError = useCallback(
    (msg: string[]): void => {
      form.setFields([
        {
          name: "accountNumber",
          errors: msg,
        },
      ]);
    },
    [form]
  );

  const { isDisabled, search } = useCheckAccount(form, setFieldError);

  const onEnterClicked = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const value = +event.currentTarget.value;

      value && !_.isNaN(value)
        ? search(value)
        : setFieldError(["Please enter correct number"]);
    },
    [search, setFieldError]
  );

  const onInputChange = useCallback(() => {
    setFieldError([]);
    form.setFieldValue("toContractId", null);
  }, [setFieldError, form]);

  const rules = useMemo(() => {
    return [
      () => ({
        validateTrigger: "onSubmit",
        validator(_: Rule, value: string) {
          const toContractId = form.getFieldValue("toContractId");

          if (!value || toContractId) {
            return Promise.resolve("");
          }
          if (value && !toContractId) {
            return Promise.reject(
              new Error("Please press Enter to find the account")
            );
          }
          return Promise.reject(new Error("Account was not found"));
        },
      }),
    ];
  }, [form]);

  return (
    <>
      <Form.Item
        label={isShort ? "Account Number" : "GL/Account Number"}
        name="accountNumber"
        rules={rules}
      >
        <Input
          disabled={isDisabled || isFormDisabled}
          onPressEnter={onEnterClicked}
          onChange={onInputChange}
        />
      </Form.Item>
      <Form.Item name="toContractId" className="d-none">
        <Input />
      </Form.Item>
    </>
  );
};
