import { useCallback } from "react";

export const useFormData = (sendTo: any[]) => {
  const getFormData = useCallback(
    (data: any) => {
      const formData = new FormData();

      for (const [key, value] of Object.entries(data)) {
        if (value === undefined || value === null) {
          continue;
        }

        if (key === "sendTo") {
          const sendToItems: any[] = [];

          data.sendTo.forEach((item: string) => {
            const val = sendTo.find((data: any) => data.i.toString() === item);

            if (val) {
              const valToPush: any = { email: val.email };

              if (val.id) {
                valToPush.id = val.id;
              }

              sendToItems.push(valToPush);
            }
          });

          formData.append(key, JSON.stringify(sendToItems));

          continue;
        }

        if (key === "attachments") {
          const templateIds: string[] = [];
          const files: any[] = [];

          data.attachments.forEach((item: any) => {
            if (item.templateId) {
              templateIds.push(item.templateId);
            } else if (item.originFileObj) {
              files.push(item.originFileObj);
            }
          });

          formData.append("templateIds", JSON.stringify(templateIds));

          formData.append("files", JSON.stringify(files));

          continue;
        }

        formData.append(key, data[key]);
      }

      return formData;
    },
    [sendTo]
  );

  return { getFormData };
};
