import { Form, FormInstance, Row, Select } from "antd";
import { PAYMENT_LATE_CHARGES_ASSESS_METHOD } from "../../../../../../lib/constants";
import { InputRow } from "./InputRow";
import { useCallback } from "react";

type ConfigFormProps = {
  form: FormInstance;
  isFormDisabled: boolean;
  isPaymentPosted: boolean;
  isHistoryOpened: boolean;
  calculate: (toUpdate?: {
    name: string;
    value: any;
    isUpdatedField?: boolean;
  }) => void;
};

export const ConfigForm = ({
  form,
  isFormDisabled,
  isPaymentPosted,
  isHistoryOpened,
  calculate,
}: ConfigFormProps) => {
  const onAssessMethodChange = useCallback((): void => {
    calculate({
      name: "breakdown.lateCharge.assessMethod",
      value: true,
      isUpdatedField: true,
    });
  }, [calculate]);

  return (
    <Form form={form} disabled={isFormDisabled || isPaymentPosted}>
      <Row className="d-block">
        <p className="mt-0 mb-4 text-md w-500">
          Configuration for This Payment
        </p>
        <Row className="align-center mb-2 justify-space-between">
          <p className="my-0 text-md title">Assess Method</p>
          <Form.Item
            className="mb-0 late-charge-input"
            label=""
            name={["breakdown", "lateCharge", "assessMethod"]}
          >
            <Select
              options={PAYMENT_LATE_CHARGES_ASSESS_METHOD}
              onSelect={onAssessMethodChange}
            />
          </Form.Item>
        </Row>
        <InputRow
          form={form}
          label="Assessed"
          name={["breakdown", "lateCharge", "assessed"]}
          calculate={calculate}
        />
        <InputRow
          form={form}
          label="Paid"
          name={
            !isHistoryOpened
              ? ["breakdown", "lateChargeAmount"]
              : ["breakdown", "lateCharge", "paid"]
          }
          calculate={calculate}
          isUpdated
        />
        <InputRow
          form={form}
          label="Added to Unpaid"
          name={
            !isHistoryOpened
              ? ["breakdown", "lateChargeToBalance"]
              : ["breakdown", "lateCharge", "addedToUnpaid"]
          }
          calculate={calculate}
          isUpdated
        />
        <InputRow
          form={form}
          label="Added to Principal"
          name={
            !isHistoryOpened
              ? ["breakdown", "principalToBalance"]
              : ["breakdown", "lateCharge", "addedToPrincipal"]
          }
          calculate={calculate}
          isUpdated
        />
      </Row>
    </Form>
  );
};
