import { useCallback, useEffect, useState } from "react";
import BatchesService from "../../../services/processing/batches";
import { usePrevious } from "../../../lib/hooks/previous";
import dayjs from "../../../configs/dayjs";
import { DATE_FORMAT } from "../../../lib/constants";
import { useSearchParams } from "react-router-dom";

export const useFetchCount = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [total, setTotal] = useState<number>(0);

  const [request, setRequest] = useState<any>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const prevRequest = usePrevious(request);

  const onPageChange = useCallback(
    (pageNumber: number, pageSize = 20) => {
      const newReq: any = { ...request, pageNumber, pageSize };

      setSearchParams(newReq);
    },
    [request, setSearchParams]
  );

  useEffect(() => {
    const val: any = {
      pageSize: +(searchParams.get("pageSize") || 20),
      pageNumber: +(searchParams.get("pageNumber") || 1),
      from: searchParams.get("from") || dayjs().format(DATE_FORMAT),
      to: searchParams.get("to") || dayjs().format(DATE_FORMAT),
    };
    setRequest(val);
  }, [searchParams]);

  const fetchCount = useCallback(() => {
    setIsLoading(true);
    BatchesService.fetchCount(request)
      .then((res: number) => {
        if (
          res !== 0 &&
          request &&
          request.pageNumber > Math.ceil(res / request.pageSize)
        ) {
          onPageChange(request.pageNumber - 1);
        }

        setTotal(res);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [onPageChange, request]);

  useEffect(() => {
    if (
      !prevRequest ||
      prevRequest.from !== request.from ||
      prevRequest.to !== request.to
    ) {
      request && fetchCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCount]);

  return { total, request, isLoading, onPageChange, fetchCount };
};
