import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../store";
import {
  setErrorMessage,
  setSuccessMessage,
} from "../../../acions/actionsCreators/messageActions";
import { TASK_WAS_COMPLETED } from "../../../lib/constants";
import { Modal } from "antd";
import BackGroundService from "../../../services/background";

export const useGetTaskStatus = (
  id: string,
  errorMessage: string,
  onSuccess: (id?: string) => void,
  customSuccessMessage?: string
) => {
  const dispatch = useAppDispatch();

  const [percent, setPercent] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      BackGroundService.getProgress(id)
        .then((res: any) => {
          const { progressPercent, status, result } = res;

          setPercent(progressPercent);

          if ([6, 4].includes(status)) {
            if (status === 4) {
              if (result.failedCount > 0) {
                dispatch(setErrorMessage(errorMessage, true));
              } else {
                setPercent(100);
                dispatch(
                  setSuccessMessage(
                    customSuccessMessage || TASK_WAS_COMPLETED,
                    !!customSuccessMessage
                  )
                );
              }
            }
            clearInterval(interval);
            Modal.destroyAll();
            onSuccess(id);
          }
        })
        .catch((e) => {
          dispatch(setErrorMessage(e.response.data.title, true));
          Modal.destroyAll();
          clearInterval(interval);
        });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [customSuccessMessage, dispatch, errorMessage, id, onSuccess]);

  return { percent };
};
