import { Row } from "antd";
import { ReactNode, useCallback } from "react";

export const useTagProps = () => {
  const getTagLabel = useCallback((item: any, value: string): string => {
    let name: string;

    if (item) {
      name = item.name;
      if (item.role) {
        name += ` | ${item.role === 1 ? "Buyer" : "Seller"}`;
      }
    } else {
      name = value;
    }

    return name;
  }, []);

  const getTooltip = useCallback(
    (item: any, value: string): string | ReactNode => {
      if (item) {
        if (item.isEmail) {
          return item.name;
        } else {
          return (
            <Row className="d-block">
              <p className="my-0 text-sm">{item.name}</p>
              <p className="my-0 text-sm">{item.email}</p>
            </Row>
          );
        }
      } else {
        return value;
      }
    },
    []
  );

  return { getTagLabel, getTooltip };
};
