import { Checkbox, Form, FormInstance, Row } from "antd";
import { FloatNumberInput } from "../../../../../../../components/FloatNumberInput";
import { useEffect, useMemo } from "react";
import { Rule } from "antd/es/form";

type SettingsRowProps = {
  form: FormInstance;
  label: string;
  emailName: string;
  mailName: string;
  sendAfter?: boolean;
};

export const SettingsRow = ({
  form,
  label,
  emailName,
  mailName,
  sendAfter = false,
}: SettingsRowProps) => {
  const emailValue = Form.useWatch(emailName, form);

  useEffect(() => {
    const val = form.getFieldValue(mailName);

    if (sendAfter && emailValue && !val) {
      form.setFieldValue(mailName, 1);
    }
  }, [emailValue, form, mailName, sendAfter]);

  const generateAfterRule: Rule[] = useMemo(() => {
    return [
      () => ({
        validateTrigger: "onSubmit",
        validator(_: Rule, value: string) {
          if (!value || +value === 0) {
            return Promise.reject(new Error(""));
          }
          return Promise.resolve();
        },
      }),
    ];
  }, []);

  return (
    <Row className="my-2 align-center position-relative">
      <span className="type-col px-2">{label}</span>
      <Form.Item
        name={emailName}
        className="email-col d-flex justify-center mb-0 mx-2"
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>
      {!sendAfter ? (
        <Form.Item
          name={mailName}
          className="mail-col d-flex justify-center mb-0 mx-2"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      ) : (
        <>
          {emailValue && (
            <Row className="align-center">
              <FloatNumberInput
                className="days-input d-flex justify-center mb-0 ml-2"
                name={mailName}
                label=""
                prefix=""
                allowDecimal={false}
                form={form}
                rules={generateAfterRule}
              />
              <span className="text-sm title ml-1">days</span>
            </Row>
          )}
        </>
      )}
    </Row>
  );
};
