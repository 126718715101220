import { Button, Form, Row, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  DOCX_TYPE,
  PDF_TYPE,
} from "../../../../../../../../../../lib/constants";
import { useCallback } from "react";
import { useRules } from "./hooks/useRules";
import { useDownload } from "../../../../../../../../../Administration/TemplatesTab/hooks/useDownload";

type AttachmentsProps = {
  clearError: () => void;
};

export const Attachments = ({ clearError }: AttachmentsProps) => {
  const { onDownload } = useDownload();

  const beforeUpload = useCallback((file: File) => {
    const isAllowed = file.type === DOCX_TYPE || file.type === PDF_TYPE;

    return isAllowed ? false : Upload.LIST_IGNORE;
  }, []);

  const onDownlaodClick = useCallback(
    (file: any) => {
      onDownload(file.templateId, file.name);
    },
    [onDownload]
  );

  const { rules } = useRules();

  return (
    <Row className="position-relative">
      <Form.Item
        label="Attachments"
        name="attachments"
        className="mb-2 w-100"
        valuePropName="fileList"
        getValueFromEvent={(e) => e.fileList}
        rules={rules}
      >
        <Upload
          listType="picture"
          beforeUpload={beforeUpload}
          previewFile={async () => ""}
          showUploadList={{ showDownloadIcon: true }}
          onDownload={onDownlaodClick}
          onRemove={clearError}
        >
          <Button icon={<UploadOutlined />} shape="round">
            Upload DOCX or PDF
          </Button>
        </Upload>
      </Form.Item>
    </Row>
  );
};
