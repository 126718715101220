import { Row, Col, Card } from "antd";
import { PieChartCard } from "./components/PieChartCard";
import { LineChartCard } from "./components/LineChartCard";
import { PaymentNumberCard } from "./components/PaymentNumberCard";
import { DefaultWidgetCard } from "./components/DefaultWidgetCard";
import { Loader } from "./components/Loader";
import { useFetchData } from "./hooks/useFetchData";
import { useWidgetProps } from "./hooks/useWidgetProps";
import { useGetTotals } from "./hooks/useGetTotals";
import { useCreatedAtLabel } from "./hooks/useCreatedAtLabel";
import { InternalServerError } from "../InternalServerError";
import "./styles.scss";

export const Dashboard = () => {
  const { opened, closed, other, draft, isLoading, data, noKpi } =
    useFetchData();

  const { createdAtLabel } = useCreatedAtLabel(data);

  const { totalImpound, paymnentAmounts } = useGetTotals(data);

  const { draftProps, otherProps } = useWidgetProps(draft, other, isLoading);

  return (
    <Row className="table-page-content">
      <Row className="d-block w-100">
        <Row className="py-6 mx-8 align-center justify-space-between">
          <h3 className="h h3">Home</h3>
          {createdAtLabel}
        </Row>
        {!noKpi ? (
          <Row className="px-8 py-8 home-content align-center flex-column">
            <div className="d-flex">
              <Col span={8} className="pr-4">
                <Card className="dashboard-card top">
                  <p className="text mt-0 mb-2">Opened Accounts</p>
                  {!isLoading ? (
                    <div>
                      <PieChartCard
                        data={opened.values}
                        colors={opened.colors}
                      />
                    </div>
                  ) : (
                    <Loader />
                  )}
                </Card>
              </Col>
              <Col span={8} className="pr-4">
                <Card className="dashboard-card top">
                  <p className="text mt-0 mb-2">Closed Accounts</p>
                  {!isLoading ? (
                    <div>
                      <PieChartCard
                        data={closed.values}
                        colors={closed.colors}
                      />
                    </div>
                  ) : (
                    <Loader />
                  )}
                </Card>
              </Col>
              <Col span={8}>
                <Card className="dashboard-card top">
                  <p className="text mt-0 mb-2">Total Impound Balance</p>
                  <div className="h-100">
                    <LineChartCard
                      totalImpound={totalImpound}
                      isDataLoading={isLoading}
                    />
                  </div>
                </Card>
              </Col>
            </div>
            <div className="mt-4 d-flex">
              <DefaultWidgetCard {...draftProps} />
              <DefaultWidgetCard {...otherProps} />
              <Col span={8}>
                <Card className="dashboard-card payments">
                  {!isLoading ? (
                    <PaymentNumberCard
                      isLoading={isLoading}
                      paymnentAmounts={paymnentAmounts}
                    />
                  ) : (
                    <Loader />
                  )}
                </Card>
              </Col>
            </div>
          </Row>
        ) : (
          <InternalServerError dashboard />
        )}
      </Row>
    </Row>
  );
};
