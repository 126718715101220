import { Button, Card, Row, Table } from "antd";
import { useGetTableColumns } from "./hooks/useGetTableColumns";
import { Loader } from "../../../../../../../../components/Loader";
import { useFetchData } from "./hooks/useFetchData";
import { IPayeeDisbursements } from "../../../../../../../../types/PayeeDisbursements";
import { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useOpenSideBar } from "./hooks/useOpenSideBar";
import { TRecord } from "./types";
import { useFetchDataCount } from "../../../../../../../../lib/hooks/useDefaultFetchDataCount";
import { TablePagination } from "../../../../../../../../components/TablePagination";
import PayeeDisbursementsService from "../../../../../../../../services/accounts/payeeDisbutsements";
import { useDefualtOnRow } from "../../../../../../../../lib/hooks/useDefaultOnRow";

type TPayeeDisbursements = {
  accountId: string;
  primaryBuyerNumber: number;
  primarySellerNumber: number;
  shouldPayeeDisbUpdate: boolean;
  setShouldPayeeDisbUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: () => void;
};

export const PayeeDisbursements = ({
  accountId,
  primaryBuyerNumber,
  primarySellerNumber,
  shouldPayeeDisbUpdate,
  setShouldPayeeDisbUpdate,
  onSuccess,
}: TPayeeDisbursements) => {
  const [record, setRecord] = useState<TRecord>({ id: null, contractId: "" });

  const {
    total,
    isLoading: isCountLoading,
    request,
    fetchCount,
    onPageChange,
  } = useFetchDataCount<{ contractId: string }>(
    PayeeDisbursementsService,
    {
      contractId: accountId,
    },
    ["contractId"]
  );

  const { data, isLoading, fetchData } = useFetchData({
    request,
    setShouldPayeeDisbUpdate,
  });

  const reloadData = useCallback(
    (isNew = false) => {
      fetchData();
      fetchCount();
      !isNew && setRecord({ id: null, contractId: "" });
      onSuccess();
    },
    [fetchData, fetchCount, onSuccess]
  );

  const { isOpen, setIsOpen, formWrapper } = useOpenSideBar({
    reloadData,
    payeesNumber: {
      primaryBuyerNumber,
      primarySellerNumber,
    },
    customSidebarClass: "sidebar__container-payee-disbursements",
    contractId: accountId,
    setRecord,
    record,
  });

  const openDisb = useCallback(
    (id: string) => {
      setRecord({ id });
      setIsOpen(true);
    },
    [setIsOpen, setRecord]
  );

  const { onRow } = useDefualtOnRow(openDisb);

  const { columns } = useGetTableColumns({
    setIsOpen,
    reloadData,
    setRecord,
    openDisb,
  });

  const onAddClick = useCallback((): void => {
    setIsOpen(true);
    setRecord({
      contractId: accountId,
      id: null,
    });
  }, [accountId, setIsOpen]);

  const rowClassName = useCallback(
    (record: IPayeeDisbursements): string =>
      record.status === 3 || record.status === 2 ? "gray-row" : "",
    []
  );

  useEffect(() => {
    !isOpen && setRecord({ contractId: accountId, id: null });
  }, [accountId, isOpen]);

  useEffect(() => {
    shouldPayeeDisbUpdate && reloadData();
  }, [reloadData, shouldPayeeDisbUpdate]);

  return (
    <Row className="w-100 mb-4">
      <Card className="w-100">
        <Row className="w-100 d-flex justify-space-between">
          <h5 className="h h5 ant-table-title">Payee Disbursements</h5>
          <Button shape="round" onClick={onAddClick}>
            Add new
          </Button>
        </Row>
        {isLoading ? (
          <Loader fontSize="64px" />
        ) : (
          data.length !== 0 && (
            <Row className="w-100 pt-6 d-block">
              <Table
                dataSource={data}
                columns={columns}
                className="w-100 payee-disbursements-table account-details-table clickable-rows-table"
                rowKey="id"
                pagination={false}
                rowClassName={rowClassName}
                onRow={onRow}
              />
              {total > 20 && (
                <TablePagination
                  isTotalLoading={isCountLoading}
                  total={total}
                  currentPage={request.pageNumber}
                  pageSize={request.pageSize}
                  onPageChange={onPageChange}
                />
              )}
            </Row>
          )
        )}
      </Card>
      {isOpen && createPortal(formWrapper, document.body)}
    </Row>
  );
};
