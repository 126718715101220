import { Button, Form, Row, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useCallback, useMemo } from "react";
import { UploadFile as UploadFileType } from "antd/es/upload";
import { Rule } from "antd/es/form";
import {
  DOCX_TYPE,
  FILE_SIZE_EXCEED,
  MAX_FILE_SIZE,
  TEMPLATE_REQUIRED,
} from "../../../../lib/constants";
import { useDownload } from "../hooks/useDownload";
import { TemplateItem } from "../../../../types/Template";

type UploadFileProps = {
  data: TemplateItem | null;
};

export const UploadFile = ({ data }: UploadFileProps) => {
  const { onDownload } = useDownload();

  const onDownlaodClick = useCallback(() => {
    data && onDownload(data.id, data.fileName);
  }, [data, onDownload]);

  const beforeUpload = useCallback((file: File) => {
    const isAllowed = file.type === DOCX_TYPE;

    return isAllowed ? false : Upload.LIST_IGNORE;
  }, []);

  const fileRule: Rule[] = useMemo(() => {
    return [
      () => ({
        validateTrigger: "onSubmit",
        validator(_: Rule, value: UploadFileType[]) {
          if (!value || value.length === 0) {
            return Promise.reject(TEMPLATE_REQUIRED);
          } else {
            const { size } = value[0];

            if (!size || size > MAX_FILE_SIZE) {
              return Promise.reject(new Error(FILE_SIZE_EXCEED));
            }
          }

          return Promise.resolve();
        },
      }),
    ];
  }, []);

  return (
    <Row className="position-relative">
      <Form.Item
        label="Template"
        name="file"
        className="mb-2 w-100"
        valuePropName="fileList"
        getValueFromEvent={(e) => e.fileList}
        rules={fileRule}
      >
        <Upload
          listType="picture"
          beforeUpload={beforeUpload}
          maxCount={1}
          previewFile={async () => ""}
          showUploadList={{ showDownloadIcon: true }}
          onDownload={onDownlaodClick}
        >
          <Button icon={<UploadOutlined />} shape="round">
            Upload DOCX
          </Button>
        </Upload>
      </Form.Item>
    </Row>
  );
};
