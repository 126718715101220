import { useCallback, useEffect, useState } from "react";
import BatchesService from "../../../../../services/processing/batches";
import { IBatchGet } from "../../../../../types/Batches";
import { NavigateFunction, useNavigate } from "react-router-dom";

export const useFetchDetails = (
  id: string,
  setActiveKey: React.Dispatch<React.SetStateAction<string>>
) => {
  const navigate: NavigateFunction = useNavigate();

  const [data, setData] = useState<IBatchGet | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchData = useCallback(
    (isShowLoader: boolean = true): void => {
      isShowLoader && setIsLoading(true);

      BatchesService.fetch(id)
        .then((res: IBatchGet) => {
          setIsLoading(false);
          setData(res);
          res.type === 3 && setActiveKey("2");
        })
        .catch((e) => {
          e.response.data.status === 400 && navigate("/not-found");
          setIsLoading(false);
        });
    },
    [id, navigate, setActiveKey]
  );

  useEffect((): void => fetchData(), [fetchData]);

  return { data, isLoading, fetchData };
};
