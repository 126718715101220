import { useCallback, useMemo } from "react";
import { useFetchData } from "../../../../../../lib/hooks/useDefaultFetchData";
import { useFetchDataCount } from "../../../../../../lib/hooks/useDefaultFetchDataCount";
import { useTableColumns } from "./hooks/useTableColumns";
import { Button, Card, Radio, RadioChangeEvent, Row, Table } from "antd";
import "./styles.scss";
import { TablePagination } from "../../../../../../components/TablePagination";
import { useOnSorterChange } from "./hooks/useOnSorterChange";
import { useDefaultTableScrollProp } from "../../../../../Customers/hooks/defaultTableScrollProp";
import { TRequest } from "./types";
import { IBatchPayment } from "../../../../../../types/BatchPayment";
import BatchPaymentsService from "../../../../../../services/processing/payments";
import { IBatchGet } from "../../../../../../types/Batches";
import { usePostAll } from "./hooks/usePostAll";
import ROUTES from "../../../../../../configs/routesConfig";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useDefualtOnRow } from "../../../../../../lib/hooks/useDefaultOnRow";

type TPaymentsTableProps = {
  batchId: string;
  batchData: IBatchGet | null;
  reloadBatch: () => void;
};

export const PaymentsTable = ({
  batchId,
  batchData,
  reloadBatch,
}: TPaymentsTableProps) => {
  const navigate: NavigateFunction = useNavigate();

  const {
    total,
    isLoading: isCountLoading,
    request,
    onPageChange,
    fetchCount,
    setRequest,
  } = useFetchDataCount<{ batchId: string; status?: number; orderBy?: number }>(
    BatchPaymentsService,
    { batchId },
    ["status"]
  );

  const { data, isLoading, fetchData } = useFetchData<TRequest, IBatchPayment>(
    BatchPaymentsService,
    request
  );

  const { onSorterChange } = useOnSorterChange(request, setRequest);

  const { scroll } = useDefaultTableScrollProp(data);

  const reloadData = useCallback(() => {
    fetchCount();
    fetchData();
  }, [fetchCount, fetchData]);

  const { isLoading: isPostAllLoading, onPostAll } = usePostAll(
    batchId,
    reloadBatch
  );

  const getPaymentRoute = useCallback(
    (id: string): string => {
      return `${ROUTES.PROCESSING}/${batchId}/payments/${id}?batchNumber=${batchData?.batchNumber}&type=${batchData?.type}`;
    },
    [batchData?.batchNumber, batchData?.type, batchId]
  );

  const navigateToPeyment = useCallback(
    (id: string) => {
      navigate(getPaymentRoute(id));
    },
    [getPaymentRoute, navigate]
  );

  const { onRow } = useDefualtOnRow(navigateToPeyment);

  const { columns } = useTableColumns({
    reloadData,
    reloadBatch,
    getPaymentRoute,
    navigateToPeyment,
  });

  const radioOptions = useMemo(
    () => [
      { value: 0, label: "All" },
      { value: 1, label: "Posted" },
      { value: 2, label: "Draft" },
    ],
    []
  );

  const onRadioChange = useCallback(
    (e: RadioChangeEvent) => {
      setRequest({ ...request, status: e.target.value });
    },
    [request, setRequest]
  );

  return (
    <Row className="w-100 payments-table-wrapper py-6 px-8">
      <Card className="w-100">
        <Row className="w-100 d-flex justify-space-between pb-6">
          <Radio.Group
            optionType="button"
            options={radioOptions}
            onChange={onRadioChange}
            defaultValue={0}
          />
          <Button shape="round" disabled={isPostAllLoading} onClick={onPostAll}>
            Post All Drafts
          </Button>
        </Row>
        <Row className="w-100 h-100 overflow-hidden d-block table__container">
          <Table
            dataSource={data}
            columns={columns}
            loading={isLoading}
            rowKey="id"
            pagination={false}
            className="w-100 payments-table clickable-rows-table"
            onChange={onSorterChange}
            scroll={scroll}
            showSorterTooltip={false}
            onRow={onRow}
          />
          {total > 0 && (
            <TablePagination
              isTotalLoading={isCountLoading}
              total={total}
              currentPage={request.pageNumber}
              pageSize={request.pageSize}
              onPageChange={onPageChange}
            />
          )}
        </Row>
      </Card>
    </Row>
  );
};
