import { Checkbox, Form, FormInstance, Input, Row } from "antd";
import { Rule } from "antd/es/form";
import { useCallback, useMemo, useState } from "react";
import { UploadFile } from "./UploadFile";
import { useSave } from "../hooks/useSave";
import { FormButtons } from "../../../../components/DefaultFormButtons";
import { ExportOutlined } from "@ant-design/icons";
import { useDeleteFromForm } from "../hooks/useDeleteFromForm";
import { TemplateItem } from "../../../../types/Template";
import { useGetInitialValues } from "../hooks/useGetInitialValues";

type TemplateFormProps = {
  data: TemplateItem | null;
  setData: React.Dispatch<React.SetStateAction<TemplateItem | null>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  parentId: string;
  reloadData: () => void;
};

export const TemplateForm = ({
  data,
  reloadData,
  setIsOpen,
  setData,
  parentId,
}: TemplateFormProps) => {
  const [form]: FormInstance[] = Form.useForm();

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const close = useCallback(() => {
    setIsOpen(false);
    setData(null);
  }, [setData, setIsOpen]);

  const { onSave } = useSave(parentId, form, close, reloadData, setIsDisabled);

  const { isDefault, initialData, id } = useGetInitialValues(data);

  const nameRules: Rule[] = useMemo(
    () => [
      {
        required: true,
        validateTrigger: "onSubmit",
      },
    ],
    []
  );

  const { onDelete } = useDeleteFromForm(id, close, reloadData);

  return (
    <Row className="template-form h-100 d-flex position-relative flex-column">
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        className="pa-6"
        disabled={isDisabled}
        initialValues={initialData}
      >
        <Form.Item name="name" label="Name" rules={nameRules} className="mb-4">
          <Input />
        </Form.Item>

        <UploadFile data={data} />

        <Row className="align-center mb-6">
          <span className="text-sm title">
            Dynamic system fields should be named according to&nbsp;
            <span className="ant-btn-link">the schema</span>
            <ExportOutlined className="ml-1 ant-btn-link logo-cursor" />
          </span>
        </Row>

        <Form.Item name="isDefault" valuePropName="checked">
          <Checkbox disabled={isDefault || isDisabled}>Set as Default</Checkbox>
        </Form.Item>
      </Form>

      <FormButtons
        currentId={id}
        onSaveClick={onSave}
        onCancelClick={close}
        onDeleteClick={onDelete}
        isFormDisabled={isDisabled}
      />
    </Row>
  );
};
