import { Button, Row } from "antd";
import { useCallback } from "react";

type FormFooterButtonsProps = {
  isDisabled: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: () => void;
};

export const FormFooterButtons = ({
  isDisabled,
  setIsOpen,
  onSave,
}: FormFooterButtonsProps) => {
  const onCancel = useCallback((): void => setIsOpen(false), [setIsOpen]);

  return (
    <Row className="default-form-buttons w-100 pa-6 divider d-flex position-absolute">
      <Button
        shape="round"
        type="primary"
        onClick={onSave}
        disabled={isDisabled}
        loading={isDisabled}
      >
        Save
      </Button>
      <Button shape="round" onClick={onCancel} disabled={isDisabled}>
        Cancel
      </Button>
    </Row>
  );
};
