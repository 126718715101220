import { Col, DatePicker, Form, FormInstance, Select } from "antd";
import {
  BEGINNING_PRINCIPAL_MAX_MSG,
  DATE_FORMAT,
} from "../../../../../../../../lib/constants";
import { ACCOUNT_PAYMENT_TYPES } from "../../../../../../../../lib/constants";
import { FloatNumberInput } from "../../../../../../../../components/FloatNumberInput";
import { useCallback, useMemo } from "react";
import { Rule } from "antd/es/form";
import { useGetBeginningDateRules } from "../../../../../../../Accounts/components/Filters/hooks/useGetBeginningDateRules";
import { Dayjs } from "dayjs";
import { useCalculateInterestPerDiem } from "../../hooks/calculateInterestPerDiem";
import dayjs from "../../../../../../../../configs/dayjs";
import { transformToFloat } from "../../../../../../../../lib/transformToFloat";

type LeftFormColProps = {
  form: FormInstance;
};

export const LeftFormCol = ({ form }: LeftFormColProps) => {
  const MAX_BEGINNING_PRINCIPAL_RULE = 1000000000;

  const { calculate } = useCalculateInterestPerDiem(form);

  const { rules: beginningDateRules } = useGetBeginningDateRules();

  const beginningPrincipalRule: Rule[] = useMemo(() => {
    return [
      () => ({
        validateTrigger: "onSubmit",
        validator(_: Rule, value: string) {
          const val: number | null = transformToFloat(value);
          if (!val || +val <= MAX_BEGINNING_PRINCIPAL_RULE) {
            return Promise.resolve();
          }
          return Promise.reject(new Error(BEGINNING_PRINCIPAL_MAX_MSG));
        },
      }),
    ];
  }, []);

  const balanceDueDateRule: Rule[] = useMemo(() => {
    return [
      () => ({
        validateTrigger: "onSubmit",
        validator(_: Rule, value: Dayjs) {
          if (!value) {
            return Promise.resolve();
          }

          const current = value.startOf("D");
          const minDate = dayjs().startOf("D");

          if (minDate.diff(current) > 0) {
            return Promise.reject(
              new Error(
                `Date must be greater than ${minDate.format(DATE_FORMAT)}`
              )
            );
          }

          return Promise.resolve("");
        },
      }),
    ];
  }, []);

  const onBeggingPrincipalChange = useCallback(() => {
    const val = form.getFieldValue("beginningPrincipal");

    form.setFieldValue("balancePrincipal", val);
    calculate();
  }, [form, calculate]);

  return (
    <Col className="general-info-col mr-7">
      <Form.Item
        label="Beginning Date"
        name={"beginningDate"}
        className="mb-4 balance__form-item"
        rules={beginningDateRules}
      >
        <DatePicker format={DATE_FORMAT} />
      </Form.Item>

      <FloatNumberInput
        name={"beginningPrincipal"}
        label="Beginning Principal"
        className="mb-4"
        form={form}
        rules={beginningPrincipalRule}
        onChange={onBeggingPrincipalChange}
        includeThousandsSeparator
      />

      <Form.Item
        label="	Balance Due Date"
        name={"balanceDueDate"}
        className="mb-4 balance__form-item"
        rules={balanceDueDateRule}
      >
        <DatePicker format={DATE_FORMAT} />
      </Form.Item>

      <Form.Item label="Payment Type" name={"paymentType"}>
        <Select options={ACCOUNT_PAYMENT_TYPES} />
      </Form.Item>
    </Col>
  );
};
