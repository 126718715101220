import {
  Button,
  Card,
  Dropdown,
  MenuProps,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
} from "antd";
import { useCallback, useMemo, useState } from "react";
import { MoreOutlined } from "@ant-design/icons";
import { PaymentRecord } from "./components/PaymentRecord";
import "./styles.scss";
import { Payments } from "./components/Payments";
import { Totals } from "./components/Totals";
import { useFetchYears } from "./hooks/useFetchYears";
import { Loader } from "../../../../../../components/Loader";

type TPaymentHistoryProps = {
  accountId: string;
  accountNumber: number;
};

export const PaymentHistory = ({
  accountId,
  accountNumber,
}: TPaymentHistoryProps) => {
  const [isTotalsOpen, setIsTotalsOpen] = useState<boolean>(false);

  const [paymentId, setPaymentId] = useState<string | null | undefined>(
    undefined
  );

  const [shouldDataReload, setShouldDataReload] = useState<boolean>(false);

  const [openEditMode, setOpenEditMode] = useState<boolean>(false);

  const [selectValue, setSelectValue] = useState<number>();

  const [isActionsDisabled, setIsActionsDisabled] = useState<boolean>(false);

  const { years, isLoading } = useFetchYears(accountId, setSelectValue);

  const onAddBtnClick = useCallback(() => {
    setPaymentId(null);
    setOpenEditMode(true);
  }, []);

  const items: MenuProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: <div onClick={onAddBtnClick}>Create Payment History</div>,
      },
    ],
    [onAddBtnClick]
  );

  const radioOptions = useMemo(
    () => [
      { label: "Payments", value: 1 },
      { label: "Totals", value: 2 },
    ],
    []
  );

  const onRadioChange = useCallback((e: RadioChangeEvent) => {
    setIsTotalsOpen(e.target.value === 2);
  }, []);

  const back = useCallback(() => setPaymentId(undefined), []);

  const reloadPaymentsData = useCallback(() => {
    setPaymentId(undefined);
    setShouldDataReload(true);
  }, []);

  const onSelectChange = useCallback((value: number) => {
    setSelectValue(value);
  }, []);

  return (
    <Row className="w-100 py-6 px-8 payment-history-wrapper">
      <Card className="w-100">
        <Row className="d-flex justify-space-between">
          <Row>
            <Radio.Group
              optionType="button"
              options={radioOptions}
              onChange={onRadioChange}
              defaultValue={1}
              className="mr-4 payment-history-switcher"
              disabled={isActionsDisabled}
            />
            {!isTotalsOpen && (
              <Select
                options={years}
                className="payment-history-select"
                value={selectValue}
                disabled={isLoading || isActionsDisabled}
                onChange={onSelectChange}
              />
            )}
          </Row>
          {!isTotalsOpen && (
            <Dropdown
              menu={{ items }}
              disabled={isActionsDisabled}
              trigger={["click"]}
            >
              <Button icon={<MoreOutlined />} shape="circle" />
            </Dropdown>
          )}
        </Row>
        <Row className="w-100 pt-6 d-block table__container">
          {isTotalsOpen ? (
            <Totals />
          ) : selectValue !== undefined ? (
            <Payments
              accountId={accountId}
              setPaymentId={setPaymentId}
              shouldDataReload={shouldDataReload}
              year={selectValue}
              setShouldDataReload={setShouldDataReload}
              setOpenEditMode={setOpenEditMode}
              setIsActionsDisabled={setIsActionsDisabled}
              isActionsDisabled={isActionsDisabled}
            />
          ) : (
            <Loader fontSize="64px" />
          )}
        </Row>
      </Card>
      {paymentId !== undefined && (
        <PaymentRecord
          accountNumber={accountNumber}
          back={back}
          paymentId={paymentId}
          reloadData={reloadPaymentsData}
          contractId={accountId}
          openEditMode={openEditMode}
          setOpenEditMode={setOpenEditMode}
        />
      )}
    </Row>
  );
};
