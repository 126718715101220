import { Col, FormInstance, Row } from "antd";
import { FloatNumberInput } from "../../../../../components/FloatNumberInput";
import { ReactNode, useCallback } from "react";
import { getFormattedCurrency } from "../../../../../lib/currencyFormatter";
import { SettingOutlined } from "@ant-design/icons";
import { EditOutlined } from "@ant-design/icons";

type DataRowProps = {
  form: FormInstance;
  instance: any;
  label: string;
  name: string;
  toText: string;
  calculate: () => void;
  isHistoryOpened: boolean;
  isPaymentPosted: boolean;
  isFormDisabled: boolean;
  openLCPopup?: () => void;
  openInterestPopup?: () => void;
};

export const DataRow = ({
  form,
  instance,
  label,
  name,
  toText,
  openLCPopup,
  openInterestPopup,
  calculate,
  isHistoryOpened,
  isPaymentPosted,
  isFormDisabled,
}: DataRowProps) => {
  const beforeAfterCell = useCallback(
    (name: string): ReactNode => {
      if (!instance) {
        return <Row />;
      }

      const { breakdown } = instance;

      const before = breakdown[`${name}Before`];
      const after = breakdown[`${name}After`];

      const isBeforeError = name === "lateCharge" ? !!before : before < 0;
      const isAfterError = name === "lateCharge" ? !!after : after < 0;

      return (
        <Row className="justify-end">
          <Row className="flex-column align-end">
            <p className={`my-0 text-sm ${isBeforeError && "text-color-red"}`}>
              {getFormattedCurrency(before || 0)}
            </p>
            {name === "interest" && !isPaymentPosted && !isHistoryOpened ? (
              <Row className={`${name}-after`} onClick={openInterestPopup}>
                <EditOutlined
                  className="mr-2 edit-icon"
                  style={{ color: "#0000008C" }}
                />
                <p
                  className={`my-0 text-sm ${isAfterError && "text-color-red"}`}
                >
                  {getFormattedCurrency(after || 0)}
                </p>
              </Row>
            ) : (
              <p className={`my-0 text-sm ${isAfterError && "text-color-red"}`}>
                {getFormattedCurrency(after || 0)}
              </p>
            )}
          </Row>
        </Row>
      );
    },
    [instance, isHistoryOpened, isPaymentPosted, openInterestPopup]
  );

  return (
    <Row className="w-100 py-2 align-center row">
      <Col span={5} className="px-2 d-flex align-center">
        <p className="my-0 text-md">{label}</p>
        {name === "lateCharge" && (
          <SettingOutlined className="ml-1 logo-cursor" onClick={openLCPopup} />
        )}
      </Col>
      <Col span={6} className="px-2">
        <FloatNumberInput
          name={["breakdown", `${name}Amount`]}
          label=""
          form={form}
          className="totals-input mb-0"
          allowNegative
          onPressEnter={calculate}
        />
      </Col>
      <Col span={8} className="px-2">
        <Row className="align-center">
          <FloatNumberInput
            name={["breakdown", `${name}ToBalance`]}
            label=""
            form={form}
            className="totals-input to-balance-input mb-0"
            allowNegative
            onPressEnter={calculate}
            disabled={
              (name === "interest" && !isHistoryOpened) ||
              isPaymentPosted ||
              isFormDisabled
            }
          />
          <Row className="d-block pl-1">
            <p className="my-0 text-sm title">to</p>
            <p className="my-0 text-sm title">{toText}</p>
          </Row>
        </Row>
      </Col>
      <Col span={5} className="px-2">
        {beforeAfterCell(name)}
      </Col>
    </Row>
  );
};
