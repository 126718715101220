import { useCallback, useEffect, useState } from "react";
import EmailsOutboxService from "../../../services/emails/outbox";

export const useFetchCount = () => {
  const [total, setTotal] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [request, setRequest] = useState<any>({
    pageNumber: 1,
    pageSize: 20,
    type: 1,
  });

  const onPageChange = useCallback(
    (pageNumber: number, pageSize = 20) => {
      const newRequest = { ...request, pageNumber, pageSize };

      setRequest(newRequest);
    },
    [request]
  );

  const fetchCount = useCallback(() => {
    setIsLoading(true);

    EmailsOutboxService.fetchCount(request)
      .then((res: number) => {
        setTotal(res);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [request]);

  useEffect(() => {
    fetchCount();
  }, [fetchCount]);

  return { total, isLoading, request, setRequest, fetchCount, onPageChange };
};
