import { Button, Dropdown, FormInstance, InputRef, MenuProps } from "antd";
import { CustomerInputSearch } from "../../../../../../../../Accounts/components/Filters/CustomerInputSearch";
import { MoreOutlined } from "@ant-design/icons";
import { MouseEvent, useCallback, useMemo, useRef } from "react";

type TPayeeInputProps = {
  form: FormInstance;
  payeesNumber: {
    primaryBuyerNumber: number;
    primarySellerNumber: number;
  };
  onCustomerFound: (number: number | null, name: string[]) => void;
  hasId: any;
  label: string;
  name: string[];
  isFormDisabled: boolean;
  isPayment?: boolean;
  isFieldRequired?: boolean;
};

export const PayeeInput = ({
  form,
  payeesNumber,
  onCustomerFound,
  hasId,
  label,
  name,
  isFormDisabled,
  isPayment,
  isFieldRequired,
}: TPayeeInputProps) => {
  const customerInputRef = useRef<InputRef>(null);

  const onDropdownChange = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const id: string = event.currentTarget.id;

      const value: number =
        id === "1"
          ? payeesNumber.primaryBuyerNumber
          : payeesNumber.primarySellerNumber;

      form.setFieldValue(name, value);
      customerInputRef.current && customerInputRef.current.focus();
    },
    [form, name, payeesNumber]
  );

  const items: MenuProps["items"] = useMemo(() => {
    const value = [
      {
        key: "1",
        label: (
          <div id="1" onClick={onDropdownChange}>
            Set Primary Buyer
          </div>
        ),
      },
    ];

    !isPayment &&
      value.push({
        key: "2",
        label: (
          <div id="2" onClick={onDropdownChange}>
            Set Primary Seller
          </div>
        ),
      });

    return value;
  }, [onDropdownChange, isPayment]);

  return (
    <div className="d-flex">
      <CustomerInputSearch
        form={form}
        label={label}
        name={name}
        isFormDisabled={isFormDisabled}
        onCustomerFound={onCustomerFound}
        hasId={hasId}
        placeholder="Input Payee#, “Enter” to confirm"
        additionalClass="flex-grow-1"
        customRef={customerInputRef}
        isFieldRequired={isFieldRequired}
      />
      <Dropdown
        className="ml-2 mt-6"
        menu={{ items }}
        disabled={isFormDisabled}
        trigger={["click"]}
      >
        <Button shape="circle" icon={<MoreOutlined />}></Button>
      </Dropdown>
    </div>
  );
};
