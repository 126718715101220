import { useCallback } from "react";
import { useDefaultDeleteModal } from "../../../../lib/hooks/defaultDeleteModal";
import { deleteTemplate } from "../../../../acions/administration/templates";

export const useDeleteFromForm = (
  id: string | null,
  close: () => void,
  reloadData: () => void
) => {
  const closeAndReload = useCallback(() => {
    close();
    reloadData();
  }, [close, reloadData]);

  const { openDeleteModal } = useDefaultDeleteModal(
    deleteTemplate,
    closeAndReload
  );

  const onDelete = useCallback(() => {
    id && openDeleteModal(id, "Delete template");
  }, [id, openDeleteModal]);

  return { onDelete };
};
