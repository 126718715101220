import { Col, Row } from "antd";
import { IBatchGet } from "../../../../../../types/Batches";
import { getFormattedCurrency } from "../../../../../../lib/currencyFormatter";
import dayjs from "../../../../../../configs/dayjs";
import { DataValueItem } from "./DataValueItem";
import "./styles.scss";

type SubHeaderProps = {
  data: IBatchGet;
  isWarehouse: boolean;
  fetchData: () => void;
};

export const SubHeader = ({ data, isWarehouse, fetchData }: SubHeaderProps) => {
  return (
    <Row className="px-8 align-center title justify-space-between header-container">
      <Col className="d-flex align-center">
        <DataValueItem header="Batch Date" value={dayjs(data.date)} />
        {!isWarehouse && (
          <DataValueItem
            header="Payment Date"
            value={dayjs(data.paymentsDate)}
            editable
            updateDataPaymentDate={fetchData}
            data={data}
          />
        )}
      </Col>
      <Col className="d-flex align-center">
        <p className="my-0 text-md title">Total Amount: </p>
        <Row className="align-center value">
          <p className="my-0 px-1 text w-500">
            {getFormattedCurrency(data.totalAmount || 0)}
          </p>
        </Row>
      </Col>
    </Row>
  );
};
