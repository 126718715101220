import { Rule } from "antd/es/form";
import { useMemo } from "react";
import {
  ATTACHMENTS_SIZE_EXCEED,
  MAX_FILE_SIZE,
} from "../../../../../../../../../../../lib/constants";
import { UploadFile as UploadFileType } from "antd/es/upload";

export const useRules = () => {
  const rules: Rule[] = useMemo(() => {
    return [
      () => ({
        validateTrigger: "onSubmit",
        validator(_: Rule, value: UploadFileType[]) {
          if (value && value.length > 0) {
            const res: number = value
              .map((item) => item.size || 0)
              .reduce((prevSum, size) => {
                return prevSum + (size || 0);
              });

            if (res > MAX_FILE_SIZE) {
              return Promise.reject(new Error(ATTACHMENTS_SIZE_EXCEED));
            }
          }

          return Promise.resolve();
        },
      }),
    ];
  }, []);

  return { rules };
};
