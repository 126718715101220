import { Form, FormInstance, Typography } from "antd";
import { useCallback, useEffect, useMemo } from "react";
import { FloatNumberInput } from "../../../../components/FloatNumberInput";
import { Rule } from "antd/es/form";
import { getFormattedCurrency } from "../../../../lib/currencyFormatter";
import { transformToFloat } from "../../../../lib/transformToFloat";

type TAmountProps = {
  form: FormInstance;
  payer: string;
  isDisabled: boolean;
  total: number;
  setTotal: (value: React.SetStateAction<number>) => void;
};

const { Text } = Typography;

export const Amount = ({
  form,
  payer,
  isDisabled,
  total,
  setTotal,
}: TAmountProps) => {
  const transformInputLabel = useCallback((value: string): string => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }, []);

  const fee: string = Form.useWatch(`${payer}Fee`, {
    form,
    preserve: true,
  });

  const tax: string = Form.useWatch(`${payer}Tax`, {
    form,
    preserve: true,
  });

  const totalType: "secondary" | undefined = useMemo(
    () => (isDisabled ? "secondary" : undefined),
    [isDisabled]
  );

  const rules: Rule[] = useMemo(
    () => [
      {
        required: true,
      },
    ],
    []
  );

  useEffect((): void => {
    setTotal((transformToFloat(fee) || 0) + (transformToFloat(tax) || 0));
  }, [fee, setTotal, tax]);

  return (
    <div className="amount-container">
      <FloatNumberInput
        name={`${payer}Fee`}
        label={`${transformInputLabel(payer)} Fee`}
        form={form}
        className="w-100 mb-4"
        rules={rules}
        disabled={isDisabled}
        includeThousandsSeparator
      />
      <FloatNumberInput
        name={`${payer}Tax`}
        label={`${transformInputLabel(payer)} Tax`}
        form={form}
        className="w-100 mb-4"
        rules={rules}
        disabled={isDisabled}
        includeThousandsSeparator
      />
      <Text
        className={`d-block w-100 fee-form-total-${payer}`}
        type={totalType}
      >
        Total: {getFormattedCurrency(total)}
      </Text>
    </div>
  );
};
