import { CLEAR_MESSAGE, SET_MESSAGE } from "../types";

export const setSuccessMessage = (
  message: string,
  closable: boolean = false
) => {
  return {
    type: SET_MESSAGE,
    payload: { type: "success", message, closable },
  };
};

export const setInfoMessage = (message: string) => {
  return {
    type: SET_MESSAGE,
    payload: { type: "info", message },
  };
};

export const setErrorMessage = (
  message: any,
  shouldDisapear: boolean,
  closable: boolean = false
) => {
  return {
    type: SET_MESSAGE,
    payload: {
      type: "error",
      message,
      shouldDisapear,
      closable,
    },
  };
};

export const clearMessage = () => ({
  type: CLEAR_MESSAGE,
});
