import { FormInstance } from "antd";
import { useCallback, useState } from "react";
import { useAppDispatch } from "../../../../../../../../../store";
import { assign } from "../../../../../../../../../acions/processing/disbursements";

type TButtonHandlers = {
  batchId: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  form: FormInstance;
  selectedDisbursementIds: React.Key[];
  reloadData: () => void;
  setShouldDisbOpen: React.Dispatch<
    React.SetStateAction<
      | {
          checkModeOn: boolean;
        }
      | undefined
    >
  >;
  isChecksModeOn: boolean;
};

export const useButtonHandlers = ({
  batchId,
  setIsOpen,
  form,
  selectedDisbursementIds,
  reloadData,
  setShouldDisbOpen,
  isChecksModeOn,
}: TButtonHandlers) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const onAssignClick = useCallback(async () => {
    try {
      setIsDisabled(true);

      const formData = await form.validateFields();

      const req = {
        ...formData,
        batchId,
        selectedDisbursementIds,
      };

      await Promise.resolve(dispatch(assign(req)));

      setIsDisabled(false);
      setIsOpen(false);
      reloadData();
      setShouldDisbOpen({ checkModeOn: isChecksModeOn });
    } catch (error) {
      setIsDisabled(false);
    }
  }, [
    batchId,
    dispatch,
    form,
    selectedDisbursementIds,
    setIsOpen,
    reloadData,
    setShouldDisbOpen,
    isChecksModeOn,
  ]);

  const onCancelClick = useCallback(() => setIsOpen(false), [setIsOpen]);

  return { isDisabled, onAssignClick, onCancelClick };
};
