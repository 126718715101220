import { useCallback, useEffect, useMemo } from "react";
import { useFetchData } from "../../../../../../../lib/hooks/useDefaultFetchData";
import { useFetchDataCount } from "../../../../../../../lib/hooks/useDefaultFetchDataCount";
import PaymentHistoryService from "../../../../../../../services/accounts/paymentHistory";
import { useDefaultTableScrollProp } from "../../../../../../Customers/hooks/defaultTableScrollProp";
import { useGetNestedTableColumns } from "../hooks/useGetNestedTableColumns";
import { useGetTableColumns } from "../hooks/useGetTableColumns";
import { TRequest } from "../types";
import { Table } from "antd";
import { TablePagination } from "../../../../../../../components/TablePagination";
import { useDefualtOnRow } from "../../../../../../../lib/hooks/useDefaultOnRow";

type TPaymentsProps = {
  accountId: string;
  setPaymentId: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  shouldDataReload: boolean;
  year: number;
  setShouldDataReload: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setIsActionsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  isActionsDisabled: boolean;
};

export const Payments = ({
  accountId,
  setPaymentId,
  shouldDataReload,
  year,
  setShouldDataReload,
  setOpenEditMode,
  setIsActionsDisabled,
  isActionsDisabled,
}: TPaymentsProps) => {
  const {
    total,
    isLoading: isCountLoading,
    onPageChange,
    request,
    fetchCount,
  } = useFetchDataCount<{ contractId: string; year: number }>(
    PaymentHistoryService,
    { contractId: accountId, year },
    ["contractId", "year"]
  );

  const { data, isLoading, fetchData } = useFetchData<TRequest, any>(
    PaymentHistoryService,
    request
  );

  const reloadData = useCallback(() => {
    fetchCount();
    fetchData();
    setShouldDataReload(false);
  }, [fetchCount, fetchData, setShouldDataReload]);

  const { onRow } = useDefualtOnRow(setPaymentId);

  const { columns } = useGetTableColumns({
    setPaymentId,
    reloadData,
    setOpenEditMode,
    setIsActionsDisabled,
    isActionsDisabled,
  });

  const { columns: nestedColumns } = useGetNestedTableColumns();

  const { scroll } = useDefaultTableScrollProp(data, 235);

  const expandableRowProps = useMemo(() => {
    return {
      rowExpandable: (record: any) => record.disbursements.length !== 0,
      expandedRowRender: (record: any) => {
        return (
          <Table
            columns={nestedColumns}
            dataSource={record.disbursements}
            pagination={false}
            rowKey="id"
            className="payment-history-disb-table mt-3 mb-2"
            rowClassName={(record) => (record.method === 9 ? "row-gray" : "")}
          />
        );
      },
    };
  }, [nestedColumns]);

  const tableScroll = useMemo(() => ({ x: "88%", y: scroll?.y }), [scroll]);

  useEffect(() => {
    shouldDataReload && reloadData();
  }, [reloadData, shouldDataReload]);

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        loading={isLoading}
        pagination={false}
        rowKey="id"
        className="w-100 clickable-rows-table"
        scroll={tableScroll}
        expandable={expandableRowProps}
        onRow={onRow}
      />
      {total > 20 && (
        <TablePagination
          total={total}
          isTotalLoading={isCountLoading}
          onPageChange={onPageChange}
          pageSize={request.pageSize}
          currentPage={request.pageNumber}
        />
      )}
    </>
  );
};
