import { Button } from "antd";
import { useCallback, useState } from "react";
import { CreateEmailsModal } from ".";

type CreateButtonProps = {
  isLoading: boolean;
  reloadData: () => void;
};

export const CreateButton = ({ isLoading, reloadData }: CreateButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const openModal = useCallback((): void => setIsModalOpen(true), []);

  return (
    <>
      <Button
        shape="round"
        type="primary"
        disabled={isLoading}
        onClick={openModal}
      >
        Create Emails
      </Button>

      {isModalOpen && (
        <CreateEmailsModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          reloadData={reloadData}
        />
      )}
    </>
  );
};
