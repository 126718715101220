import { useCallback, useEffect, useMemo, useState } from "react";
import CustomersService from "../../../../../../../../../services/customers";

export const useFetchPrimaryData = (
  primaryData: {
    buyerId: string;
    sellerId: string;
  },
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const [data, setData] = useState<any[]>([]);

  const formattedData = useMemo(() => {
    return data.map((item: any) => {
      const { customerCompanyData, customerIndividualData, typeId } = item;

      return {
        id: item.id,
        number: item.number,
        email: item.primaryEmail,
        name:
          typeId === 2
            ? customerCompanyData.companyName || ""
            : `${customerIndividualData.firstName || ""} ${
                customerIndividualData.middleName || ""
              } ${customerIndividualData.lastName || ""}`,
        role: primaryData.buyerId === item.id ? 1 : 2,
      };
    });
  }, [data, primaryData]);

  const fetchData = useCallback(() => {
    Promise.all([
      CustomersService.get(primaryData.buyerId),
      CustomersService.get(primaryData.sellerId),
    ])
      .then((res) => {
        setData(res);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [primaryData, setIsLoading]);

  useEffect(() => fetchData(), [fetchData]);

  return { formattedData };
};
