import { FormInstance, Row } from "antd";
import { FloatNumberInput } from "../../../../../../../../components/FloatNumberInput";
import { useCalculateTotals } from "./hooks/useCalculateTotals";
import { getFormattedCurrency } from "../../../../../../../../lib/currencyFormatter";
import { useMemo } from "react";

type TSubtotalProps = {
  form: FormInstance;
  isFormDisabled: boolean;
};

export const Totals = ({ form, isFormDisabled }: TSubtotalProps) => {
  const { buyerSubtotal, buyerTotal, sellerFeeTotal } =
    useCalculateTotals(form);

  const TOTAL_INPUTS = useMemo(
    () => [
      { label: "Impound Refund", name: ["subtrahend", "impoundRefund"] },
      { label: "Discount", name: ["subtrahend", "discount"] },
    ],
    []
  );

  return (
    <Row className="w-100 pt-3 pb-4 px-6 d-flex flex-column payoff-form-total">
      <p className="my-1 d-flex justify-space-between">
        <span>Buyer Subtotal</span>
        <span>{getFormattedCurrency(buyerSubtotal)}</span>
      </p>
      <Row className="pl-4 pb-3">
        {TOTAL_INPUTS.map((item) => {
          return (
            <Row
              className="w-100 d-flex flex-nowrap justify-space-between align-center"
              key={item.label}
            >
              <p className="ma-0">{item.label}</p>
              <FloatNumberInput
                form={form}
                label=""
                name={item.name}
                prefix="– $"
                className="payoff-form-total-input mb-1"
                disabled={isFormDisabled}
                includeThousandsSeparator
              />
            </Row>
          );
        })}
      </Row>
      <p className="w-100 ma-0 pb-1 d-flex justify-space-between text-md w-500">
        <span>Buyer Total</span>
        <span>{getFormattedCurrency(buyerTotal)}</span>
      </p>
      <p className="divider w-100 ma-0 pt-1 d-flex justify-space-between text-md w-500">
        <span>Seller Fee Total</span>
        <span>{getFormattedCurrency(sellerFeeTotal)}</span>
      </p>
    </Row>
  );
};
