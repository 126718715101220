import { Button, Row, Table } from "antd";
import { TemplateCategoryItem, TemplateItem } from "../../../../types/Template";
import { useTableColumns } from "../hooks/useTableColumns";
import { useCallback, useMemo } from "react";
import { useSidebarControls } from "../hooks/useSidebarControls";
import { createPortal } from "react-dom";

type TemplatesTableProps = {
  category: TemplateCategoryItem | null;
  isLoading: boolean;
  fetchData: () => void;
};

export const TemplatesTable = ({
  category,
  isLoading,
  fetchData,
}: TemplatesTableProps) => {
  const { formWrapper, isOpen, setIsOpen, onEdit } = useSidebarControls(
    category,
    fetchData
  );

  const data: TemplateItem[] = useMemo(() => {
    return category ? category.templates : [];
  }, [category]);

  const onAdd = useCallback(() => setIsOpen(true), [setIsOpen]);

  const { columns } = useTableColumns(category, fetchData, onEdit);

  const onRow = useCallback(
    (record: any) => ({
      onClick: () => onEdit(record),
    }),
    [onEdit]
  );

  return (
    <Row className="d-block w-100 pl-8 h-100">
      <Row className="mb-6 align-center justify-space-between">
        <h5 className="h h5 ant-table-title">{category?.categoryName}</h5>
        <Button shape="round" onClick={onAdd}>
          Add New
        </Button>
      </Row>
      <Table
        className="templates-table overflow-auto"
        dataSource={data}
        columns={columns}
        loading={isLoading}
        pagination={false}
        rowKey="id"
        onRow={onRow}
      />
      {isOpen && createPortal(formWrapper, document.body)}
    </Row>
  );
};
