import { useCallback, useState } from "react";
import { useDefaultDeleteModal } from "../../../../../../../../../../../lib/hooks/defaultDeleteModal";
import {
  deleteDistribution,
  createDistribution,
} from "../../../../../../../../../../../acions/accounts/impounds/distributions";
import { IImpDistributionPost } from "../../../../../../../../../../../types/Distribuions";
import { useAppDispatch } from "../../../../../../../../../../../store";
import { FormInstance } from "antd";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../../../../../../../../../../configs/routesConfig";
import { transformToFloat } from "../../../../../../../../../../../lib/transformToFloat";

type TButtonHandlers = {
  reloadData: () => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  parentId: string | null;
  form?: FormInstance;
  options?: { value: string; label: string; props: any }[];
};

export const useButtonHandlers = ({
  reloadData,
  setIsOpen,
  parentId,
  form,
  options,
}: TButtonHandlers) => {
  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);

  const { openDeleteModal } = useDefaultDeleteModal(
    deleteDistribution,
    reloadData,
    setIsOpen
  );

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const onDeleteBtnClick = useCallback(
    (id: string) => openDeleteModal(id, "Delete Record"),
    [openDeleteModal]
  );

  const onCancelBtnClick = useCallback(() => setIsOpen(false), [setIsOpen]);

  const onSaveBtnClick = useCallback(
    async (id: string | null, customersIds: any) => {
      try {
        const formData = await form?.validateFields();

        const { batchId, date } = formData;

        const request: IImpDistributionPost = {
          ...formData,
          payeeId: customersIds.payeeId,
          impoundId: parentId,
          date: date.format(),
          amount: transformToFloat(formData.amount),
        };

        setIsFormDisabled(true);

        const res: any = await dispatch(createDistribution(request));

        if (options) {
          const batch = options.find((item) => item.value === batchId);

          batch &&
            navigate(
              `${ROUTES.PROCESSING}/${batchId}/payments/${res.paymentId}?batchNumber=${batch.props.batchNumber}&type=${batch.props.type}`
            );
        }
      } catch (error) {
        setIsFormDisabled(false);
      }
    },
    [dispatch, form, navigate, options, parentId]
  );

  return { isFormDisabled, onDeleteBtnClick, onCancelBtnClick, onSaveBtnClick };
};
