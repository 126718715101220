export const transformToFloat = (value: string) => {
  return value ? +value.toString().replace(/,/g, "") : null;
};

export const transformToFormatted = (
  value: number | null | undefined,
  digits: number = 2
) => {
  return value
    ? value.toLocaleString(undefined, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      })
    : null;
};
