import { useCallback } from "react";
import {
  transformToFloat,
  transformToFormatted,
} from "../../../../lib/transformToFloat";

export const useTransformRequest = (
  parentId: string | undefined,
  isRequest: boolean = true
) => {
  const transform = useCallback(
    (data: any) => {
      ["buyerFee", "buyerTax", "sellerFee", "sellerTax"].forEach(
        (prop: string) => {
          data[prop] = isRequest
            ? transformToFloat(data[prop])
            : transformToFormatted(data[prop]) || "0.00";
        }
      );

      if (parentId) {
        data.contractId = parentId;
      }
    },
    [isRequest, parentId]
  );

  return { transform };
};
