import { useCallback } from "react";
import { useFormData } from "./useFormData";
import { useAppDispatch } from "../../../../../../../../../store";
import { sendEmail } from "../../../../../../../../../acions/accounts/mailings";

export const useSend = (
  contractId: string,
  sendToData: any,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  close: () => void,
  onFail: (err: any) => void
) => {
  const dispatch = useAppDispatch();

  const { getFormData } = useFormData(sendToData);

  const send = useCallback(
    async (values: any) => {
      try {
        setIsLoading(true);
        const formData = getFormData(values);

        await Promise.resolve(dispatch(sendEmail(formData, contractId)));

        close();
      } catch (err) {
        setIsLoading(false);
        onFail(err);
      }
    },
    [getFormData, setIsLoading, dispatch, contractId, close, onFail]
  );

  return { send };
};
