import { useCallback, useState, MouseEvent } from "react";
import { TemplateItem } from "../../../../types/Template";
import { editTemplate } from "../../../../acions/administration/templates";
import { useAppDispatch } from "../../../../store";
import { useFormData } from "./useFormData";

export const useSetAsDefault = (reloadData: () => void) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { getFormData } = useFormData();

  const onUpdateDefault = useCallback(
    async (id: string, record: TemplateItem) => {
      try {
        setIsLoading(true);

        const formData = getFormData({ ...record, isDefault: true });

        await Promise.resolve(dispatch(editTemplate(id, formData)));

        reloadData();

        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    },
    [dispatch, getFormData, reloadData]
  );

  const onUpdateDefaultClick = useCallback(
    (e: MouseEvent<HTMLInputElement>, record: TemplateItem): void => {
      e.stopPropagation();
      onUpdateDefault(e.currentTarget.id, record);
    },
    [onUpdateDefault]
  );

  return { isLoading, onUpdateDefaultClick };
};
