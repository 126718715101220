import { SET_PERMISSIONS, CLEAR_PERMISSIONS } from "../acions/types";

const initialState = {};

export default function permissions(state = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case SET_PERMISSIONS:
      return { ...payload };
    case CLEAR_PERMISSIONS:
      return {};
    default:
      return state;
  }
}
