import dayjs from "../../../../../../../configs/dayjs";
import { DATE_FORMAT } from "../../../../../../../lib/constants";
import { getFormattedCurrency } from "../../../../../../../lib/currencyFormatter";
import "./styles.scss";

type CouponProps = {
  accountNumber: string;
  data: any;
};

export const Coupon = ({ accountNumber, data }: CouponProps) => {
  return (
    <div id="coupon">
      {data && (
        <>
          <div className="data column">
            <p className="label">Buyer(s):</p>
            <p>{data.generalInformation.buyer}</p>
            <p>&nbsp;</p>
            <p className="label">Please Remit Payment to:</p>
            <p>
              Sunwest Escrow, LC
              <br />
              P.O. Box 36371
              <br />
              Albuquerque, NM 87176-6371
            </p>
            <p>&nbsp;</p>
          </div>
          <dl className="data column">
            <dt>Payment Date:</dt>
            <dd className="underline">
              {data.nextPayment.paymentDate
                ? dayjs(data.nextPayment.paymentDate).format(DATE_FORMAT)
                : "-"}
            </dd>
            <dt>Contract Number:</dt>
            <dd>{accountNumber}</dd>
            <dt>Payment Amount:</dt>
            <dd className="underline">$</dd>
            <dt className="noemph">*Expected Pmt Amt:</dt>
            <dd className="noemph">
              {getFormattedCurrency(data.nextPayment.amount)}
            </dd>
          </dl>
        </>
      )}
      <p className="data">
        To insure proper credit to your account, please include a coupon with
        the payment.
      </p>
    </div>
  );
};
