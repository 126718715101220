import { useCallback } from "react";
import { deleteEmail } from "../../../acions/emails";
import { useDefaultDeleteModal } from "../../../lib/hooks/defaultDeleteModal";

export const useDelete = (reloadData: () => void) => {
  const { openDeleteModal } = useDefaultDeleteModal(deleteEmail, reloadData);

  const remove = useCallback(
    (id: string) => {
      openDeleteModal(id, "Delete Email from Outbox");
    },
    [openDeleteModal]
  );

  return { remove };
};
