import { useCallback, useEffect, useState } from "react";
import DocumentsService from "../../../../../../../services/accounts/documents";
import dayjs from "dayjs";
import { FormInstance } from "antd";

export const useGetInitialValues = (id: string | null, form: FormInstance) => {
  const [isLoading, setIsLoading] = useState<boolean>(!!id);

  const fetchDocument = useCallback((): void => {
    if (id !== null) {
      setIsLoading(true);

      DocumentsService.fetch(id)
        .then((res: any) => {
          setIsLoading(false);

          const { date, fileName, uploaded, fileContentType, fileSizeBytes } =
            res;

          const data = { ...res, date: dayjs(date) };

          if (fileName && uploaded) {
            data.file = [
              {
                name: fileName,
                type: fileContentType,
                size: fileSizeBytes,
              },
            ];
          }

          form.setFieldsValue(data);
        })
        .catch(() => setIsLoading(false));
    } else {
      form.setFieldValue("date", dayjs());
    }
  }, [id, setIsLoading, form]);

  useEffect(() => fetchDocument(), [fetchDocument]);

  return { isLoading };
};
