import { ReactNode, useMemo, useState } from "react";
import { SideBarContainer } from "../../../../../../../components/SideBarContainer";
import { MailingSettingsForm } from "../components/MailingSettingsForm";

export const useSidebar = (
  contractId: string,
  mailing: any,
  reloadData: () => void
) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const formWrapper: ReactNode = useMemo(() => {
    return (
      <SideBarContainer
        title="Mailing Settings"
        setShowSideBar={setIsOpen}
        setCurrentId={() => {}}
      >
        <MailingSettingsForm
          reloadData={reloadData}
          setIsOpen={setIsOpen}
          parentId={contractId}
          mailing={mailing}
        />
      </SideBarContainer>
    );
  }, [contractId, reloadData, mailing]);

  return { formWrapper, isOpen, setIsOpen };
};
