import { Card, Form, FormInstance, Row, Table, TableProps } from "antd";
import { useFetchData } from "../../../../../../lib/hooks/useDefaultFetchData";
import { useFetchDataCount } from "../../../../../../lib/hooks/useDefaultFetchDataCount";
import { useGetTableColumns } from "./hooks/useGetTableColumns";
import { TablePagination } from "../../../../../../components/TablePagination";
import { useCallback, useMemo, useState } from "react";
import { useDefaultTableScrollProp } from "../../../../../Customers/hooks/defaultTableScrollProp";
import { useOnSorterChange } from "./hooks/useOnSorterChange";
import BatchDisbursementsService from "../../../../../../services/processing/disbursements";
import { TRequest } from "./types";
import { IBatchDisbursements } from "../../../../../../types/PayeeDisbursements";
import { useOnAssignClick } from "./hooks/useOnAssignClick";
import { AssignChecksModal } from "./components/AssignChecksModal";
import { ChecksModeHeader } from "./components/ChecksModeHeader";
import { CommonModeHeader } from "./components/CommonModeHeader";
import "./styles.scss";

type TDisbursementsTableProps = {
  batchId: string;
  reloadBatch: (isShowLoader?: boolean) => void;
  setShouldDisbOpen: React.Dispatch<
    React.SetStateAction<
      | {
          checkModeOn: boolean;
        }
      | undefined
    >
  >;
  shouldDisbOpen:
    | {
        checkModeOn: boolean;
      }
    | undefined;
};

export const DisbursementTable = ({
  batchId,
  reloadBatch,
  setShouldDisbOpen,
  shouldDisbOpen,
}: TDisbursementsTableProps) => {
  const [selectedDisbursementIds, setSelectedDisbursementIds] = useState<
    React.Key[]
  >([]);

  const [isChecksModeOn, setIsChecksModeOn] = useState<boolean>(
    !!shouldDisbOpen && shouldDisbOpen.checkModeOn
  );

  const {
    total,
    isLoading: isCountLoading,
    request,
    setRequest,
    onPageChange,
    fetchCount,
  } = useFetchDataCount<{
    batchId: string;
    method: number | null;
    orderBy?: number;
  }>(
    BatchDisbursementsService,
    {
      batchId,
      method: !!shouldDisbOpen && shouldDisbOpen.checkModeOn ? 1 : null,
      orderBy: 1,
    },
    ["method"]
  );

  const { data, isLoading, fetchData } = useFetchData<
    TRequest,
    IBatchDisbursements
  >(BatchDisbursementsService, request);

  const reloadData = useCallback(() => {
    fetchData();
    fetchCount();
    reloadBatch(false);
  }, [fetchCount, fetchData, reloadBatch]);

  const [form]: FormInstance[] = Form.useForm();

  const { columns } = useGetTableColumns();

  const { scroll } = useDefaultTableScrollProp(data);

  const { onSorterChange } = useOnSorterChange(request, setRequest);

  const { isDisabled, isOpen, onAssignClick, setIsOpen, hasAssigned } =
    useOnAssignClick({
      batchId,
      form,
    });

  const onMethodChange = useCallback(
    (value: number) => {
      const method = value || null;

      const newRequest = { ...request, method };

      setRequest(newRequest);
    },
    [request, setRequest]
  );

  const onOpenChecksModeClick = useCallback(() => {
    onMethodChange(1);
    setIsChecksModeOn(true);
  }, [onMethodChange]);

  const rowSelectionProps: TableProps<IBatchDisbursements>["rowSelection"] =
    useMemo(
      () =>
        isChecksModeOn
          ? {
              onChange: (selectedRowKeys: React.Key[]) =>
                setSelectedDisbursementIds(selectedRowKeys),
              type: "checkbox",
            }
          : undefined,
      [isChecksModeOn]
    );

  return (
    <Row className="w-100 py-6 px-8">
      <Card className="w-100">
        {isChecksModeOn ? (
          <ChecksModeHeader
            selectedDisbursementIds={selectedDisbursementIds}
            setSelectedDisbursementIds={setSelectedDisbursementIds}
            setIsChecksModeOn={setIsChecksModeOn}
            onMethodChange={onMethodChange}
            onAssignClick={onAssignClick}
            batchId={batchId}
            reloadData={reloadData}
          />
        ) : (
          <CommonModeHeader
            reloadData={reloadData}
            batchId={batchId}
            onMethodChange={onMethodChange}
            isDisabled={isDisabled}
            onAssignClick={onAssignClick}
            onOpenChecksModeClick={onOpenChecksModeClick}
          />
        )}
        <Row className="w-100 d-block pt-6">
          <Table
            dataSource={data}
            columns={columns}
            loading={isLoading}
            pagination={false}
            rowKey="id"
            className="w-100 batch-disbursements-table"
            scroll={scroll}
            onChange={onSorterChange}
            showSorterTooltip={false}
            rowSelection={rowSelectionProps}
          />
          {total > 0 && (
            <TablePagination
              total={total}
              isTotalLoading={isCountLoading}
              onPageChange={onPageChange}
              currentPage={request.pageNumber}
              pageSize={request.pageSize}
            />
          )}
        </Row>
      </Card>
      <AssignChecksModal
        form={form}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        hasAssigned={hasAssigned}
        batchId={batchId}
        selectedDisbursementIds={selectedDisbursementIds}
        reloadData={reloadData}
        setShouldDisbOpen={setShouldDisbOpen}
        isChecksModeOn={isChecksModeOn}
      />
    </Row>
  );
};
