import dayjs from "../../../../../../../configs/dayjs";
import { ReactNode, useMemo } from "react";
import { DATE_FORMAT } from "../../../../../../../lib/constants";

export const useSetLastUpdated = (data: any, lastUpdate: string | null) => {
  const lastUpdated: ReactNode = useMemo(() => {
    if (data) {
      return (
        <div className="mr-8 desktop-label">
          <p className="my-0 text-md title">
            Last Updated:&nbsp;
            {dayjs(lastUpdate || data.lastModifiedDateTime).format(DATE_FORMAT)}
          </p>
        </div>
      );
    }

    return <div />;
  }, [data, lastUpdate]);

  return { lastUpdated };
};
