import { Rule } from "antd/es/form";
import { useMemo } from "react";
import _ from "lodash";
import {
  CUSTOMER_NOT_FOUND,
  EMAIL_NOT_SPECIFIED,
  ONLY_5_RECEIVERS_ALLOWED,
  SEND_TO_REQUIRED,
  WRONG_EMAIL_FORMAT,
} from "../../../../../../../../../../../lib/constants";

export const useRules = (optionsData: any) => {
  const rules = useMemo(
    () => [
      () => ({
        validateTrigger: "onSubmit",
        validator(_r: Rule, value: undefined | string[]) {
          if (!value || value.length === 0) {
            return Promise.reject(new Error(SEND_TO_REQUIRED));
          }

          return Promise.resolve();
        },
      }),
      () => ({
        validator(_r: Rule, value: undefined | string[]) {
          const messages = new Set();

          value &&
            value.forEach((item: string) => {
              const val = optionsData.find(
                (data: any) => data.i.toString() === item
              );

              if (!val) {
                messages.add(
                  !_.isNaN(+item) ? CUSTOMER_NOT_FOUND : WRONG_EMAIL_FORMAT
                );
              } else {
                !val.email && messages.add(EMAIL_NOT_SPECIFIED);
              }
            });

          if (value && value.length > 5) {
            messages.add(ONLY_5_RECEIVERS_ALLOWED);
          }

          const msgArr = Array.from(messages);

          return msgArr.length > 0 ? Promise.reject(msgArr) : Promise.resolve();
        },
      }),
    ],
    [optionsData]
  );

  return { rules };
};
