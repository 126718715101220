import { useCallback } from "react";
import { useDefaultDeleteModal } from "../../../lib/hooks/defaultDeleteModal";
import { deleteAllEmails } from "../../../acions/emails";

export const useDeleteAll = (reloadData: () => void) => {
  const { openDeleteModal } = useDefaultDeleteModal(
    deleteAllEmails,
    reloadData
  );

  const removeAll = useCallback(() => {
    openDeleteModal("", "Delete All Emails from Outbox");
  }, [openDeleteModal]);

  return { removeAll };
};
