import { App } from "antd";
import { useCallback } from "react";
import { ProgressBar } from "../../components/ProgressBar";
import BackGroundService from "../../services/background";

export const useOpenProgressModal = (
  title: string,
  errorMessage: string,
  onSuccess: (id?: string) => void,
  customSuccessMessage?: string
) => {
  const { modal } = App.useApp();

  const openModal = useCallback(
    (id: string) => {
      modal.confirm({
        title,
        icon: null,
        okButtonProps: {
          style: { display: "none" },
        },
        cancelButtonProps: {
          shape: "round",
        },
        content: (
          <ProgressBar
            id={id}
            onSuccess={onSuccess}
            errorMessage={errorMessage}
            customSuccessMessage={customSuccessMessage}
          />
        ),
        onCancel: async () => await BackGroundService.cancelTask(id),
      });
    },
    [customSuccessMessage, errorMessage, modal, onSuccess, title]
  );

  return { openModal };
};
