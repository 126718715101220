import { useCallback, MouseEvent } from "react";
import { deleteTemplate } from "../../../../acions/administration/templates";
import { useDefaultDeleteModal } from "../../../../lib/hooks/defaultDeleteModal";
import { useAppDispatch } from "../../../../store";
import { TemplateItem } from "../../../../types/Template";
import { setErrorMessage } from "../../../../acions/actionsCreators/messageActions";

export const useDelete = (reloadData: () => void) => {
  const ERROR_MESSAGE = "Default template can’t be deleted";

  const TITLE = "Delete template";

  const dispatch = useAppDispatch();

  const { openDeleteModal } = useDefaultDeleteModal(deleteTemplate, reloadData);

  const onDeleteClick = useCallback(
    (e: MouseEvent<HTMLInputElement>, record: TemplateItem) => {
      e.stopPropagation();

      const { id, isDefault } = record;

      isDefault
        ? dispatch(setErrorMessage(ERROR_MESSAGE, true))
        : openDeleteModal(id, TITLE);
    },
    [openDeleteModal, dispatch]
  );

  return { onDeleteClick };
};
