import { useEffect, useMemo } from "react";
import {
  NavigateFunction,
  Outlet,
  useLocation,
  useNavigate,
  Location,
} from "react-router-dom";
import AuthService from "../../services/auth";
import ROUTES from "../../configs/routesConfig";
import _ from "lodash";

export const RedirectHome = () => {
  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();

  const title: string = useMemo(() => {
    const root = location.pathname.split("/")[1].split("-");

    const res: string = root
      .map((item: string) => _.capitalize(item))
      .join(" ");

    return `${res} - Sunwest Escrow`;
  }, [location]);

  useEffect(() => {
    document.title = title;

    if (location.pathname === ROUTES.HOME) {
      navigate(AuthService.isLoggedIn() ? ROUTES.DASHBOARD : ROUTES.LOGIN);
    }
  }, [location, navigate, title]);

  return <Outlet />;
};
