enum ROUTES {
  NOT_FOUND = "*",
  HOME = "/",
  INTERNAL_SERVER_ERROR = "/internal-server-error",
  LOGIN = "/login",
  SIGN_UP = "/signup",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/reset-password",
  FORGOT_ACCOUNT = "/forgot-account-details",
  ACCOUNTS = "/accounts",
  ACCOUNT = "/accounts/:accountId",
  PROFILE = "/profile",
  DASHBOARD = "/home",
  USER_MANAGEMENT = "/user-management",
  CUSTOMERS = "/customers",
  CUSTOMER = "/customers/:customerId",
  ADMINISTRATION = "/administration",
  PROFILE_CHANGE_PASSWORD = "/profile/change-password",
  PROFILE_CHANGE_PHONE = "/profile/change-phone",
  PROFILE_CHANGE_EMAIL = "/profile/change-email",
  OTHER_ACCOUNTS = "/other-accounts",
  PROCESSING = "/processing",
  BATCH = "/processing/:batchId",
  PAYMENT = "/processing/:batchId/payments/:paymentId",
  EMAILS = "/emails",
}

export default ROUTES;
