import { Form, FormInstance, Input, Row } from "antd";
import { FloatNumberInput } from "../../../../../../../../components/FloatNumberInput";
import { useMemo } from "react";
import { useGetItemTitleRules } from "./hooks/useGetItemTitleRules";

type TBuyerAmountsProps = {
  form: FormInstance;
  isFormDisabled: boolean;
};

export const BuyerAmounts = ({ form, isFormDisabled }: TBuyerAmountsProps) => {
  const AMOUNT_INPUTS_DATA = useMemo(
    () => [
      {
        label: "Principal",
        name: ["buyer", "principal"],
        disabled: true,
      },
      {
        label: "Interest",
        name: ["buyer", "interest"],
        disabled: isFormDisabled,
      },
      {
        label: "Unpaid Interest",
        name: ["buyer", "unpaidInterest"],
        disabled: isFormDisabled,
      },
      {
        label: "Unpaid Late Charges",
        name: ["buyer", "unpaidLateCharges"],
        disabled: isFormDisabled,
      },
      {
        label: "Current Late Charges",
        name: ["buyer", "currentLateCharges"],
        disabled: isFormDisabled,
      },
      {
        label: null,
        name: ["buyer", "amount"],
        disabled: isFormDisabled,
      },
    ],
    [isFormDisabled]
  );

  const inputClassName = (index: number): string => {
    return index === AMOUNT_INPUTS_DATA.length - 1 ? "mb-0" : "mb-2";
  };

  const { rules } = useGetItemTitleRules();

  return (
    <Row className="w-100 d-flex flex-column">
      <p className="mb-4 mt-0 text-md w-500">Buyer Amounts</p>
      {AMOUNT_INPUTS_DATA.map((item, index) => {
        return (
          <Row
            className="d-flex justify-space-between flex-nowrap w-100"
            key={item.label || "customItem"}
          >
            {!!item.label ? (
              <span className="pr-2">{item.label}</span>
            ) : (
              <Form.Item
                className="flex-grow-1 mr-2 mb-0 payoff-form-item-title"
                name={["item", "title"]}
                rules={rules}
              >
                <Input placeholder="Item Title" />
              </Form.Item>
            )}
            <FloatNumberInput
              name={item.name}
              label=""
              form={form}
              disabled={item.disabled}
              allowNegative
              includeThousandsSeparator
              className={`payoff-form-amount-input ${inputClassName(index)}`}
            />
          </Row>
        );
      })}
    </Row>
  );
};
