import { FormInstance } from "antd";
import { useEffect } from "react";

export const useInitialSettings = (mailings: any, form: FormInstance) => {
  useEffect(() => {
    const data = {
      ...mailings,
      lateNoticePayers: mailings.latePayerDays !== null,
      lateNoticePayees: mailings.latePayeeDays !== null,
    };

    form.setFieldsValue(data);
  }, [mailings, form]);
};
