import { FormInstance } from "antd";
import { useCalcInterestBalance } from "./calculations/useCalcInterestBalance";
import { useCalcPrincipalPaid } from "./calculations/useCalcPrincipalPaid";
import { useCalcLCBalance } from "./calculations/useCalcLCBalance";
import { useCalcImpoundBalance } from "./calculations/useCalcImpoundBalance";
import { useCalcPrincipalBalance } from "./calculations/useCalcPrincipalBalance";
import { useCalcInterestForScreen } from "./calculations/useCalcInterestForScreen";
import { useCallback } from "react";
import { useCalcTotals } from "./calculations/useCalcTotals";
import { useCalcUnpaidInterestBefore } from "./calculations/useCalcUnpaindInterestBefore";

export const useCalculate = (
  form: FormInstance,
  instance: any,
  contractId: string,
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>,
  setForm: (data: any, isFormBlured: boolean) => void
) => {
  const { calcInterestBalance } = useCalcInterestBalance();

  const { calcPrincipalPaid } = useCalcPrincipalPaid(calcInterestBalance);

  const { calcLCBalance } = useCalcLCBalance();

  const { calcImpoundBalance } = useCalcImpoundBalance();

  const { calcPrincipalBalance } = useCalcPrincipalBalance();

  const { calcInterestForScreen } = useCalcInterestForScreen(
    contractId,
    calcInterestBalance,
    calcPrincipalPaid,
    calcPrincipalBalance
  );

  const { calcTotalPayment, calcTotalToDisburse } = useCalcTotals();

  const { calcUnpaidInterestBefore } = useCalcUnpaidInterestBefore(
    calcInterestBalance,
    calcPrincipalPaid,
    calcPrincipalBalance
  );

  const calculate = useCallback(
    async (toUpdate?: { name: string; value: any }) => {
      try {
        setIsDisabled(true);

        let history = await form.validateFields();

        history = {
          ...instance,
          ...history,
          breakdown: {
            ...instance.breakdown,
            ...history.breakdown,
          },
          disbursements:
            toUpdate && toUpdate.name === "disbursements"
              ? toUpdate.value
              : instance.disbursements,
        };

        calcInterestBalance(history);
        calcPrincipalPaid(history);
        calcLCBalance(history);
        calcImpoundBalance(history);
        await calcInterestForScreen(history);
        calcPrincipalBalance(history);
        calcUnpaidInterestBefore(history);
        calcTotalPayment(history);
        calcTotalToDisburse(history);

        setForm(history, false);
        return 1;
      } catch (error) {
        return 0;
      } finally {
        setIsDisabled(false);
      }
    },
    [
      calcImpoundBalance,
      calcInterestBalance,
      calcInterestForScreen,
      calcLCBalance,
      calcPrincipalBalance,
      calcPrincipalPaid,
      calcTotalPayment,
      calcTotalToDisburse,
      calcUnpaidInterestBefore,
      form,
      instance,
      setForm,
      setIsDisabled,
    ]
  );

  return { calculate };
};
