import { ReactNode, useCallback } from "react";
import {
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";

export const useStatusLabel = () => {
  const getStatusLabel = useCallback(
    (status: "warning" | "error" | "processing"): ReactNode => {
      if (status === "warning") {
        return (
          <Tooltip placement="top" title="Invalid Email">
            <ExclamationCircleOutlined className={status} />
          </Tooltip>
        );
      }

      if (status === "processing") {
        return (
          <Tooltip placement="top" title="Sending">
            <ClockCircleOutlined className={status} />
          </Tooltip>
        );
      }

      return (
        <Tooltip placement="top" title="Sending failed">
          <CloseCircleOutlined className={status} />
        </Tooltip>
      );
    },
    []
  );

  return { getStatusLabel };
};
