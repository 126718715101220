import { Area } from "@ant-design/plots";
import { CHART_BLUE_7 } from "../../../lib/constants";
import { useEffect, useMemo, useState } from "react";
import { DashboardService } from "../../../services/dashboard";
import dayjs from "../../../configs/dayjs";
import { Spin } from "antd";
import { KPI_CODES } from "../kpiCodes";

type LineChartCardProps = {
  totalImpound: number | null;
  isDataLoading: boolean;
};

export const LineChartCard = ({
  totalImpound,
  isDataLoading,
}: LineChartCardProps) => {
  const [data, setData] = useState<any>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const now = dayjs();

    const request = {
      DateTo: now.format("YYYY-MM-DD"),
      Datefrom: now.subtract(6, "months").format("YYYY-MM-DD"),
      KpiCode: KPI_CODES.IMPOUNDS_TOTAL_BALANCE,
    };
    DashboardService.fetchDashboardHistoryData(request)
      .then((res) => {
        if (res.length > 0) {
          const value = res
            .map((item: any) => {
              const month = dayjs(item.createdAt).format("MMM");

              return {
                month,
                amount: item.kpiValues[0].value,
                tooltipValue: `${month}, ${dayjs(item.createdAt).format(
                  "YYYY"
                )}`,
              };
            })
            .reverse();

          setData(value);
        }

        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, []);

  const balanceValue: string = useMemo(() => {
    return totalImpound !== null ? `$${totalImpound.toLocaleString()}K` : "";
  }, [totalImpound]);

  const config = useMemo(
    () => ({
      data,
      padding: "auto" as const,
      xField: "month",
      yField: "amount",
      height: 264,
      color: CHART_BLUE_7,
      xAxis: {
        tickCount: 5,
      },
      areaStyle: () => {
        return {
          fill: "l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff",
        };
      },
      tooltip: {
        customContent: (_: any, data: any) => {
          return data.length > 0
            ? `<div class="px-3 py-2"><div class="text-sm w-400 pb-1">${
                data[0].data.tooltipValue
              }</div><div class="text-sm w-500">${data[0].data.amount.toLocaleString()}k</div></div>`
            : "";
        },
      },
    }),
    [data]
  );

  return (
    <>
      {!isLoading && !isDataLoading ? (
        <div>
          <div className="d-flex align-end mb-6">
            <h3 className="mt-0 h h3">{balanceValue}</h3>
          </div>

          <Area {...config} />
        </div>
      ) : (
        <div className="h-100 d-flex justify-center align-center">
          <Spin size="large" />
        </div>
      )}
    </>
  );
};
