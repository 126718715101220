import { FormInstance } from "antd";
import {
  transformToFloat,
  transformToFormatted,
} from "../../../../../../../lib/transformToFloat";

export const useCalculateInterestPerDiem = (form: FormInstance) => {
  const calculate = (): string => {
    const year = new Date().getFullYear();

    const isLeapYear = new Date(year, 1, 29).getDate() === 29;

    let daysInYear = 365;

    switch (form.getFieldValue("interestCalculationMethod")) {
      case 0:
        daysInYear = 360;
        break;
      case 1:
        daysInYear = isLeapYear ? 366 : 365;
        break;
      case 2:
        daysInYear = 365;
        break;
      default:
        break;
    }

    const balancePrincipal = transformToFloat(
      form.getFieldValue("balancePrincipal")
    );

    const interestRate = transformToFloat(form.getFieldValue("interestRate"));

    const interestPerDiem =
      ((balancePrincipal || 0) * (interestRate ?? 0)) / (daysInYear * 100);

    form.setFieldValue(
      "interestPerDiem",
      transformToFormatted(interestPerDiem) || "0.000000"
    );

    return transformToFormatted(interestPerDiem) || "0.000000";
  };

  return { calculate };
};
