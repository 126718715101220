import { Tooltip } from "antd";
import {
  RightOutlined,
  TeamOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import iconBatchValidated from "../../../images/icons/BatchValidated.svg";
import iconDisbursementsRegistered from "../../../images/icons/BatchRegistered.svg";
import iconDisbursementsNotRegistered from "../../../images/icons/BatchNotRegistered.svg";
import iconChecksDone from "../../../images/icons/ChecksDone.svg";
import iconChecksNotPrinted from "../../../images/icons/ChecksNotPrinted.svg";
import { IBatchGet } from "../../../types/Batches";
import { ReactNode } from "react";

export const useGetProcessingState = () => {
  const getState = (record: IBatchGet, showCreatorIcon = true): ReactNode => {
    const {
      isValidated,
      isDisbursementsRegistred,
      isChecksPrinted,
      isCreatorPaymentOnly,
      type,
    } = record;

    return (
      <div className="d-flex justify-space-between">
        <div className="d-flex align-center">
          <Tooltip
            title={`Batch is ${!isValidated ? "not" : ""} validated`}
            trigger={"hover"}
          >
            {isValidated ? (
              <img src={iconBatchValidated} alt="Batch state" />
            ) : (
              <FileDoneOutlined className="ant-table-btn-size ant-table-icon" />
            )}
          </Tooltip>
          <RightOutlined className="mx-1 processing-state-arrow ant-table-icon" />
          <Tooltip
            title={`Check numbers are ${
              !isDisbursementsRegistred ? "not" : ""
            } assigned`}
            trigger={"hover"}
          >
            <img
              src={
                isDisbursementsRegistred
                  ? iconDisbursementsRegistered
                  : iconDisbursementsNotRegistered
              }
              alt="Check numbers state"
            />
          </Tooltip>
          <RightOutlined className="mx-1 processing-state-arrow ant-table-icon" />
          <Tooltip
            title={`Checks are ${!isChecksPrinted ? "not" : ""} printed`}
            trigger={"hover"}
          >
            <img
              src={isChecksPrinted ? iconChecksDone : iconChecksNotPrinted}
              alt="Checks state"
            />
          </Tooltip>
        </div>
        {!isCreatorPaymentOnly && type !== 3 && showCreatorIcon && (
          <Tooltip
            title="Anyone with the permissions can post payments"
            trigger={"hover"}
          >
            <TeamOutlined />
          </Tooltip>
        )}
      </div>
    );
  };

  return { getState };
};
