import { FormInstance } from "antd";
import { useCallback, useState } from "react";
import { updateContract } from "../../../acions/accounts/contracts";
import { setErrorMessage } from "../../../acions/actionsCreators/messageActions";
import { FIX_VALIDATION_ERRORS_MSG } from "../../../lib/constants";
import { useAppDispatch } from "../../../store";
import { useGetInpurSearchIds } from "../../Accounts/components/Filters/hooks/getInputSearchIds";
import { Dayjs } from "dayjs";
import { useTransformAmounts } from "../components/View/Admin/Details/hooks/useTransformAmounts";

export const useUpdateAccount = (
  form: FormInstance,
  contractId: string,
  setIsFormDisabled: (value: boolean) => void
) => {
  const dispatch = useAppDispatch();

  const [lcSectionKey, setLcSectionKey] = useState<boolean>(false);

  const {
    customerIds,
    onCustomerFound,
    hasPrimaryBuyerId,
    hasPrimarySellerId,
  } = useGetInpurSearchIds();

  const formatDate = useCallback((data: any, prop: string, value: Dayjs) => {
    data[prop] = value ? value.startOf("D").format() : undefined;
  }, []);

  const { transform } = useTransformAmounts(true);

  const getFormattedRequest: any = useCallback(
    (request: any) => {
      let data: any = { ...request };

      const {
        nextDueDate,
        interestCalculationDate,
        beginningDate,
        balanceDueDate,
      } = request;

      formatDate(data, "interestCalculationDate", interestCalculationDate);
      formatDate(data, "nextDueDate", nextDueDate);

      formatDate(data, "balanceDueDate", balanceDueDate);
      formatDate(data, "beginningDate", beginningDate);

      data.primaryBuyerId = customerIds.primaryBuyerNumber;
      data.primarySellerId = customerIds.primarySellerNumber;

      delete data.lateChargeAssessedDate;

      transform(data);

      return data;
    },
    [customerIds, formatDate, transform]
  );

  const update = useCallback(
    async (onSuccess?: () => void) => {
      try {
        const res = await form.validateFields();
        const request: any = getFormattedRequest(res);

        if (customerIds.primaryBuyerNumber && customerIds.primarySellerNumber) {
          setIsFormDisabled(true);
          await Promise.resolve(dispatch(updateContract(contractId, request)));
          setIsFormDisabled(false);
          onSuccess && onSuccess();

          setLcSectionKey(!lcSectionKey);

          return true;
        } else {
          return false;
        }
      } catch (e: any) {
        if (e.errorFields) {
          e.errorFields.forEach((item: any) => {
            if (item.name[0] === "bankAccountNumber") {
              dispatch(setErrorMessage(FIX_VALIDATION_ERRORS_MSG, true));
            }
          });
        }
        setIsFormDisabled(false);

        return false;
      }
    },
    [
      form,
      getFormattedRequest,
      customerIds,
      setIsFormDisabled,
      dispatch,
      contractId,
      lcSectionKey,
    ]
  );

  return {
    update,
    onCustomerFound,
    hasPrimaryBuyerId,
    hasPrimarySellerId,
    lcSectionKey,
  };
};
