import { useCallback, useEffect, useState } from "react";
import {
  IImpoundGetOne,
  IImpoundsTypeExtended,
} from "../../../../../../../../../../../types/Impounds";
import ImpoundsTypeService from "../../../../../../../../../../../services/administration/impounds";
import ImpoundsService from "../../../../../../../../../../../services/accounts/impounds/index";
import { FormInstance } from "antd";
import { transformToFormatted } from "../../../../../../../../../../../lib/transformToFloat";

export const useGetInitialValues = (
  id: string | null,
  form: FormInstance,
  props: { setBalanceBeginningYear: (value: number | null) => void }
) => {
  const [entry, setEntry] = useState<IImpoundGetOne | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(!!id);

  const [isTypesLoading, setIsTypesLoading] = useState<boolean>(false);

  const [types, setTypes] = useState<{ value: string; label: string }[]>();

  const setInitialValues = useCallback(() => {
    if (id !== null) {
      setIsLoading(true);

      ImpoundsService.fetch(id)
        .then((res: IImpoundGetOne) => {
          const initialValues = {
            impoundAccount: res.impoundAccount.toString(),
            impoundTypeId: res.impoundTypeId,
            amount: transformToFormatted(res.amount),
            payeeId: res.payeeNumber,
          };
          setIsLoading(false);

          form.setFieldsValue(initialValues);

          res.balanceBeginningYear &&
            props.setBalanceBeginningYear(res.balanceBeginningYear);

          setEntry(res);
        })
        .catch(() => setIsLoading(false));
    }
  }, [form, id, props]);

  const fetchTypes = useCallback(() => {
    setIsTypesLoading(true);

    ImpoundsTypeService.fetchAll()
      .then((res: any) => {
        const typesArr = res.map((item: IImpoundsTypeExtended) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setTypes(typesArr);
        setIsTypesLoading(false);
      })
      .catch(() => setIsTypesLoading(false));
  }, []);

  useEffect(() => {
    fetchTypes();
    setInitialValues();
  }, [setInitialValues, fetchTypes, id]);

  return { isLoading, types, entry, isTypesLoading };
};
