import { useMemo } from "react";
import {
  FIRST_NAME_REQUIRED,
  LAST_NAME_REQUIRED,
  MAX_FIRSTNAME,
  MAX_LASTNAME,
  MAX_MIDDLENAME,
  WRONG_FIRST_NAME,
  WRONG_LAST_NAME,
  WRONG_MIDDLE_NAME,
  WRONG_NAME_EXTENDED,
} from "../constants";
import { NAME_RULE, NAME_RULE_EXTENDED } from "../validationRules";

export const useGetFullNameFormRules = (
  isExtended: boolean = false,
  namePath?: string
) => {
  const formOptions = useMemo(() => {
    return [
      {
        label: "First Name",
        name: namePath ? [namePath, "firstName"] : "firstName",
        rules: [
          {
            max: 50,
            message: MAX_FIRSTNAME,
            validateTrigger: "onSubmit",
          },
          {
            pattern: isExtended ? NAME_RULE_EXTENDED : NAME_RULE,
            message: isExtended ? WRONG_NAME_EXTENDED : WRONG_FIRST_NAME,
            validateTrigger: "onSubmit",
          },
          {
            required: true,
            message: FIRST_NAME_REQUIRED,
            validateTrigger: "onSubmit",
          },
        ],
      },
      {
        label: "Middle Name",
        name: namePath ? [namePath, "middleName"] : "middleName",
        rules: [
          {
            max: 50,
            message: MAX_MIDDLENAME,
            validateTrigger: "onSubmit",
          },
          {
            pattern: isExtended ? NAME_RULE_EXTENDED : NAME_RULE,
            message: isExtended ? WRONG_NAME_EXTENDED : WRONG_MIDDLE_NAME,
            validateTrigger: "onSubmit",
          },
        ],
      },
      {
        label: "Last Name",
        name: namePath ? [namePath, "lastName"] : "lastName",
        rules: [
          {
            max: 50,
            message: MAX_LASTNAME,
            validateTrigger: "onSubmit",
          },
          {
            pattern: isExtended ? NAME_RULE_EXTENDED : NAME_RULE,
            message: isExtended ? WRONG_NAME_EXTENDED : WRONG_LAST_NAME,
            validateTrigger: "onSubmit",
          },
          {
            required: true,
            message: LAST_NAME_REQUIRED,
            validateTrigger: "onSubmit",
          },
        ],
      },
    ];
  }, [namePath, isExtended]);

  return { formOptions };
};
