import { FormInstance } from "antd";
import { useEffect, useState } from "react";
import _ from "lodash";
import {
  transformToFloat,
  transformToFormatted,
} from "../../../../lib/transformToFloat";

export const useDefineAmountState = (form: FormInstance, value: number) => {
  const [isBuyerDisabled, setIsBuyerDisabled] = useState<boolean>(false);

  const [isSellerDisabled, setIsSellerDisabled] = useState<boolean>(true);

  const calctTaxAndFee = (value: number): void => {
    const { sellerFee, sellerTax, buyerFee, buyerTax } = form.getFieldsValue();

    const feeSum: number = _.round(
      (transformToFloat(sellerFee) || 0) + (transformToFloat(buyerFee) || 0),
      2
    );

    const taxSum: number = _.round(
      (transformToFloat(sellerTax) || 0) + (transformToFloat(buyerTax) || 0),
      2
    );

    if (value === 0) {
      form.setFieldValue("buyerFee", transformToFormatted(feeSum) || "0.00");
      form.setFieldValue("buyerTax", transformToFormatted(taxSum) || "0.00");
      form.setFieldValue("sellerFee", "0.00");
      form.setFieldValue("sellerTax", "0.00");
    }

    if (value === 1) {
      form.setFieldValue("buyerFee", "0.00");
      form.setFieldValue("buyerTax", "0.00");
      form.setFieldValue("sellerFee", transformToFormatted(feeSum) || "0.00");
      form.setFieldValue("sellerTax", transformToFormatted(taxSum) || "0.00");
    }

    if (value === 2) {
      const halfFeeSum: string | null = transformToFormatted(
        _.ceil(feeSum / 2, 2)
      );

      const halfTaxSum: string | null = transformToFormatted(
        _.ceil(taxSum / 2, 2)
      );

      form.setFieldValue("buyerFee", halfFeeSum || "0.00");
      form.setFieldValue("buyerTax", halfTaxSum || "0.00");
      form.setFieldValue("sellerFee", halfFeeSum || "0.00");
      form.setFieldValue("sellerTax", halfTaxSum || "0.00");
    }
  };

  useEffect(() => {
    switch (value) {
      case 0:
        setIsSellerDisabled(true);
        setIsBuyerDisabled(false);
        break;
      case 1:
        setIsSellerDisabled(false);
        setIsBuyerDisabled(true);
        break;
      case 2:
        setIsSellerDisabled(false);
        setIsBuyerDisabled(false);
        break;
      default:
        break;
    }
  }, [form, value]);

  return { isSellerDisabled, isBuyerDisabled, calctTaxAndFee };
};
