import { Form, FormInstance } from "antd";
import { useEffect } from "react";
import { DOCX_TYPE } from "../../../../../../../../../../../lib/constants";
import { TemplateItem } from "../../../../../../../../../../../types/Template";

export const useUpdateAttachments = (
  form: FormInstance,
  templates: TemplateItem[]
) => {
  const templateId = Form.useWatch("templateId", form);

  useEffect(() => {
    if (templateId) {
      const template = templates.find(
        (item: TemplateItem) => item.id === templateId
      );

      if (template) {
        const attachments = form.getFieldValue("attachments");

        const value = {
          templateId: template.id,
          name: template.fileName,
          type: DOCX_TYPE,
          status: "done",
          size: template.fileSizeBytes,
        };

        if (attachments) {
          const res = [...attachments];

          const attachedIndex = res.findIndex(
            (item: any) => item.templateId === value.templateId
          );

          if (attachedIndex === -1) {
            res.push(value);

            form.setFieldValue("attachments", res);
          }
        } else {
          form.setFieldValue("attachments", [value]);
        }
      }
    }
  }, [templateId, form, templates]);
};
