import { useCallback, useEffect, useState } from "react";
import BatchesService from "../../../../../../../../../../../services/processing/batches";
import dayjs from "../../../../../../../../../../../configs/dayjs";
import { IBatchGet } from "../../../../../../../../../../../types/Batches";
import { FormInstance } from "antd";
import { DATE_FORMAT } from "../../../../../../../../../../../lib/constants";
import { useGetRole } from "../../../../../../../../../../../lib/hooks/getRole";

export const useFetchBatches = (form: FormInstance) => {
  const [options, setOptions] = useState<
    { value: string; label: string; props: any }[]
  >([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_role, _, me] = useGetRole();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchBatches = useCallback(() => {
    const batchDate = dayjs().startOf("D").format(DATE_FORMAT);

    const request = {
      pageSize: 10000,
      pageNumber: 1,
      type: 7,
      from: batchDate,
      to: batchDate,
    };

    setIsLoading(true);

    BatchesService.fetchAll(request)
      .then((res: { date: string | Date; batches: IBatchGet[] }[]) => {
        if (res.length !== 0) {
          const batchOptions = res[0].batches.map((batch) => {
            const { isCreatorPaymentOnly, createdByUserId } = batch;

            const disabled: boolean =
              isCreatorPaymentOnly && me.uid !== createdByUserId;

            return {
              value: batch.id,
              label: `${batch.batchNumber}: Misc Transactions`,
              props: batch,
              disabled,
            };
          });

          setOptions(batchOptions);

          const preSelectedBatch = batchOptions.find(
            (item: any) => !item.disabled
          );

          form.setFieldValue("batchId", preSelectedBatch?.value);
        }
      })
      .catch(() => setIsLoading(false))
      .finally(() => setIsLoading(false));
  }, [form, me]);

  useEffect(() => fetchBatches(), [fetchBatches]);

  return { options, isLoading };
};
