export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const UPDATE_USER_DATA = "UPDATE_USER_DATA";

export const CONFIRM_CODE_SUCCESS = "CONFIRM_CODE_SUCCESS";
export const CONFIRM_CODE_FAIL = "CONFIRM_CODE_FAIL";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const SET_REGISTERED_COUNT = "SET_REGISTERED_COUNT";

export const SET_LOCATION = "SET_LOCATION";
export const CLEAR_LOCATION = "CLEAR_LOCATION";

export const SET_PERMISSIONS = "SET_PERMITIONS";
export const CLEAR_PERMISSIONS = "CLEAR_PERMITIONS";
