import { Col, DatePicker, Form, FormInstance, Row, Tag } from "antd";
import { ReactNode, useCallback, useEffect, useMemo } from "react";
import {
  DATE_FORMAT,
  NEXT_DUE_DATE_REQUIRED,
} from "../../../../../../../../../lib/constants";
import dayjs from "../../../../../../../../../configs/dayjs";
import { ExclamationCircleOutlined } from "@ant-design/icons";

type NextPaymentProps = {
  form: FormInstance;
  data: any;
  lCDays1: number;
};

export const NextPayment = ({ form, data, lCDays1 }: NextPaymentProps) => {
  const nextDueDate = Form.useWatch("nextDueDate", {
    form,
    preserve: true,
  });

  const lateChargeAssessedDate = Form.useWatch("lateChargeAssessedDate", {
    form,
    preserve: true,
  });

  useEffect(() => {
    if (nextDueDate) {
      const lateChargeAssessedDate = nextDueDate.add(
        lCDays1 + (!!data.isLcExtraDay ? 1 : 0),
        "d"
      );

      form.setFieldValue("lateChargeAssessedDate", lateChargeAssessedDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextDueDate, form, lCDays1]);

  const getLabelByDate = useCallback(
    (value: Date, isSimple: boolean): ReactNode => {
      const blob: ReactNode = (
        <div className={`${isSimple ? "position-absolute" : ""}`} />
      );

      if (value) {
        const now = dayjs();

        const diff = now.diff(value, "d");

        return diff > 0 ? (
          !isSimple ? (
            <Tag color="orange" className="mr-0">
              {diff} Days Late
            </Tag>
          ) : (
            <div className="position-absolute attantion-label">
              <ExclamationCircleOutlined />
            </div>
          )
        ) : (
          blob
        );
      }

      return blob;
    },
    []
  );

  const formItems: ReactNode = useMemo(() => {
    const values: any = [
      {
        name: "nextDueDate",
        label: "Next Due Date",
        rules: [
          {
            type: "date",
            required: true,
            validateTrigger: "onSubmit",
            message: NEXT_DUE_DATE_REQUIRED,
          },
        ],
      },
      { name: "interestCalculationDate", label: "Interest Calculation Date" },
      {
        name: "lateChargeAssessedDate",
        label: "Late Charge Assessed Date",
        disabled: true,
      },
    ];

    return values.map((item: any, i: number) => (
      <Row
        className="align-center mb-4 justify-space-between position-relative"
        key={i}
      >
        <span className="text-md title">{item.label}</span>
        <Form.Item
          name={item.name}
          className="mb-0 balance__form-item"
          rules={item.rules || ""}
        >
          <DatePicker format={DATE_FORMAT} disabled={item.disabled} />
        </Form.Item>

        {item.disabled && getLabelByDate(lateChargeAssessedDate, true)}
      </Row>
    ));
  }, [getLabelByDate, lateChargeAssessedDate]);

  return (
    <Row className="next-payment__container d-block">
      <Row className="justify-space-between align-center mt-10 mb-5">
        <Col>
          <h5 className="my-0 h h5 card-header">Next Payment</h5>
        </Col>
        {getLabelByDate(nextDueDate, false)}
      </Row>
      {formItems}
    </Row>
  );
};
