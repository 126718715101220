import { Row } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useState } from "react";
import { IBatchGet } from "../../../../../../types/Batches";
import { PaymentDateInput } from "./PaymentDateInput";
import { Dayjs } from "dayjs";
import { DATE_FORMAT } from "../../../../../../lib/constants";

type DataValueItemProps = {
  header: string;
  value: Dayjs;
  data?: IBatchGet;
  editable?: boolean;
  updateDataPaymentDate?: () => void;
};

export const DataValueItem = ({
  header,
  value,
  editable,
  data,
  updateDataPaymentDate,
}: DataValueItemProps) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const onCloseEdit = () => setIsEdit(false);

  const onValueClicked = () => editable && setIsEdit(true);

  return (
    <Row className={`align-center batch-prop ${editable && "ml-3 editable"}`}>
      <p className="my-0 text-md title">{header}: </p>
      {!isEdit ? (
        <Row className="align-center value" onClick={onValueClicked}>
          <p className="my-0 px-1 text w-500">{value.format(DATE_FORMAT)}</p>
          <EditOutlined className="ml-1" />
        </Row>
      ) : (
        <>
          {data && updateDataPaymentDate && (
            <PaymentDateInput
              value={value}
              data={data}
              onCloseEdit={onCloseEdit}
              updateDataPaymentDate={updateDataPaymentDate}
            />
          )}
        </>
      )}
    </Row>
  );
};
