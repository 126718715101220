import { ReactNode, useMemo, useState } from "react";
import { SideBarContainer } from "../../../../../../components/SideBarContainer";
import { CreateEditForm } from "../components/CreateEditForm";
import { FormInstance } from "antd";

export const useOpenSideBar = (
  contractId: string,
  payeesNumber: {
    primaryBuyerNumber: number;
    primarySellerNumber: number;
  },
  isFormDisabled: boolean,
  isPaymentPosted: boolean,
  saveRecord: (
    form: FormInstance,
    customerIds: any,
    cancel: () => void,
    isNew: boolean,
    index: string | null
  ) => void,
  openDeleteModal: (id: string) => void,
  isHistoryOpened: boolean
) => {
  const [record, setRecord] = useState<any>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleSideBarSideBar = (
    value: any,
    isOpenSidebar: boolean = true
  ): void => {
    setRecord(value);
    setIsOpen(isOpenSidebar);
  };

  const title = useMemo(
    () =>
      record && Object.keys(record).length === 0
        ? "Add Payee"
        : "Payee Details",
    [record]
  );

  const formWrapper: ReactNode = useMemo(() => {
    return (
      <SideBarContainer
        title={title}
        setShowSideBar={setIsOpen}
        customClass="sidebar__container-payee-disbursements"
      >
        <CreateEditForm
          record={record}
          contractId={contractId}
          payeesNumber={payeesNumber}
          isFormDisabled={isFormDisabled}
          isPaymentPosted={isPaymentPosted}
          onCloseSideBar={toggleSideBarSideBar}
          openDeleteModal={openDeleteModal}
          saveRecord={saveRecord}
          isHistoryOpened={isHistoryOpened}
        />
      </SideBarContainer>
    );
  }, [
    record,
    contractId,
    payeesNumber,
    isFormDisabled,
    saveRecord,
    openDeleteModal,
    isPaymentPosted,
    isHistoryOpened,
    title,
  ]);

  return { formWrapper, isOpen, toggleSideBarSideBar };
};
