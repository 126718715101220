import { FormInstance } from "antd";
import {
  PERCENT_LIMIT,
  LATE_CHARGE_AMOUNT_LIMIT,
} from "../../../../lib/constants";
import { FloatNumberInput } from "../../../../components/FloatNumberInput";
import { transformToFloat } from "../../../../lib/transformToFloat";

type TAmountInputProps = {
  isDollarInput: boolean;
  form: FormInstance;
};

export const AmountInput = ({ isDollarInput, form }: TAmountInputProps) => {
  return (
    <div className="amount-inputs-wrapper">
      {isDollarInput ? (
        <FloatNumberInput
          name="amount"
          label="Amount"
          form={form}
          className="amount-input-wrapper"
          includeThousandsSeparator
          rules={[
            {
              type: "number",
              transform: transformToFloat,
              max: 99999,
              message: LATE_CHARGE_AMOUNT_LIMIT,
              validateTrigger: "onSubmit",
            },
            {
              required: true,
            },
          ]}
        />
      ) : (
        <FloatNumberInput
          name="percentage"
          label="Percent"
          form={form}
          prefix="%"
          decimalLimit={4}
          className="amount-input-wrapper"
          rules={[
            {
              type: "number",
              transform: (value) => +value,
              max: 100,
              message: PERCENT_LIMIT,
              validateTrigger: "onSubmit",
            },
            {
              required: true,
            },
          ]}
        />
      )}
    </div>
  );
};
