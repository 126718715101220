import { useCallback, useState } from "react";
import { useDefaultDeleteModal } from "../../../../../../../../../lib/hooks/defaultDeleteModal";
import {
  deletePayeeDisb,
  createPayeeDisb,
  editPayeeDisb,
} from "../../../../../../../../../acions/accounts/payeeDisbursements";
import { IPayeeDisbursementsBase } from "../../../../../../../../../types/PayeeDisbursements";
import { FormInstance } from "antd";
import { useAppDispatch } from "../../../../../../../../../store";
import { TRecord } from "../types";

type TButtonHandlers = {
  reloadData: (isNew?: boolean) => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRecord: React.Dispatch<React.SetStateAction<TRecord>>;
  form?: FormInstance;
};

export const useButtonHandlers = ({
  reloadData,
  setIsOpen,
  setRecord,
  form,
}: TButtonHandlers) => {
  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);

  const { openDeleteModal } = useDefaultDeleteModal(
    deletePayeeDisb,
    reloadData,
    setIsOpen
  );

  const dispatch = useAppDispatch();

  const onCancelClick = useCallback(() => {
    setIsOpen(false);
    setRecord({ id: null, contractId: "" });
  }, [setIsOpen, setRecord]);

  const onDeleteBtnClick = useCallback(
    async (id: string) => {
      openDeleteModal(id, "Delete Disbursement");
    },
    [openDeleteModal]
  );

  const create = useCallback(
    async (entry: IPayeeDisbursementsBase, isNew: boolean) => {
      try {
        await Promise.resolve(dispatch(createPayeeDisb(entry)));

        reloadData(isNew);
        setIsFormDisabled(false);
        setIsOpen(isNew);
      } catch (error) {
        setIsFormDisabled(false);
      }
    },
    [dispatch, reloadData, setIsOpen]
  );

  const edit = useCallback(
    async (id: string, entry: IPayeeDisbursementsBase) => {
      try {
        await Promise.resolve(dispatch(editPayeeDisb(entry, id)));

        reloadData();
        setIsFormDisabled(false);
        setIsOpen(false);
      } catch (error) {
        setIsFormDisabled(false);
      }
    },
    [dispatch, reloadData, setIsOpen]
  );

  const onSaveBtnClick = useCallback(
    async (isNew: boolean, record: TRecord, customerIds?: any) => {
      try {
        setIsFormDisabled(true);

        const formData = await form?.validateFields();

        const { beginningDate, endDate } = formData;

        const request = {
          ...formData,
          alternativePayeeId: customerIds.alternativePayeeId,
          payeeId: customerIds.payeeId,
          contractId: record.contractId,
          beginningDate: beginningDate
            ? beginningDate.startOf("D").format()
            : undefined,
          endDate: endDate ? endDate.startOf("D").format() : undefined,
        };

        record.id !== null ? edit(record.id, request) : create(request, isNew);
      } catch (error) {
        setIsFormDisabled(false);
      }
    },
    [create, edit, form]
  );

  return {
    onCancelClick,
    onDeleteBtnClick,
    onSaveBtnClick,
    isFormDisabled,
  };
};
