import { useCallback, useState } from "react";
import { useAppDispatch } from "../../../../../store";
import { createContract } from "../../../../../acions/accounts/contracts";

export const useCreateAccount = (
  customerNumbers: any,
  onSaved: (value: any) => void
) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const save = useCallback(
    (values: any): void => {
      setIsLoading(true);

      const request = {
        beginningDate: values.beginningDate.startOf("D").format(),
        primaryBuyerId: customerNumbers.primaryBuyerNumber,
        primarySellerId: customerNumbers.primarySellerNumber,
      };

      Promise.resolve(dispatch(createContract(request)))
        .then((res: any) => {
          setIsLoading(false);
          const data = { ...res, accountNumber: res.name };
          onSaved(data);
        })
        .catch(() => setIsLoading(false));
    },
    [customerNumbers, dispatch, onSaved]
  );

  return { isLoading, setIsLoading, save };
};
