import {
  Button,
  Dropdown,
  Form,
  FormInstance,
  Input,
  MenuProps,
  Select,
} from "antd";
import {
  BANK_ACCOUNT_NUMBER_MIN_MAX,
  DISBURSEMENTS_METHOD_TYPES,
  PAYEE_DISB_DESCRIPTION_MAX,
  WRONG_FORMAT_BANK_TRANSIT_ROUTING,
} from "../../../../../../../../../lib/constants";
import { MoreOutlined } from "@ant-design/icons";
import { useCallback, useState } from "react";
import { FloatNumberInput } from "../../../../../../../../../components/FloatNumberInput";
import { useGetBankAccounts, TBankAccount } from "../hooks/useGetBankAccounts";
import { useFetchImpoundAccounts } from "../hooks/useFetchImpoundAccounts";
import { AccountInput } from "./AccountInput";

type TDisbursementsMethodProps = {
  form: FormInstance;
  customerIds: any;
  contractId: string;
  disbursementMethodState: {
    isEscrowAcc: boolean;
    isACHMethod: boolean;
  };
  isFormDisabled: boolean;
};

export const DisbursementsMethodPart = ({
  form,
  customerIds,
  contractId,
  disbursementMethodState,
  isFormDisabled,
}: TDisbursementsMethodProps) => {
  const [isInputShown, setIsInputShown] = useState({
    isBankAccountShown: !disbursementMethodState.isEscrowAcc,
    isAccountNumberShown: !disbursementMethodState.isACHMethod,
  });

  const [isAccNumberLabelShort, setIsAccNumberLabelShort] = useState<boolean>(
    disbursementMethodState.isEscrowAcc
  );

  const { impoundsAccs, isLoading } = useFetchImpoundAccounts(contractId);

  const { isDropdownDisabled, primaryBankAccount, secBankAccount } =
    useGetBankAccounts({ customerIds });

  const onDropdownChange = useCallback(
    (value: TBankAccount | null) => {
      const isBankAccount = value !== null;

      const values = {
        bankAccountNumber: isBankAccount ? value.bankAccountNumber : null,
        bankTransitRouting: isBankAccount ? value.bankTransitRouting : null,
      };

      form.setFieldsValue(values);
    },
    [form]
  );

  const onMethodChange = useCallback((value: number) => {
    const state: any = {};

    state.isAccountNumberShown = !(value >= 5 && value <= 7);
    state.isBankAccountShown = value !== 8;

    setIsInputShown(state);
    setIsAccNumberLabelShort(value === 8);
  }, []);

  const items: MenuProps["items"] = [
    {
      label: (
        <div onClick={() => onDropdownChange(primaryBankAccount)}>
          {`Set ${
            customerIds.alternativePayeeId ? "Alternate" : ""
          } Payee’s Primary Bank Account`}
        </div>
      ),
      key: "1",
    },
    {
      label: (
        <div onClick={() => onDropdownChange(secBankAccount)}>
          {`Set ${
            customerIds.alternativePayeeId ? "Alternate" : ""
          } Payee’s Secondary Bank Account`}
        </div>
      ),
      key: "2",
    },
  ];

  return (
    <div className="pb-4">
      <p className="mb-4 mt-0 text-md w-500">Disbursement Method</p>
      <Form.Item label="Type" className="mb-4" name="method">
        <Select
          options={DISBURSEMENTS_METHOD_TYPES}
          onChange={onMethodChange}
        />
      </Form.Item>
      {isInputShown.isBankAccountShown && (
        <div className="d-flex">
          <FloatNumberInput
            name="bankAccountNumber"
            label="Bank Account Number"
            form={form}
            prefix=""
            decimalLimit={0}
            className="mb-4 bank-number-input"
            allowDecimal={false}
            rules={[
              {
                min: 6,
                message: BANK_ACCOUNT_NUMBER_MIN_MAX,
                validateTrigger: "onSubmit",
              },
              {
                max: 17,
                message: BANK_ACCOUNT_NUMBER_MIN_MAX,
                validateTrigger: "onSubmit",
              },
            ]}
          />
          <FloatNumberInput
            name="bankTransitRouting"
            label="Routing Number"
            form={form}
            prefix=""
            decimalLimit={0}
            className="ml-2 mb-4 routing-number-input"
            allowDecimal={false}
            rules={[
              {
                min: 9,
                message: WRONG_FORMAT_BANK_TRANSIT_ROUTING,
                validateTrigger: "onSubmit",
              },
              {
                max: 9,
                message: WRONG_FORMAT_BANK_TRANSIT_ROUTING,
                validateTrigger: "onSubmit",
              },
            ]}
          />
          <Dropdown
            menu={{ items }}
            className="ml-2 mt-6"
            disabled={isDropdownDisabled || isFormDisabled}
            trigger={["click"]}
          >
            <Button shape="circle" icon={<MoreOutlined />}></Button>
          </Dropdown>
        </div>
      )}
      {isInputShown.isAccountNumberShown && (
        <AccountInput
          form={form}
          isShort={isAccNumberLabelShort}
          isFormDisabled={isFormDisabled}
        />
      )}
      <Form.Item label="Impound Account" name="impoundId" className="mb-4">
        <Select
          options={impoundsAccs}
          allowClear
          disabled={isLoading || isFormDisabled}
          className="payee-disbursements-form-select"
        />
      </Form.Item>
      <Form.Item
        label="Description"
        name="description"
        className="mb-4"
        rules={[
          {
            max: 60,
            message: PAYEE_DISB_DESCRIPTION_MAX,
            validateTrigger: "onSubmit",
          },
        ]}
      >
        <Input />
      </Form.Item>
    </div>
  );
};
