import { Button, FormInstance, Row } from "antd";
import { useCallback } from "react";

type FormButtonsProps = {
  form: FormInstance;
  isLoading: boolean;
  close: () => void;
};

export const FormButtons = ({ form, isLoading, close }: FormButtonsProps) => {
  const onSend = useCallback(() => form.submit(), [form]);

  return (
    <Row className="w-100 justify-end pa-4">
      <Button
        type="primary"
        shape="round"
        disabled={isLoading}
        loading={isLoading}
        onClick={onSend}
      >
        Send
      </Button>
      <Button
        shape="round"
        className="ml-2"
        disabled={isLoading}
        onClick={close}
      >
        Cancel
      </Button>
    </Row>
  );
};
