import { CLEAR_PERMISSIONS, SET_PERMISSIONS } from "../types";

export const setPermissions = (payload: any) => ({
  type: SET_PERMISSIONS,
  payload,
});

export const clearPermissions = () => ({
  type: CLEAR_PERMISSIONS,
});
