import { useCallback, useState } from "react";
import CustomersService from "../../../../../../../../../../../services/customers";
import { EMAIL_RULE } from "../../../../../../../../../../../lib/validationRules";

export const useGetCustomerInfo = (
  setData: React.Dispatch<React.SetStateAction<any[]>>
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const search = useCallback(
    (num: number) => {
      setIsLoading(true);
      CustomersService.searchByNumber(num)
        .then((res) => {
          setIsLoading(false);

          setData((prev: any) => {
            const val = [...prev];

            val.push({ ...res, number: num, i: num });

            return val;
          });
        })
        .catch(() => setIsLoading(false));
    },
    [setIsLoading, setData]
  );

  const testEmail = useCallback(
    (val: string) => {
      if (EMAIL_RULE.test(val)) {
        setData((prev: any) => {
          const value = [...prev];

          value.push({ email: val, name: val, i: val, isEmail: true });

          return value;
        });
      }
    },
    [setData]
  );

  return { isLoading, search, testEmail };
};
