import { Button, Card, Row, Table } from "antd";
import { useFetchDataCount } from "../../../../../../lib/hooks/useDefaultFetchDataCount";
import { useFetchData } from "../../../../../../lib/hooks/useDefaultFetchData";
import { useGetTableColumns } from "./hooks/useGetTableColumns";
import { TablePagination } from "../../../../../../components/TablePagination";
import { useDefaultTableScrollProp } from "../../../../../Customers/hooks/defaultTableScrollProp";
import { useCallback, useState } from "react";
import { createPortal } from "react-dom";
import "./styles.scss";
import { useOpenSidebar } from "./hooks/useOpenSidebar";
import PayoffQuoteService from "../../../../../../services/accounts/payoffQuote";
import { TRequest } from "./types";
import { IPayoffQuotesGet } from "../../../../../../types/PayoffQute";
import { useDefualtOnRow } from "../../../../../../lib/hooks/useDefaultOnRow";

type TPayoffProps = {
  contractId: string;
  primaryBuyerId: string;
  primarySellerId: string;
};

export const Payoff = ({
  contractId,
  primaryBuyerId,
  primarySellerId,
}: TPayoffProps) => {
  const [id, setId] = useState<string | null>(null);

  const {
    total,
    request,
    isLoading: isCountLoading,
    onPageChange,
    fetchCount,
  } = useFetchDataCount<{ contractId: string }>(
    PayoffQuoteService,
    { contractId },
    ["contractId"]
  );

  const { data, isLoading, fetchData } = useFetchData<
    TRequest,
    IPayoffQuotesGet
  >(PayoffQuoteService, request);

  const { scroll } = useDefaultTableScrollProp(data);

  const reloadData = useCallback(() => {
    fetchCount();
    fetchData();
  }, [fetchData, fetchCount]);

  const { formWrapper, isOpen, setIsOpen } = useOpenSidebar({
    id,
    setId,
    reloadData,
    customSidebarClass: "sidebar__container-payoff-quote",
    parentId: contractId,
    primaryBuyerId,
    primarySellerId,
  });

  const openPayoff = useCallback(
    (id: string) => {
      setId(id);
      setIsOpen(true);
    },
    [setIsOpen]
  );

  const { onRow } = useDefualtOnRow(openPayoff);

  const { columns } = useGetTableColumns({
    setId,
    setIsOpen,
    reloadData,
    openPayoff,
  });

  const onAddClick = useCallback(() => setIsOpen(true), [setIsOpen]);

  return (
    <Row className="w-100 py-6 px-8">
      <Card className="w-100">
        <Row className="w-100 d-flex justify-space-between">
          <h5 className="h h5 ant-table-title">Payoff Quotes</h5>
          <Button shape="round" onClick={onAddClick}>
            Add New
          </Button>
        </Row>
        <Row className="w-100 pt-6 d-block">
          <Table
            columns={columns}
            dataSource={data}
            loading={isLoading}
            pagination={false}
            rowKey="id"
            className="w-100 clickable-rows-table"
            scroll={scroll}
            onRow={onRow}
          />
          {total > 20 && (
            <TablePagination
              total={total}
              onPageChange={onPageChange}
              isTotalLoading={isCountLoading}
              currentPage={request.pageNumber}
              pageSize={request.pageSize}
            />
          )}
        </Row>
      </Card>
      {isOpen && createPortal(formWrapper, document.body)}
    </Row>
  );
};
