import { useCallback, useEffect, useState } from "react";
import PayoffQuoteService from "../../../../../../../../../services/accounts/payoffQuote";
import { FormInstance } from "antd";
import { IPayoffQuoteGetOne } from "../../../../../../../../../types/PayoffQute";
import dayjs, { Dayjs } from "dayjs";
import { useFormatData } from "./useFormatData";

export const useGetInitialData = (
  id: string | null,
  form: FormInstance,
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>,
  contractId: string
) => {
  const [additionalInfo, setAdditionalInfo] = useState<{
    daysOfInterest: number;
    interestPerDay: number;
  }>({
    daysOfInterest: 0,
    interestPerDay: 0,
  });

  const { getFormattedIncomingData } = useFormatData();

  const onSuccess = useCallback(
    (
      data: IPayoffQuoteGetOne,
      daysOfInterest: number,
      interestPerDay: number
    ) => {
      const initialData = getFormattedIncomingData(data);

      form.setFieldsValue(initialData);

      setAdditionalInfo({
        daysOfInterest,
        interestPerDay,
      });
    },
    [form, getFormattedIncomingData]
  );

  const fetchCreationData = useCallback(
    (date: Dayjs | string, disable: boolean) => {
      disable && setIsDisabled(true);

      PayoffQuoteService.initialize({ date, contractId })
        .then((res: IPayoffQuoteGetOne) => {
          const { daysOfInterest, interestPerDay } = res;

          setIsDisabled(false);

          onSuccess(res, daysOfInterest, interestPerDay);
        })
        .catch(() => setIsDisabled(false));
    },
    [contractId, onSuccess, setIsDisabled]
  );

  const fetchInitialData = useCallback(() => {
    if (id) {
      PayoffQuoteService.fetch(id)
        .then((res: IPayoffQuoteGetOne) => {
          const { daysOfInterest, interestPerDay } = res;

          setIsDisabled(false);

          onSuccess(res, daysOfInterest, interestPerDay);
        })
        .catch(() => setIsDisabled(false));
    } else {
      const date = dayjs().startOf("D").format();

      fetchCreationData(date, false);
    }
  }, [fetchCreationData, onSuccess, id, setIsDisabled]);

  useEffect(() => fetchInitialData(), [fetchInitialData]);

  return {
    fetchCreationData,
    additionalInfo,
  };
};
