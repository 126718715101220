import { useCallback, useState } from "react";
import { useAppDispatch } from "../../../store";
import { sendOne } from "../../../acions/emails";

export const useSendOne = (reloadData: () => void) => {
  const dispatch = useAppDispatch();

  const [isSending, setIsSending] = useState<boolean>(false);

  const send = useCallback(
    async (id: string) => {
      try {
        setIsSending(true);

        await Promise.resolve(dispatch(sendOne(id)));

        setIsSending(false);

        reloadData();
      } catch {
        setIsSending(false);
      }
    },
    [dispatch, reloadData]
  );

  return { isSending, send };
};
