import qs from "qs";
import { API_URL } from "../../configs/apiUrl";
import authAxios, { authHeader } from "../data";

const fetchAll = async (request?: any) => {
  const response = await authAxios.get(API_URL + "ReportTemplates", {
    params: request,
    headers: authHeader(),
  });

  return response.data;
};

const create = async (request: FormData) => {
  const response = await authAxios.post(API_URL + "ReportTemplates", request, {
    headers: authHeader(),
  });

  return response.data;
};

const edit = async (id: string, request: FormData) => {
  const response = await authAxios.put(
    `${API_URL}ReportTemplates/${id}`,
    request,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const remove = async (id: string) => {
  const response = await authAxios.delete(API_URL + "ReportTemplates/" + id, {
    headers: authHeader(),
  });

  return response.data;
};

const download = async (id: string) => {
  const response = await authAxios.get(
    API_URL + `ReportTemplates/${id}/download`,
    {
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      headers: authHeader(),
      responseType: "blob",
    }
  );

  return response.data;
};

const TemplatesService = {
  fetchAll,
  remove,
  create,
  edit,
  download,
};

export default TemplatesService;
