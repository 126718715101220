import { useCallback, useState } from "react";
import { useDefaultDeleteModal } from "../../../../../../../../../lib/hooks/defaultDeleteModal";
import {
  deleteImpound,
  createImpound,
  editImpound,
} from "../../../../../../../../../acions/accounts/impounds";
import { FormInstance } from "antd";
import { IImpoundsPost } from "../../../../../../../../../types/Impounds";
import { useAppDispatch } from "../../../../../../../../../store";
import { transformToFloat } from "../../../../../../../../../lib/transformToFloat";

type TButtonHandlers = {
  reloadData: (isImpoundCreated?: boolean) => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
  form?: FormInstance;
  parentId?: string;
  setActiveKey?: React.Dispatch<React.SetStateAction<string>>;
  setIsDistributionModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setImpoundCurrentBalance?: React.Dispatch<React.SetStateAction<number>>;
};

export const useButtonHandlers = ({
  reloadData,
  setIsOpen,
  setId,
  form,
  parentId,
  setActiveKey,
  setIsDistributionModalOpen,
  setImpoundCurrentBalance,
}: TButtonHandlers) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { openDeleteModal } = useDefaultDeleteModal(
    deleteImpound,
    reloadData,
    setIsOpen
  );

  const onDeleteBtnClick = useCallback(
    (id: string) => openDeleteModal(id, "Delete Impound"),
    [openDeleteModal]
  );

  const onCancelBtnClick = useCallback(() => {
    setIsOpen(false);
    setId(null);
  }, [setId, setIsOpen]);

  const onOpenBtnClick = useCallback(
    (id: string, activeKey: string, impoundBalance: number, value = false) => {
      setIsOpen(true);
      setId(id);
      setActiveKey && setActiveKey(activeKey);
      setIsDistributionModalOpen && setIsDistributionModalOpen(value);
      setImpoundCurrentBalance && setImpoundCurrentBalance(impoundBalance);
    },
    [
      setActiveKey,
      setId,
      setImpoundCurrentBalance,
      setIsDistributionModalOpen,
      setIsOpen,
    ]
  );

  const create = useCallback(
    async (entry: IImpoundsPost, isNew: boolean) => {
      try {
        await Promise.resolve(dispatch(createImpound(entry)));

        reloadData(true);
        setIsDisabled(false);
        setId(null);
        setIsOpen(isNew);
        form?.resetFields();
      } catch (error) {
        setIsDisabled(false);
      }
    },
    [dispatch, form, reloadData, setId, setIsOpen]
  );

  const edit = useCallback(
    async (entry: IImpoundsPost, id: string) => {
      try {
        await Promise.resolve(dispatch(editImpound(entry, id)));

        reloadData();
        setIsDisabled(false);
        setId(null);
        setIsOpen(false);
      } catch (error) {
        setIsDisabled(false);
      }
    },
    [dispatch, reloadData, setId, setIsOpen]
  );

  const onSaveBtnClick = useCallback(
    async (isNew: boolean, id: string | null, customerIds: any) => {
      try {
        setIsDisabled(true);

        const formData = await form?.validateFields();

        const request = {
          ...formData,
          amount: transformToFloat(formData.amount),
          payeeId: customerIds.payeeId,
          contractId: parentId,
        };

        id !== null ? edit(request, id) : create(request, isNew);
      } catch (error) {
        setIsDisabled(false);
      }
    },
    [create, edit, form, parentId]
  );

  return {
    onDeleteBtnClick,
    onCancelBtnClick,
    onSaveBtnClick,
    isDisabled,
    onOpenBtnClick,
  };
};
