import {
  CloseCircleFilled,
  CheckCircleFilled,
  InfoCircleFilled,
  CloseOutlined,
} from "@ant-design/icons";
import { ReactNode, useCallback } from "react";
import { useAppDispatch } from "../../store";
import { clearMessage } from "../../acions/actionsCreators/messageActions";
import { useEffect } from "react";
import "./styles.scss";

type AlertProps = {
  children: ReactNode | string;
  type: "success" | "error" | "info";
  shouldDisapear: boolean;
  closable: boolean;
};

const TIME_TO_HIDE = 3000;

export const Alert = ({
  children,
  type,
  shouldDisapear,
  closable,
}: AlertProps) => {
  const dispatch = useAppDispatch();

  const clear = useCallback((): void => {
    dispatch(clearMessage());
  }, [dispatch]);

  useEffect(() => {
    let timetId: any = null;
    if (
      (type === "success" && !closable) ||
      type === "info" ||
      shouldDisapear
    ) {
      timetId = setTimeout(() => {
        clear();
      }, TIME_TO_HIDE);
    }

    return () => clearTimeout(timetId);
  }, [dispatch, type, shouldDisapear, clear, closable]);

  return (
    <div
      className={`notification__container ${type} py-2 px-3 d-flex align-center`}
    >
      {type === "error" && <CloseCircleFilled className="mr-2" />}

      {type === "success" && <CheckCircleFilled className="mr-2" />}

      {type === "info" && <InfoCircleFilled className="mr-2" />}

      <div className="notification-content">
        <div className="message">{children}</div>
      </div>
      {closable && <CloseOutlined className="close-btn ml-3" onClick={clear} />}
    </div>
  );
};
