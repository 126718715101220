import { Row } from "antd";
import { getFormattedCurrency } from "../../../../../lib/currencyFormatter";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { PAYMENT_LATE_CHARGES_ASSESS_METHOD } from "../../../../../lib/constants";
import { useMemo } from "react";

type WarningProps = {
  diff: number;
  lateCharge: any;
};

export const Warning = ({ diff, lateCharge }: WarningProps) => {
  const assessedMethod: string = useMemo(() => {
    if (lateCharge) {
      const method = PAYMENT_LATE_CHARGES_ASSESS_METHOD.find(
        (item: any) => item.value === lateCharge.assessMethod
      );

      return method ? method.label : "";
    }

    return "";
  }, [lateCharge]);

  const amount: string = useMemo(() => {
    return getFormattedCurrency(lateCharge?.assessed || 0);
  }, [lateCharge]);

  return (
    <Row className="align-center text-sm">
      <ExclamationCircleOutlined className="search-icon logo-cursor ml-4 text-md" />
      <p className="my-0 pl-2 text-sm w-500">
        {`${diff} day${diff > 1 ? "s" : ""} late. ${amount}`}
      </p>
      &nbsp;assessed.&nbsp;Method:&nbsp;
      <p className="my-0 text-sm w-500 elipsis header-method-val">
        {assessedMethod}
      </p>
    </Row>
  );
};
