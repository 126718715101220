import { useCallback, useState } from "react";
import { useDebounce } from "./debounce";

type SearchReturnType = [
  search: string,
  debouncedSearch: string,
  onSearchChange: (e: any) => void,
  onClearSearch: () => void
];

export const useSearch = (delay?: number): SearchReturnType => {
  const [search, setSearch] = useState<string>("");

  const debouncedSearch = useDebounce(search, delay);

  const onSearchChange = useCallback((e: any) => setSearch(e.target.value), []);

  const onClearSearch = useCallback((): void => setSearch(""), []);

  return [search, debouncedSearch, onSearchChange, onClearSearch];
};
