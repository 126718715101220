import { useCallback, useRef } from "react";

export const useScrollToDiv = () => {
  const ref = useRef<HTMLDivElement>(null);

  const scrollToDiv = useCallback(() => {
    ref.current?.scrollIntoView({ block: "center", behavior: "smooth" });
  }, []);

  return { ref, scrollToDiv };
};
