import "./styles.scss";
import { useState, useEffect, useCallback } from "react";
import { Button } from "antd";

type TimerProps = {
  time: number;
  onResendClick: () => void;
};
export const Timer = ({ time, onResendClick }: TimerProps) => {
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);

  const [isFinifhed, setIsFinished] = useState<boolean>(false);

  useEffect((): void => setMinutes(time), [time]);

  useEffect(() => {
    let intervalId = setInterval(() => {
      if (seconds === 0) {
        setSeconds(59);
        minutes - 1 > -1 ? setMinutes(minutes - 1) : setIsFinished(true);
        minutes - 1 === -1 && clearInterval(intervalId);
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  });

  const clickResend = useCallback(() => onResendClick(), [onResendClick]);

  return (
    <div className="timer">
      {isFinifhed ? (
        <Button
          type="link"
          className="useful-link px-0 py-0"
          onClick={clickResend}
        >
          Resend
        </Button>
      ) : (
        <>
          expires in&ensp;
          <div className="d-inline">
            <span>{minutes}</span>:
            <span>{seconds > 9 ? seconds : `0${seconds}`}</span>
          </div>
        </>
      )}
    </div>
  );
};
