import { Layout, Button } from "antd";
import { Outlet } from "react-router-dom";
import { Copyright } from "../../Copyright";
import { TermsAndConditionsModal } from "../../TermsAndConditionsModal";
import { useToggleTerms } from "../../../lib/hooks/useToggleTerms";
import "./styles.scss";

export const Footer = () => {
  const { Footer } = Layout;

  const { termsTabId, setTermsTabId, onCloseTerms, openTerms, openPrivacy } =
    useToggleTerms();

  return (
    <>
      <Outlet />

      <Footer style={{ textAlign: "center" }}>
        <div className="footer__container">
          <Copyright />
          <div className="footer-link d-flex align-center">
            <div className="elipse" />
            <Button type="link" onClick={openTerms}>
              Terms of Use
            </Button>
            <div className="elipse" />
            <Button type="link" onClick={openPrivacy}>
              Privacy Policy
            </Button>
          </div>
        </div>

        <TermsAndConditionsModal
          termsTabId={termsTabId}
          setTermsTabId={setTermsTabId}
          onClose={onCloseTerms}
        />
      </Footer>
    </>
  );
};
