import { useCallback } from "react";

export const useOnFail = (
  setError: React.Dispatch<React.SetStateAction<string | null>>
) => {
  const onFail = useCallback(
    (err: any) => {
      const errorTitle: string = err.response.data.title;

      setError(errorTitle);
    },
    [setError]
  );

  const clearError = useCallback(() => setError(null), [setError]);

  return { onFail, clearError };
};
