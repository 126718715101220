import { useCallback } from "react";
import {
  IPayoffQuoteFormData,
  IPayoffQuotePost,
} from "../../../../../../../../../types/PayoffQute";
import dayjs from "dayjs";
import { TCustomersData } from "../../../types";
import { getFormatted } from "../../../../../../../../../lib/PhoneFormatter";
import {
  transformToFloat,
  transformToFormatted,
} from "../../../../../../../../../lib/transformToFloat";

export const useFormatData = () => {
  const filterObject = useCallback((obj: any, word: string) => {
    let result: Record<string, string | null> = {};

    const filteredKeys = Object.keys(obj).filter((key) => key.includes(word));

    filteredKeys.forEach((key: keyof typeof result) => {
      result[key] = transformToFormatted(obj[key as keyof typeof obj]);
    }, {});

    return result;
  }, []);

  const formatAmounts = useCallback((data: any) => {
    const obj: any = {};

    Object.keys(data).forEach((item: string) => {
      obj[item] = transformToFloat(data[item]);
    });

    return obj;
  }, []);

  const getFormattedIncomingData = useCallback(
    (data: IPayoffQuotePost) => {
      const formattedData = {
        ...data,
        date: dayjs(data.date),
        buyer: {
          ...filterObject(data, "buyer"),
          principal: transformToFormatted(data.principal),
          currentLateCharges: transformToFormatted(data.currentLateCharges),
          interest: transformToFormatted(data.interest),
          unpaidInterest: transformToFormatted(data.unpaidInterest),
          unpaidLateCharges: transformToFormatted(data.unpaidLateCharges),
          amount: transformToFormatted(data.item.amount),
          buyerFees: transformToFormatted(data.itemFee.buyerFees),
        },
        seller: {
          ...filterObject(data, "seller"),
          sellerFees: transformToFormatted(data.itemFee.sellerFees),
        },
        subtrahend: {
          impoundRefund: transformToFormatted(
            Math.abs(data.impoundRefund || 0)
          ),
          discount: transformToFormatted(Math.abs(data.discount || 0)),
        },
        receiverType: data.receiver.companyName !== null ? 2 : 1,
        receiver: {
          ...data.receiver,
          phone: getFormatted(data.receiver.phone || "").slice(2),
        },
      };

      return formattedData;
    },
    [filterObject]
  );

  const getFormattedCustomersData = useCallback((data: TCustomersData) => {
    const formattedData = {
      receiver: {
        ...data.customerIndividualData,
        ...data.customerCompanyData,
        ...data.primaryAddress,
        email: data.primaryEmail,
        phone: getFormatted(data.phone || "").slice(2),
      },
    };

    return formattedData;
  }, []);

  const getFormattedOutcomingData = useCallback(
    (data: IPayoffQuoteFormData, contractId: string | undefined) => {
      const formattedData: any = {
        ...data,
        date: data.date.startOf("D").format(),
        ...formatAmounts(data.buyer),
        ...formatAmounts(data.seller),
        ...formatAmounts(data.subtrahend),
        item: {
          ...data.item,
          amount: transformToFloat(data.buyer.amount),
        },
        itemFee: {
          ...data.itemFee,
          buyerFees: transformToFloat(data.buyer.buyerFees),
          sellerFees: transformToFloat(data.seller.sellerFees),
        },
        contractId,
        receiver: {
          ...data.receiver,
          phone: data.receiver.phone
            ? `1${data.receiver.phone.replace(/[\s-()]/g, "")}`
            : null,
        },
      };

      return formattedData;
    },
    [formatAmounts]
  );

  return {
    getFormattedIncomingData,
    getFormattedCustomersData,
    getFormattedOutcomingData,
  };
};
