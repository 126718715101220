import qs from "qs";
import { API_URL } from "../../configs/apiUrl";
import authAxios, { authHeader } from "../data";

const fetchAll = async (req: {
  batchId: string;
  pageSize: number;
  pageNumber: number;
  method: number | null;
  orderBy?: number;
}) => {
  const result = await authAxios.get(API_URL + "BatchDisbursements", {
    params: req,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return result.data;
};

const fetchCount = async (req: {
  batchId: string;
  method: number | null;
  orderBy?: number;
}) => {
  const result = await authAxios.get(API_URL + "BatchDisbursements/Count", {
    params: req,
    paramsSerializer: (params) => qs.stringify(params),
    headers: authHeader(),
  });

  return result.data;
};

const fetchCheckNumber = async (req: {
  batchId: string;
  selectedDisbursementIds: string[],
}) => {
  const result = await authAxios.get(API_URL + "BatchDisbursements/NextCheckNumber", {
    params: req,
    headers: authHeader(),
  });

  return result.data;
};

const assign = async (req: {
  batchId: string;
  selectedDisbursementIds: string[],
  checkNumber: number;
  ignoreAssigned: boolean;
}) => {
  const result = await authAxios.put(API_URL + "BatchDisbursements/Assign", req, {
    headers: authHeader(),
  });

  return result.data;
};

const BatchDisbursementsService = {
  fetchAll,
  fetchCount,
  fetchCheckNumber,
  assign,
};

export default BatchDisbursementsService;
